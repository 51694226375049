import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import LoadingButton from "components/LoadingButton";
import { Grid } from "@mui/material";

export default function SummaryGroupsEditNewDialog({
  isOpen,
  onSubmit,
  data,
  type,
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [summaryGroup, setSummaryGroup] = React.useState({
    description: "",
  });

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);
    setSummaryGroup({
      description: "",
    });
  }, [isOpen]);

  React.useEffect(() => {
    setSummaryGroup({ ...data });
  }, [data]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          const result = await onSubmit(summaryGroup);
          if (result.success) {
            handleClose();
          }

          setLoading(false);
        },
      }}
    >
      <DialogTitle>
        {type == "edit" ? "Edit" : "Create new"} summary group
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: -0.5 }}>
          <Grid item xs={12}>
            <MDInput
              autoFocus
              required
              margin="dense"
              id="description"
              name="description"
              label="Description"
              type="text"
              value={summaryGroup.description}
              onChange={(e) => {
                setSummaryGroup({
                  ...summaryGroup,
                  description: e.target.value,
                });
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          {type == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
