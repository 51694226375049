import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import profileService from "services/profile-service";

const MaterialUI = createContext();

const AuthContext = createContext({
  isAuthenticated: false,
  isAdmin: false,
  user: null,
  login: () => {},
  register: () => {},
  logout: () => {},
  checkPermission: (a) => {},
  setPermissions: () => {},
  subscriptionPermissions: {},
});

const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [permissions, setPermissions] = useState(null);
  const [dictionaryPermissions, setDictionaryPermissions] = useState(null);
  const gettingUser = useRef(false);

  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  async function getUser() {
    if (gettingUser.current) {
      return;
    }
    gettingUser.current = true;
    const [response, data] = await profileService.getProfile();
    if (response.ok) {
      setUser(data);
      console.log("DATA: ", data);
    }
    gettingUser.current = false;
  }

  useEffect(() => {
    if (!token) return;

    setIsAuthenticated(true);
    getUser();
    navigate(location.pathname + location.search);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const parsedJWT = parseJwt(localStorage.getItem("token"));
      setIsAdmin(
        parsedJWT.id == 49 && parsedJWT.email == "support@coderaas.com"
      );
    } else {
      setIsAdmin(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }

    getUser();
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    if (!permissions) {
      setDictionaryPermissions(null);
      return;
    }

    const newDictionaryPermissions = {};
    permissions.map(({ name }) => {
      const index = name.split("-")[1];
      const value = name.split("-")[0];

      if (!newDictionaryPermissions[index])
        newDictionaryPermissions[index] = {};
      newDictionaryPermissions[index][value] = true;
    });

    setDictionaryPermissions(newDictionaryPermissions);
  }, [permissions]);

  const login = (token, refresh_token, navs) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
    getUser();
    navigate("/dashboard");
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("navs");
    setIsAuthenticated(false);
    navigate("/auth/login");
  };

  const checkPermission = (permission) => {
    return (
      dictionaryPermissions?.[permission] || {
        create: false,
        update: false,
        delete: false,
        get: false,
      }
    );
  };

  const subscriptionPermissions = useMemo(() => {
    let permissions = {
      audit: {
        create: {
          yes: false,
          reason: "",
        },
      },
      user: {
        create: {
          yes: false,
          reason: "",
        },
      },
      branchGroup: {
        create: {
          yes: false,
          reason: "",
        },
      },
      subAccount: {
        create: {
          yes: false,
          reason: "",
        },
      },
    };

    if (!user) {
      return permissions;
    }

    if (!user.account) {
      return {
        audit: {
          create: {
            yes: true,
            reason: "",
          },
        },
        user: {
          create: {
            yes: true,
            reason: "",
          },
        },
        branchGroup: {
          create: {
            yes: true,
            reason: "",
          },
        },
        subAccount: {
          create: {
            yes: true,
            reason: "",
          },
        },
      };
    }

    if (
      !user.account?.subscription &&
      !(user.account?.accountTypeId === 1 || user.account?.accountTypeId === 2)
    )
      return permissions;

    permissions.audit.create.yes = true;
    permissions.user.create.yes = true;
    permissions.subAccount.create.yes = true;
    permissions.branchGroup.create.yes = true;

    if (user.account.accountTypeId === 1 || user.account.accountTypeId === 2)
      return permissions;

    const subscription = user.account.subscription;
    const counts = { ...user.account._count, audits: user.audits };

    if (
      subscription.maxAuditsPeriod >= 0 &&
      counts.audits.auditsThisPeriod >= subscription.maxAuditsPeriod
    ) {
      permissions.audit.create.yes = false;
      permissions.audit.create.reason = `You have reached the maximum audits during the subscription period.`;
    }

    if (
      subscription.maxAuditsMonth >= 0 &&
      counts.audits.auditsThisMonth >= subscription.maxAuditsMonth
    ) {
      permissions.audit.create.yes = false;
      permissions.audit.create.reason = `You have reached the maximum audits during this month.`;
    }

    if (subscription.maxUsers >= 0 && counts.users >= subscription.maxUsers) {
      permissions.user.create.yes = false;
      permissions.user.create.reason = `You have reached the maximum users allowed.`;
    }

    if (
      subscription.maxSubAccounts >= 0 &&
      counts.accounts >= subscription.maxSubAccounts
    ) {
      permissions.subAccount.create.yes = false;
      permissions.subAccount.create.reason = `You have reached the maximum sub accounts allowed.`;
    }

    if (
      subscription.maxBranchGroups >= 0 &&
      counts.branchGroups >= subscription.maxBranchGroups
    ) {
      permissions.branchGroup.create.yes = false;
      permissions.branchGroup.create.reason = `You have reached the maximum branch groups allowed.`;
    }

    return permissions;
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdmin,
        login,
        logout,
        checkPermission,
        subscriptionPermissions,
        setPermissions,
        user,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

MaterialUI.displayName = "MaterialUIContext";

function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) =>
  dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) =>
  dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) =>
  dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) =>
  dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  AuthContextProvider,
  MaterialUIControllerProvider,
  setDarkMode,
  setDirection,
  setFixedNavbar,
  setLayout,
  setMiniSidenav,
  setOpenConfigurator,
  setSidenavColor,
  setTransparentNavbar,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
};
