import { SvgIcon } from "@mui/material";

export default function IndeterminateIcon() {
  return (
    <SvgIcon
      sx={(theme) => ({
        backgroundColor: "info.main",
        borderColor: theme.palette.info.main + " !important",
      })}
    >
      <svg viewBox="0 0 24 24" fill="none">
        <path
          d="M6 12L18 12"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
