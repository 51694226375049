import { Avatar, Pagination, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import AccessDenied from "components/AccessDenied";
import AlertDialog from "components/AlertDialog";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import TableSearch from "components/TableSearch";
import { useAuth } from "contexts/auth.context";
import { useLoading } from "contexts/loading.context";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import auditsService from "services/audits-service";
import regionsService from "services/regions-service";
import usersService from "services/users-service";
import { STATUS_COLOR } from "../../../const";
import AuditDialog from "./AuditDialog";
import AuditDialogv2 from "./AuditDialog.v2";

export default function AllAudits({ permissions }) {
  let { userId } = useParams();
  const { subscriptionPermissions } = useAuth();

  const { startLoading, stopLoading } = useLoading();

  const [rows, setRows] = useState([]);
  const [audits, setAudits] = useState(null);
  const [count, setCount] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [auditDialogOpen, setAuditDialogOpen] = useState(false);
  const [auditDialogAction, setAuditDialogAction] = useState("create");
  const [currentAudit, setCurrentAudit] = useState(null);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [users, setUsers] = useState(null);
  const [regions, setRegions] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [lastRequestQuery, setLastRequestQuery] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    sortBy: "",
  });

  const navigate = useNavigate();

  const columns = [
    { Header: "Company", accessor: "branch", align: "left", width: "100%" },
    {
      Header: "Description",
      accessor: "description",
      align: "left",
      width: "100%",
    },
    {
      Header: "Date",
      accessor: "date",
      align: "left",
      width: "100%",
    },
    {
      Header: "Assigned to",
      accessor: "assignedTo",
      align: "left",
      width: "100%",
    },
    { Header: "Status", accessor: "status", align: "left", width: "100%" },
    {
      Header: "",
      accessor: "actions",
      align: "left",
      width: "100%",
    },
  ];

  async function fetchAudits(cancelToken, sortBy, loadingId) {
    const requests = [
      usersService.getUsers(null, null, cancelToken),
      regionsService.getRegions(null, cancelToken),
    ];

    if (permissions.get) {
      /*requests.push(
        auditsService.getAudits(
          {
            userId: userId || 0,
            skip: (Number(searchParams.get("page") || 1) - 1) * 10,
            take: 10,
            sortBy: sortBy
              ? sortBy
              : searchParams.get("sortBy").replace("asce", "asc") || "",
          },
          cancelToken
        )
      );*/
      requests.push(auditsService.getStatuses(null, cancelToken));
    }
    const values = await Promise.all(requests);

    var [response, data] = values[0];
    if (response.ok) {
      setUsers(data);
    } else {
      console.log(`Error while fetching users: ${data.error}`);
    }

    [response, data] = values[1];
    if (response.ok) {
      setRegions(data);
    } else {
      console.log(`Error while fetching regions: ${data.error}`);
    }

    if (permissions.get) {
      /*[response, data] = values[2];
      if (response.ok) {
        setAudits(data.data);
        setCount(data.count);
      } else {
        console.log(`Error while fetching audits: ${data.error}`);
      }*/

      [response, data] = values[2];
      if (response.ok) {
        setStatuses(data);
      } else {
        console.log(`Error while fetching statuses: ${data.error}`);
      }
    }

    //stopLoading(loadingId);
  }

  async function fetchAuditsOnly(request, newPage, sortBy) {
    const req = request ? { ...request } : { ...lastRequestQuery };
    req.skip = newPage
      ? (newPage - 1) * 10
      : (Number(searchParams.get("page") || 1) - 1) * 10;
    req.take = 10;
    req.sortBy = sortBy
      ? sortBy.replace("asce", "asc")
      : searchParams.get("sortBy").replace("asce", "asc");

    const [ok, data] = await auditsService.getAudits(req);

    if (ok) {
      setAudits(data.data);
      setCount(data.count);
    }
  }

  useEffect(() => {
    if (!audits) return;

    setRows(
      audits.map((audit) => {
        return {
          branch: (
            <MDTypography
              variant="inherit"
              color="inherit"
              onClick={() => {
                if (permissions?.update || permissions?.get) {
                  navigate(`${audit.id}`);
                }
              }}
              sx={
                permissions?.update || permissions?.get
                  ? {
                      cursor: "pointer",
                    }
                  : null
              }
            >
              {audit.branch.branchGroup.name + " - " + audit.branch.name}
            </MDTypography>
          ),
          branchCsv: audit.branch.name,
          date: (
            <MDTypography variant="inherit" color="inherit">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }).format(new Date(audit.date))}
            </MDTypography>
          ),
          dateCsv: new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(new Date(audit.date)),
          description: (
            <MDTypography
              variant="inherit"
              color="inherit"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {audit.description}
            </MDTypography>
          ),
          descriptionCsv: audit.description,
          assignedTo: (
            <MDBox display="flex" alignItems="center" gap={1}>
              <Avatar
                sx={{ width: 24, height: 24 }}
                src={audit.assignedTo?.profileImage}
              />
              <MDTypography variant="inherit" color="inherit">
                {audit.assignedTo.name}
              </MDTypography>
            </MDBox>
          ),
          assignedToCsv: audit.assignedTo.name,
          status: (
            <>
              {/*<Chip
                label={audit.status.description}
                sx={{
                  backgroundColor: STATUS_COLOR[audit?.status?.id]?.light,
                  color: STATUS_COLOR[audit?.status?.id]?.dark,
                  borderRadius: "4px",
                  padding: "4px 10px",
                  width: "100%",
                }}
              />
              "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
              */}
              {
                <MDBadge
                  badgeContent={audit.status.description}
                  variant="gradient"
                  color={STATUS_COLOR[audit.status.id]?.preset ?? null}
                  container
                  width="90px"
                ></MDBadge>
              }
            </>
          ),
          statusCsv: audit.status.description,
          actions: (
            <>
              {permissions?.delete && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setCurrentAudit(audit);
                    setAlertDialogOpen(Math.random());
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              )}
            </>
          ),
        };
      })
    );
  }, [audits]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const loadingId = Math.random();
    let filters = localStorage.getItem("all-audits-page_filters");
    let sortBy;
    if (filters) {
      try {
        filters = JSON.parse(filters);
        if (filters.sortBy && !searchParams.get("sortBy")) {
          setSearchParams((curr) => {
            curr.set("sortBy", filters.sortBy);
            sortBy = filters.sortBy;
            return curr;
          });
        }
      } catch {}
    }
    //startLoading(loadingId);
    fetchAudits(source.token, sortBy, loadingId);
    return () => {
      source.cancel("Component unmounted");
      //stopLoading(loadingId);
    };
  }, []);

  async function handleCreateAudit(newAudit) {
    /*newAudit = JSON.parse(JSON.stringify(newAudit));
    newAudit.auditKpis = newAudit.selectedKpiIds.map((kpiId, index) => {
      const selectedDescription = newAudit.selectedKpiDescriptions[index];
      return {
        pillarId: newAudit.selectedPillarIds[index],
        description: selectedDescription,
        kpiId: kpiId,
      };
    });

    delete newAudit.selectedKpiDescriptions;
    delete newAudit.selectedKpiIds;

    newAudit.date = new Date(newAudit.date).toISOString();

    const [response, data] = await auditsService.createAudit(newAudit);
    if (response.ok) {
      await fetchAuditsOnly();
      return { success: true };
    } else {
      console.log(`Error while creating audit: ${data.error}`);
      return { error: error };
    }*/

    newAudit = JSON.parse(JSON.stringify(newAudit));

    newAudit.date = new Date(newAudit.date).toISOString();

    const [response, data] = await auditsService.createAudit(newAudit);
    if (response.ok) {
      await fetchAuditsOnly();
      return { success: true };
    } else {
      console.log(`Error while creating audit: ${data.error}`);
      return { error: error };
    }
  }

  async function handleDeleteAudit(audit) {
    const [response, data] = await auditsService.removeAudit(audit.id);
    if (response.ok) {
      await fetchAuditsOnly();
      return { success: true };
    } else {
      console.log(`Error while deleting audit: ${data.error}`);
      return { error: `Error while deleting audit: ${data.error}` };
    }
  }

  useEffect(() => {
    localStorage.setItem(
      "all-audits-page_filters",
      JSON.stringify({ sortBy: searchParams.get("sortBy") })
    );
  }, [searchParams.get("sortBy")]);

  const filterBySelectsItems = useMemo(() => {
    const filters = [
      {
        id: "status",
        items: [
          { value: 0, render: "All" },
          ...(statuses?.map((status) => {
            return {
              value: status.id,
              render: status.description,
            };
          }) || []),
        ],
        label: "Filter by status",
      },
      {
        id: "regionId",
        items: [
          { value: 0, render: "All" },
          ...(regions?.map((region) => {
            return {
              value: region.id,
              render: region.description,
            };
          }) || []),
        ],
        label: "Region",
      },
    ];

    if (!userId) {
      filters.push({
        id: "userId",
        items: [
          { value: 0, render: "All" },
          ...(users?.map((user) => {
            return {
              value: user.id,
              render: user.name,
            };
          }) || []),
        ],
        label: "Assigned to",
      });
    }
    return filters;
  }, [userId, users, statuses, regions]);

  const filterBys = useMemo(() => {
    const filters = ["Description", "Company"];

    if (!userId) {
      filters.push("User");
    }
    return filters;
  }, [userId]);

  return (
    <>
      {subscriptionPermissions.audit.create.yes && (
        <AuditDialogv2
          open={auditDialogOpen}
          setOpen={setAuditDialogOpen}
          onSubmit={handleCreateAudit}
        />
      )}
      {/*subscriptionPermissions.audit.create.yes && (
        <AuditDialog
          isOpen={auditDialogOpen}
          action={auditDialogAction}
          data={{}}
          onSubmit={handleCreateAudit}
        />
      )*/}
      <AlertDialog
        open={alertDialogOpen}
        setOpen={setAlertDialogOpen}
        title={"Are you sure?"}
        content={"Remove this audit?"}
        onOk={async () => {
          return await handleDeleteAudit(currentAudit);
        }}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Audits {permissions.get ? `(${rows.length})` : ""}
                </MDTypography>
                <MDBox display="flex" flexDirection="row" gap={1}>
                  {permissions?.create && (
                    <Tooltip
                      title={subscriptionPermissions.audit.create.reason}
                    >
                      <div>
                        <MDButton
                          disabled={!subscriptionPermissions.audit.create.yes}
                          onClick={() => {
                            setAuditDialogOpen(Math.random());
                            setAuditDialogAction("create");
                          }}
                        >
                          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                          &nbsp;add new audit
                        </MDButton>
                      </div>
                    </Tooltip>
                  )}

                  {permissions.get && (
                    <CSVLink
                      className="downloadbtn"
                      filename={`audits_${Date()}.csv`}
                      data={csvData || [[]]}
                    >
                      <MDButton>
                        <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                        &nbsp;export
                      </MDButton>
                    </CSVLink>
                  )}
                </MDBox>
              </MDBox>
              {permissions?.get ? (
                <>
                  <MDBox p={2} mt={2}>
                    <TableSearch
                      id="all-audits"
                      externalLoading={
                        !Boolean(
                          (!permissions.get || statuses) && users && regions
                        )
                      }
                      internalLoading={!Boolean(audits)}
                      fullWidth
                      setData={setAudits}
                      setCount={setCount}
                      onSearch={async (request, callback) => {
                        if (userId) {
                          request.userId = userId;
                        }

                        setLastRequestQuery(request);

                        callback(
                          await auditsService.getAudits({
                            ...request,
                            skip:
                              (Number(searchParams.get("page") || 1) - 1) * 10,
                            take: 10,
                            sortBy: searchParams
                              .get("sortBy")
                              ?.replace("asce", "asc"),
                          })
                        );
                      }}
                      filterByDate={true}
                      label={"Search audits"}
                      filterBys={filterBys}
                      filterBySelectsItems={filterBySelectsItems}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    <DataTable
                      table={{ columns: columns, rows: rows }}
                      isSorted={true}
                      canSort={[
                        "Date",
                        "Company",
                        "Description",
                        "Assigned to",
                        "Status",
                      ]} //put header names here to allow sorting
                      loading={!Boolean(audits)}
                      isScrollable={false} // permissions?.delete
                      entriesPerPage={false}
                      showTotalEntries={false}
                      manualSortFunction={(columnId, direction) => {
                        fetchAuditsOnly(null, null, `${columnId}-${direction}`);
                      }}
                      noEndBorder
                      setCsvData={setCsvData}
                    />
                    <MDBox sx={{ padding: 2 }}>
                      <Pagination
                        count={Math.floor(count / 10) + 1}
                        color={"info"}
                        variant="outlined"
                        page={Number(searchParams.get("page"))}
                        onChange={(_, newPage) => {
                          setSearchParams((curr) => {
                            curr.set("page", newPage);
                            return curr;
                          });

                          fetchAuditsOnly(null, newPage, null);
                        }}
                      />
                    </MDBox>
                  </MDBox>
                </>
              ) : (
                <AccessDenied type="component" />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
