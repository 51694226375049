import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import AllTemplates from "./components/AllTemplates";

import AccessDenied from "components/AccessDenied";
import PageContainer from "components/PageContainer";
import { useAuth } from "contexts/auth.context";
import Footer from "examples/Footer";
import { Route, Routes } from "react-router-dom";

function Templates() {
  const { checkPermission } = useAuth();

  if (
    Object.keys(checkPermission("summaryGroup")).filter(
      (key) => checkPermission("summaryGroup")[key]
    ).length === 0
  ) {
    return <AccessDenied type="page" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <PageContainer>
        <Routes>
          <Route
            index
            element={
              <AllTemplates permissions={checkPermission("summaryGroup")} />
            }
          />
        </Routes>
      </PageContainer>

      <Footer />
    </DashboardLayout>
  );
}

export default Templates;
