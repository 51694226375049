import { Box, Checkbox, Chip, Grid, Icon, IconButton } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { memo } from "react";

const RowKpi = memo(
  ({ kpi, pillarIndex, kpiIndex, utils, include, updatableDescription }) => {
    return (
      <Grid
        item
        sx={(theme) => {
          return {
            pl: 4.8,
            pr: 2.1,
            borderBottom: "1px solid " + theme.borders.borderColor,
            width: "100%",
            "&:last-child": {
              borderBottom: 0,
            },
            position: "relative",
            "&:hover": {
              backgroundColor: theme.palette.info.main + "11",
            },
          };
        }}
      >
        <Box
          sx={(theme) => ({
            height: "100%",
            width: "4px",
            backgroundColor: theme.palette.info.main,
            position: "absolute",
            left: 0,
            top: 0,
          })}
        ></Box>
        <Grid
          container
          sx={{ display: "flex", flexDirection: "row", gap: 4 }}
          alignItems={"center"}
        >
          <Grid
            item
            xs
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e, checked) => {
                if (checked) {
                  utils.handleSelectKpi(pillarIndex, kpiIndex);
                } else {
                  utils.handleDeselectKpi(pillarIndex, kpiIndex);
                }
              }}
              checked={Boolean(kpi.selected)}
            />
            <MDTypography
              sx={{
                fontSize: "0.95rem",
                fontWeight: "400",
                textWrap: "nowrap",
              }}
            >
              {kpi.referenceCode} —
            </MDTypography>
            &nbsp;
            <MDTypography
              sx={{
                fontSize: "0.95rem",
                fontWeight: "400",
              }}
            >
              {kpi.selected && updatableDescription ? "" : kpi.description}
            </MDTypography>
            {updatableDescription && kpi.selected && (
              <>
                &nbsp;
                <MDInput
                  disableInjectingGlobalStyles
                  //multiline
                  value={kpi.description}
                  onChange={(e) => {
                    utils.handleChangeKpiDescription(
                      pillarIndex,
                      kpiIndex,
                      e.target.value
                    );
                  }}
                  fullWidth
                  variant="standard"
                  sx={{
                    "& textarea": {
                      fontSize: "0.95rem",
                      lineHeight: 1.6,
                    },
                    display:
                      kpi.selected && updatableDescription ? "auto" : "none",
                  }}
                />
              </>
            )}
          </Grid>
          {include?.weight && (
            <Grid item xs={2}>
              <Chip
                label={"Weight: " + kpi.weight}
                size="small"
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  backgroundColor: "white !important",
                }}
                variant="outlined"
              />
            </Grid>
          )}
          {include?.categories && (
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                flexWrap: "wrap",
              }}
            ></Grid>
          )}

          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            {include?.kpi?.copy && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Icon>copy</Icon>
              </IconButton>
            )}
            {include?.kpi?.update && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            )}
            {include?.kpi?.delete && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default RowKpi;
