import HttpService from "./htttp.service";

class CategoriesService {
  getCategories = async (query, cancelToken) => {
    const endpoint = "categories";
    return await HttpService.get(endpoint, query, cancelToken);
  };

  createCategory = async (payload) => {
    const endpoint = "categories";
    return await HttpService.post(endpoint, payload);
  };

  createSubcategory = async (payload) => {
    const endpoint = "categories/subcategory";
    return await HttpService.post(endpoint, payload);
  };

  updateCategory = async (query, payload) => {
    const endpoint = "categories/" + query;
    return await HttpService.put(endpoint, payload);
  };

  updateSubcategory = async (query, payload) => {
    const endpoint = "categories/subcategory/" + query;
    return await HttpService.put(endpoint, payload);
  };

  removeCategory = async (query, payload) => {
    const endpoint = "categories/" + query;
    return await HttpService.delete(endpoint, payload);
  };

  removeSubcategory = async (query, payload) => {
    const endpoint = "categories/subcategory/" + query;
    return await HttpService.delete(endpoint, payload);
  };
}

export default new CategoriesService();
