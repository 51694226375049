import HttpService from "./htttp.service";

class AuditsServices {
  getAudits = async (payload, cancelToken) => {
    const endpoint = "audits";
    if (!payload) payload = {};
    payload.dontIncludeKpis = true;
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  getStatuses = async (payload, cancelToken) => {
    const endpoint = "audits/statuses";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  getAudit = async (query, cancelToken) => {
    const endpoint = "audits/" + query;
    return await HttpService.get(endpoint, query, cancelToken);
  };

  updateAudit = async (query, payload) => {
    const endpoint = "audits/" + query;
    return await HttpService.put(endpoint, payload);
  };

  updateKpi = async (query, payload) => {
    const endpoint = "audits/kpis/" + query;
    return await HttpService.put(endpoint, payload);
  };

  updateAuditKpis = async (query, payload) => {
    const endpoint = `audits/${query}/kpis`;
    return await HttpService.uploadFilesWithData(
      endpoint,
      payload.files,
      payload.body,
      "post"
    );
  };

  updateAuditBaseKpis = async (id, payload) => {
    const endpoint = `audits/${id}/kpis/base`;
    return await HttpService.put(endpoint, payload);
  };

  createAudit = async (payload) => {
    const endpoint = "audits";
    return await HttpService.post(endpoint, payload);
  };

  removeAudit = async (payload) => {
    const endpoint = "audits/" + payload;
    return await HttpService.delete(endpoint, payload);
  };

  removeBranchGroup = async (payload) => {
    const endpoint = "branches/groups/" + payload;
    return await HttpService.delete(endpoint, payload);
  };

  generateReport = async (id) => {
    const endpoint = `audits/${id}/report/assessment`;
    return await HttpService.post(endpoint);
  };

  generateAssessmentReportLink = async (id, query, payload) => {
    const endpoint = `audits/${id}/report/assessment/link`;
    return await HttpService.post(endpoint, payload, query);
  };

  getConsolidatedReport = async (id) => {
    const endpoint = `audits/${id}/report/consolidated`;
    return await HttpService.get(endpoint);
  };

  upsertConsolidatedReport = async (id, data) => {
    const endpoint = `audits/${id}/report/consolidated`;
    return await HttpService.post(endpoint, data);
  };
}

export default new AuditsServices();
