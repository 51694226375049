import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import AlertDialog from "components/AlertDialog";
import CountriesDialog from "./CountriesDialog";
import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import TableSearch from "components/TableSearch";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AccessDenied from "components/AccessDenied";
import locationService from "services/location-service";
import { Badge, Chip, Link, Stack, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function AllCountries({ permissions }) {
  const navigate = useNavigate();

  const [countries, setCountries] = useState(null);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [lastFilter, setLastFilter] = useState(null);

  const [rows, setRows] = useState([]);

  const [newDialogIsOpen, setNewDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Territories",
      accessor: "territories",
      width: "100%",
    },
  ];

  if (permissions.update || permissions.delete) {
    columns.push({
      Header: "",
      accessor: "actions",
      align: "right",
      width: "100%",
    });
  }

  useEffect(() => {
    updateRows();
  }, [countries]);

  async function handleAddCountry(newElement) {
    const [response, data] = await locationService.createCountry(newElement);
    if (response.ok) {
      const [response, data] = await locationService.getCountries(lastFilter);
      if (response.ok) {
        setCountries(data);
      }

      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  async function handleUpdateCountry(newElement) {
    const [response, data] = await locationService.updateCountry(
      newElement.id,
      newElement
    );
    if (response.ok) {
      const [response, data] = await locationService.getCountries(lastFilter);
      if (response.ok) {
        setCountries(data);
      }
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  async function handleDeleteCountry(id) {
    const [response, data] = await locationService.removeCountry(id);
    if (response.ok) {
      const [response, data] = await locationService.getCountries(lastFilter);
      if (response.ok) {
        setCountries(data);
      }
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  function updateRows() {
    setRows(
      countries?.length > 0
        ? countries.map((country) => {
            return {
              name: (
                <MDTypography variant="inherit">{country.name}</MDTypography>
              ),

              territories: (
                <Stack
                  direction="row"
                  overflow={"hidden"}
                  flexWrap={"wrap"}
                  sx={{ gap: 1 }}
                >
                  {country.territories?.length > 0
                    ? country.territories?.map((territory) => (
                        <Chip
                          size="small"
                          variant="outlined"
                          label={territory.name}
                        />
                      ))
                    : "None"}
                </Stack>
              ),

              actions: (
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="end"
                  alignItems="center"
                >
                  <Tooltip title={"View territories"}>
                    <Badge
                      badgeContent={country.territories?.length || 0}
                      size="small"
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          navigate(
                            "/territories?autocomplete=&countryId=" + country.id
                          );
                        }}
                      >
                        <Icon>terrain</Icon>
                      </IconButton>
                    </Badge>
                  </Tooltip>
                  {permissions.update && (
                    <Tooltip title="Edit">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setEditDialogIsOpen(Math.random());
                          setCurrentCountry(country);
                        }}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                  {permissions.delete && (
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setAlertIsOpen(Math.random());
                          setCurrentCountry(country);
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                </MDBox>
              ),
            };
          })
        : countries?.length === 0
        ? [
            {
              name: "No countries were found.",
            },
          ]
        : [
            {
              name: "Loading...",
            },
          ]
    );
  }

  return (
    <>
      <AlertDialog
        open={alertIsOpen}
        setOpen={setAlertIsOpen}
        title={"Are you sure?"}
        content={"Delete this country?"}
        onOk={async () => {
          await handleDeleteCountry(currentCountry.id);
        }}
      />
      <CountriesDialog isOpen={newDialogIsOpen} onSubmit={handleAddCountry} />
      <CountriesDialog
        type="edit"
        isOpen={editDialogIsOpen}
        onSubmit={handleUpdateCountry}
        data={currentCountry}
      />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Countries{" "}
                  {permissions.get ? `(${countries?.length || 0})` : ""}
                </MDTypography>
                {permissions.create && (
                  <MDButton
                    onClick={() => {
                      setNewDialogIsOpen(Math.random());
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new country
                  </MDButton>
                )}
              </MDBox>
              {permissions.get ? (
                <>
                  <MDBox p={2} mt={2}>
                    <TableSearch
                      internalLoading={!Boolean(countries)}
                      id="all-countries"
                      fullWidth
                      setData={setCountries}
                      onSearch={async (request, callback) => {
                        setLastFilter(request);
                        callback(await locationService.getCountries(request));
                      }}
                      label={"Search countries"}
                    />
                  </MDBox>
                  <MDBox>
                    <DataTable
                      table={{ columns: columns, rows: rows }}
                      loading={!Boolean(countries)}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </>
              ) : (
                <AccessDenied type="component" />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
