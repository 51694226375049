import App from "App";
import { AuthContextProvider } from "contexts/auth.context";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { MaterialUIControllerProvider } from "contexts/auth.context";
import LoadingContextProvider from "contexts/loading.context";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

import "./index.css";

root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <MaterialUIControllerProvider>
        <LoadingContextProvider>
          <App />
        </LoadingContextProvider>
      </MaterialUIControllerProvider>
    </AuthContextProvider>
  </BrowserRouter>
);
