import HttpService from "./htttp.service";

class ProfileService {
  getProfile = async (payload) => {
    const endpoint = "profiles";
    return await HttpService.get(endpoint, payload);
  };
  updateUser = async (query, payload) => {
    const endpoint = "profiles/" + query;
    return await HttpService.put(endpoint, payload);
  };
}

export default new ProfileService();
