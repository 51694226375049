import HttpService from "./htttp.service";

class UsersService {
  getUser = async (payload) => {
    const endpoint = "users/single";
    return await HttpService.get(endpoint, payload);
  };

  getUsers = async (payload, callback, cancelToken) => {
    const endpoint = "users";
    if (callback) {
      callback(await HttpService.get(endpoint, payload, cancelToken));
    } else {
      return await HttpService.get(endpoint, payload, cancelToken);
    }
  };

  createUser = async (query, payload) => {
    const endpoint = "users";
    return await HttpService.post(endpoint, payload, query);
  };

  updateUser = async (id, query, payload) => {
    const endpoint = "users/" + id;
    return await HttpService.put(endpoint, payload, query);
  };

  deleteUser = async (id, query) => {
    const endpoint = "users/" + id;
    return await HttpService.delete(endpoint, query);
  };
}

export default new UsersService();
