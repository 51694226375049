import * as React from "react";
import { debounce } from "@mui/material/utils";
import MDInput from "components/MDInput";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDSelect from "components/MDSelect";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";

import "./date.css";
import { Badge, Skeleton } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export default function TableSearch(props) {
  if (props.externalLoading) {
    return (
      <MDBox display="flex" gap={1} sx={{ m: 0, mb: -2, mt: -2 }}>
        {props.filterBySelectsItems && (
          <Skeleton
            sx={{
              flex: 1,
              height: 77,
              padding: 0,
              margin: 0,
              borderRadius: "8px",
            }}
          />
        )}
        <Skeleton
          sx={{
            flex: 8,
            height: 77,
            padding: 0,
            margin: 0,
            borderRadius: "8px",
          }}
        />
      </MDBox>
    );
  }

  return <TableSearchComponent {...props} />;
}

function TableSearchComponent({
  id,
  onSearch,
  setData,
  setCount,
  filterBys,
  sortBys,
  filterByDate,
  filterBySelectsItems,
  internalLoading,
  ...rest
}) {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [inputValue, setInputValue] = React.useState("");
  const [justMounted, setJustMounted] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [firstFetch, setFirstFetch] = React.useState(true);
  const [filterBy, setFilterBy] = React.useState(
    filterBys ? filterBys[0] : null
  );
  const [filterBySelects, setFilterBySelects] = React.useState(
    filterBySelectsItems?.map((f) => {
      return {
        ...f,
        value: f.items[0].value,
      };
    })
  );

  const [openDatePopover, setOpenDatePopover] = React.useState(false);
  const [datePopoverAnchor, setDatePopoverAnchor] = React.useState(null);
  const [dateFilter, setDateFilter] = React.useState(null);
  const [dateFilterIsActive, setDateFilterIsActive] = React.useState(false);

  const [openFiltersPopover, setOpenFiltersPopover] = React.useState(false);
  const [filterPopoverAnchor, setFilterPopoverAnchor] = React.useState(null);

  const [numberOfFilters, setNumbersOfFilters] = React.useState(0);

  const [localStorageData, setLocalStorageData] = React.useState();

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        onSearch(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    if (justMounted) {
      setJustMounted(false);
      return;
    }

    let active = true;

    setLoading(true);
    const query = {};
    query.autocomplete = inputValue;
    if (filterBys) {
      query.filterBy = filterBy;
    }
    if (filterBySelects) {
      filterBySelects.map((fs) => {
        query[fs.id] = fs.value;
      });
    }
    if (dateFilter && dateFilterIsActive) {
      query.dateFrom = dateFilter.from.toString();
      query.dateTo = dateFilter.to.toString();
    }

    setSearchParams(() => {
      let curr = new URLSearchParams();
      curr.set("page", searchParams.get("page") || 1);
      if (searchParams.get("sortBy"))
        curr.set("sortBy", searchParams.get("sortBy"));
      Object.keys(query).forEach((key) => {
        curr.set(key, query[key]);
      });
      return curr;
    });

    if (firstFetch) {
      onSearch(query, ([response, data]) => {
        if (active) {
          if (response.ok) {
            if (data.data) {
              setData(data.data);
              if (setCount) setCount(data.count);
            } else {
              setData(data);
            }
          } else {
            console.log("ERROR in autocomplete: " + data.error);
          }
          setLoading(false);
        }
      });
      setFirstFetch(false);
    } else {
      fetch(query, ([response, data]) => {
        if (active) {
          if (response.ok) {
            if (data.data) {
              setData(data.data);
              if (setCount) setCount(data.count);
            } else {
              setData(data);
            }
          } else {
            console.log("ERROR in autocomplete: " + data.error);
          }
          setLoading(false);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [inputValue, filterBy, dateFilterIsActive, filterBySelects, fetch]);

  React.useEffect(() => {
    let number = 0;
    if (dateFilterIsActive) number++;
    if (filterBySelects) {
      filterBySelects.map(({ value }) => {
        if (value != 0) {
          number++;
        }
      });
    }

    setNumbersOfFilters(number);
  }, [filterBySelects, dateFilterIsActive]);

  React.useEffect(() => {
    setInputValue(searchParams.get("autocomplete") || "");
    setFilterBy(
      ["Description", "Company", "User"].includes(searchParams.get("filterBy"))
        ? searchParams.get("filterBy")
        : "Description"
    );

    if (filterBySelects && filterBySelectsItems?.length > 0)
      setFilterBySelects((curr) => {
        if (!curr) return null;
        return curr?.map((element) => {
          return {
            ...element,
            value: Number(searchParams.get(element.id)),
          };
        });
      });

    if (searchParams.get("dateFrom") && searchParams.get("dateTo")) {
      setDateFilter({
        from: new Date(searchParams.get("dateFrom")),
        to: new Date(searchParams.get("dateTo")),
      });
      setDateFilterIsActive(true);
    } else {
      setDateFilter({
        from: new Date(),
        to: new Date(),
      });
    }

    if (
      searchParams.get("filterBy") != null ||
      searchParams.get("autocomplete") != null ||
      searchParams.get("status") != null ||
      searchParams.get("regionId") != null ||
      searchParams.get("userId") != null ||
      searchParams.get("dateFrom") != null ||
      searchParams.get("dateTo") != null
    ) {
      localStorage.setItem(
        id + "_table-search-page_filters",
        JSON.stringify({
          filterBy: searchParams.get("filterBy"),
          autocomplete: searchParams.get("autocomplete"),
          status: searchParams.get("status"),
          regionId: searchParams.get("regionId"),
          userId: searchParams.get("userId"),
          dateFrom: searchParams.get("dateFrom"),
          dateTo: searchParams.get("dateTo"),
        })
      );
    }
  }, [
    searchParams.get("filterBy"),
    searchParams.get("autocomplete"),
    searchParams.get("status"),
    searchParams.get("regionId"),
    searchParams.get("userId"),
    searchParams.get("dateFrom"),
    searchParams.get("dateTo"),
    filterBySelectsItems,
  ]);

  React.useEffect(() => {
    if (!localStorageData) return;
    let filters = localStorage.getItem(id + "_table-search-page_filters");

    if (filters) {
      try {
        filters = JSON.parse(filters);
        [
          "filterBy",
          "autocomplete",
          "status",
          "regionId",
          "userId",
          "dateFrom",
          "dateTo",
        ].forEach((field) => {
          if (filters[field] && !searchParams.get(field)) {
            setSearchParams((curr) => {
              curr.set(field, filters[field]);
              return curr;
            });
          }
        });
      } catch {}
    }
  }, [localStorageData]);

  React.useEffect(() => {
    try {
      setLocalStorageData(
        JSON.parse(localStorage.getItem(id + "_table-search-page_filters"))
      );
    } catch {}
  }, []);

  if (internalLoading) {
    return (
      <MDBox display="flex" gap={1} sx={{ m: 0, mb: -2, mt: -2 }}>
        {filterBySelectsItems && (
          <Skeleton
            sx={{
              flex: 1,
              height: 77,
              padding: 0,
              margin: 0,
              borderRadius: "8px",
            }}
          />
        )}
        <Skeleton
          sx={{
            flex: 8,
            height: 77,
            padding: 0,
            margin: 0,
            borderRadius: "8px",
          }}
        />
      </MDBox>
    );
  }

  return (
    <MDBox display="flex" gap={1}>
      {(filterBySelects || filterByDate) && (
        <>
          <IconButton
            onClick={(e) => {
              setFilterPopoverAnchor(e.currentTarget);
              setOpenFiltersPopover(true);
            }}
            aria-describedby={"filters-popover"}
          >
            <Badge badgeContent={numberOfFilters} color="primary">
              <Icon>filter_list</Icon>
            </Badge>
            {/*<MDTypography variant="caption">
              {numberOfFilters == 0 ? "" : numberOfFilters}
            </MDTypography>*/}
          </IconButton>
          <Popover
            id="filters-popover"
            open={openFiltersPopover}
            onClose={() => {
              setOpenFiltersPopover(false);
            }}
            anchorEl={filterPopoverAnchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MDBox
              sx={{
                backgroundColor: "white",
                boxShadow: "none",
              }}
              display="flex"
              flexDirection="column"
              gap={2}
              bgColor="white"
              p={2}
              m={-1}
            >
              {filterByDate && (
                <>
                  <MDButton
                    onClick={(e) => {
                      setDatePopoverAnchor(e.currentTarget);
                      setOpenDatePopover(true);
                    }}
                    aria-describedby={"date-popover"}
                    variant="outlined"
                    color={dateFilterIsActive ? "info" : "secondary"}
                    sx={{ minWidth: 120 }}
                  >
                    By date <Icon>arrow_drop_down</Icon>
                  </MDButton>
                  <Popover
                    id="date-popover"
                    open={openDatePopover}
                    onClose={() => {
                      setOpenDatePopover(false);
                    }}
                    anchorEl={datePopoverAnchor}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <MDBox
                      sx={{
                        backgroundColor: "white",
                        boxShadow: "none",
                      }}
                      bgColor="white"
                      p={0}
                      m={-1}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MDBox
                          display="flex"
                          flexDirection="column"
                          gap={2}
                          p={2}
                        >
                          <DateTimePicker
                            label="From"
                            required={true}
                            defaultValue={dayjs(new Date())}
                            value={dayjs(dateFilter?.from || new Date())}
                            onChange={(e) => {
                              setDateFilter({ ...dateFilter, from: e.$d });
                            }}
                          />
                          <DateTimePicker
                            label="To"
                            required={true}
                            defaultValue={dayjs(new Date())}
                            value={dayjs(dateFilter?.to || new Date())}
                            onChange={(e) => {
                              setDateFilter({ ...dateFilter, to: e.$d });
                            }}
                          />
                          <MDBox
                            display="flex"
                            flexDirection="row"
                            gap={1}
                            justifyContent={"end"}
                          >
                            {dateFilterIsActive && (
                              <MDButton
                                onClick={() => {
                                  setOpenDatePopover(false);
                                  setDateFilterIsActive(false);
                                }}
                                variant="gradient"
                                color="warning"
                              >
                                Clear
                              </MDButton>
                            )}
                            <MDButton
                              onClick={() => {
                                setOpenDatePopover(false);
                                setDateFilterIsActive(Math.random());
                              }}
                              variant="gradient"
                              color="info"
                            >
                              Choose
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </LocalizationProvider>
                    </MDBox>
                  </Popover>
                </>
              )}
              {filterBySelects &&
                filterBySelects.length > 0 &&
                filterBySelects.map((fs, index) => {
                  return (
                    <MDSelect
                      id={fs.label.toLowerCase() + "select"}
                      key={`Select: ${index}`}
                      sx={{ minWidth: 120 }}
                      onChange={(newValue) => {
                        const newFilterBySelects = [...filterBySelects];
                        newFilterBySelects[index].value = newValue;
                        setFilterBySelects(newFilterBySelects);
                      }}
                      value={fs.value || 0}
                      items={fs.items}
                      label={fs.label}
                    />
                  );
                })}
            </MDBox>
          </Popover>
        </>
      )}

      {filterBys && filterBys.length > 0 && (
        <MDSelect
          id="searchby-select"
          sx={{ minWidth: 120 }}
          onChange={(newValue) => {
            setFilterBy(newValue);
          }}
          value={filterBy}
          items={filterBys.map((item, index) => {
            return { value: item, render: item };
          })}
          label="Search by"
        />
      )}

      <TextField
        value={inputValue || ""}
        id="search-input"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading && <CircularProgress color="info" size={20} />}
              {!loading && <MDBox sx={{ width: 20 }}></MDBox>}
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </MDBox>
  );
}
