import { Card, Collapse, Divider, Menu } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import NotificationElement from "components/NotificationElement";
import React from "react";

export default function NotificationsMenu({
  notifications,
  anchorElement,
  onClose,
  setNotifications,
}) {
  return (
    <Menu
      anchorEl={anchorElement}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(anchorElement)}
      onClose={onClose}
      sx={{ mt: 2, mr: 1, minWidth: "500px" }}
    >
      <MDBox sx={{ p: 1, pt: 0.4 }}>
        <MDTypography variant="h5">
          Notifications ({notifications?.length || 0})
        </MDTypography>
      </MDBox>
      <MDBox sx={{ minWidth: "500px" }}>
        {notifications?.map((notification, index) => (
          <React.Fragment key={`Notification: ${notification.id}`}>
            <div
              style={{
                width: "calc(100% + 32px)",
                height: "1px",
                backgroundColor: "black",
                opacity: 0.05,
                marginLeft: "-16px",
                marginRight: "-16px",
              }}
            ></div>
            <NotificationElement
              notification={notification}
              setNotifications={setNotifications}
              handleCloseMenu={onClose}
            />
          </React.Fragment>
        ))}
        {(!notifications || notifications.length == 0) && (
          <MDTypography variant="inherit" color="inherit" pr={1} pl={1}>
            You have no unread notifications.
          </MDTypography>
        )}
      </MDBox>
    </Menu>
  );
}
