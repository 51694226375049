import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-hot-toast";

import CircularProgress from "@mui/material/CircularProgress";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ConvertibleInput from "components/ConvertibleInput";
import LoadingButton from "components/LoadingButton";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";
import MDTypography from "components/MDTypography";

import { alpha, styled, useTheme } from "@mui/material/styles";
import DataTable from "examples/Tables/DataTable/index";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import auditsService from "services/audits-service";
import branchesService from "services/branches-service";
import AuditGenerateReportDialog from "./AuditGenerateReportDialog";
import AuditInfoDialog from "./AuditInfoDialog";
import AuditKpisDialogv2 from "./dialogs/AuditKpisDialog.v2";

import { GENERATE_REPORT_STATUS_IDS, STATUS_COLOR } from "../../../../const";

import Attachments from "components/Attachments";
import moment from "moment";

import axios from "axios";
import Deleted from "components/Deleted";
import * as jsondiffpatch from "jsondiffpatch";
import AuditSummaryGroupsDialog from "./AuditSummaryGroupsDialog";
import AuditInfoDialogv2 from "./dialogs/AuditInfoDialog.v2";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CustomizedMenus = ({
  loading,
  setLoading,
  statuses,
  currentStatus,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = async (optionId) => {
    handleClose();
    setLoading(true);
    await onChange(optionId);
    setLoading(false);
  };

  return (
    <div>
      <MDBadge
        badgeContent={
          <>
            {currentStatus.description}
            {<KeyboardArrowDownIcon />}
          </>
        }
        variant="gradient"
        color={STATUS_COLOR[currentStatus.id]?.preset ?? null}
        container
        //width="105px"
        size="lg" // added
        sx={{
          opacity: loading ? ".5" : "1",
          pointerEvents: loading ? "none" : "",
          cursor: loading ? "none" : "pointer",
        }}
        onClick={handleClick}
        aria-haspopup="true"
      ></MDBadge>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {statuses
          ? statuses.map((status, index) => (
              <MenuItem
                key={index}
                onClick={() => handleStatusChange(status.id)}
                sx={{
                  p: 1,
                  px: 2,
                  m: 0,
                  mx: -1,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {status.description}
              </MenuItem>
            ))
          : null}
      </Menu>
    </div>
  );
};

export default function Audit({ setRoute, permissions }) {
  const theme = useTheme();
  const { id } = useParams();
  const [audit, setAudit] = useState(null);
  const [oldAudit, setOldAudit] = useState(null);

  const [flatPillars, setFlatPillars] = useState(null);
  const [oldFlatPillars, setOldFlatPillars] = useState(null);

  const [pillarRows, setPillarRows] = useState(null);
  const [loading, setLoading] = useState(false);

  const [auditInfoDialogOpen, setAuditInfoDialogOpen] = useState(false);
  const [auditSummaryGroupsDialogOpen, setAuditSummaryGroupsDialogOpen] =
    useState(false);
  const [generateReportDialogOpen, setGenerateReportDialogOpen] =
    useState(false);
  const [canGenerateReport, setCanGenerateReport] = useState(false);
  const [auditKpisDialogOpen, setAuditKpisDialogOpen] = useState(false);

  const [saving, setSaving] = useState(0);

  const [auditState, setAuditState] = useState(null);

  async function fetch(cancelToken) {
    setLoading(true);

    const [response, data] = await auditsService.getAudit(id, cancelToken);
    if (response.ok) {
      if (!data) {
        setAuditState("deleted");
      } else {
        setAudit(data);
        setOldAudit({ ...data, pillars: [...data.pillars] });

        setAuditState("ok");
      }
    } else {
      console.log(`Error while fetching audit: ${data.error}`);
    }
    setLoading(false);
  }

  const [contacts, setContacts] = useState(null);
  async function fetchContacts() {
    if (audit) {
      const [response, data] = await branchesService.getContactsOfBranch(
        audit.branchId
      );
      if (response.ok) {
        setContacts(data);
      } else {
        console.log(`Error while fetching contact branches: ${data.error}`);
      }
    }
  }

  const [statuses, setStatuses] = React.useState(null);
  async function fetchStatuses(cancelToken) {
    const [response, data] = await auditsService.getStatuses();
    if (response.ok) {
      setStatuses(data);
    } else {
      console.log(`Error while fetching statuses: ${data.error}`);
    }
  }

  const [siblingBranches, setSiblingBranches] = React.useState(null);
  async function fetchSiblingBranches() {
    const [response, data] = await branchesService.getBranches({
      branchGroupId: audit?.branch.branchGroupId,
      filterAuditsByGeneratedReports: true,
    });
    if (response.ok) {
      if (data && data.length > 0) {
        setSiblingBranches(data[0].branches);
      }
    } else {
      console.log(`Error while fetching branch group: ${data.error}`);
    }
  }

  useEffect(() => {
    if (!audit || !audit.pillars) {
      setFlatPillars([]);
      setOldFlatPillars([]);
      return;
    }

    let oldCollapsed = new Object();
    if (flatPillars) {
      flatPillars.map((pillar) => {
        oldCollapsed["" + pillar.id] = pillar.collapsed;
      });
    }
    const flat = [];

    for (let i = 0; i < audit.pillars.length; i++) {
      depthFirstSearch(audit.pillars[i], 0, flat, oldCollapsed);
    }

    setFlatPillars(flat);
    setOldFlatPillars(JSON.parse(JSON.stringify(flat)));

    function depthFirstSearch(node, level = 0, arr, oldCollapsed, isKpi) {
      if (node !== null) {
        arr.push({
          ...node,
          level: level,
          collapsed: isKpi
            ? false
            : oldCollapsed[node.id] !== undefined
              ? oldCollapsed[node.id]
              : false,
        });
        if (node.pillars) {
          for (const child of node.pillars) {
            depthFirstSearch(child, level + 1, arr, oldCollapsed);
          }
        }
        if (node.kpis) {
          for (const child of node.kpis) {
            depthFirstSearch(child, level + 1, arr, oldCollapsed, true);
          }
        }
      }
    }
  }, [audit?.pillars]);

  async function onChange(flatPillars, oldFlatPillars, audit, callback) {
    callback(await handleUpdateAllKpis(flatPillars, oldFlatPillars, audit));
  }

  async function save(flatPillars, oldFlatPillars, audit, callback) {
    const promise = handleUpdateAllKpis(flatPillars, oldFlatPillars, audit);

    toast.promise(promise, {
      position: "bottom-right",
      loading: "Loading",
      success: "Saved",
      error: "Not saved!",
      duration: 5,
    });

    try {
      await promise;
    } catch (err) {}
  }

  function updateRows(flatPillars) {
    function toggleCollapse(index) {
      const flat = [...flatPillars];
      flat[index].collapsed = !flat[index].collapsed;
      setFlatPillars(flat);
    }

    const collapsedParentIds = [];

    flatPillars.map((pillar, index) => {
      const isKpi = pillar.pillars ? false : true;
      if (!isKpi && !collapsedParentIds.includes(pillar.parentId)) {
        if (pillar.collapsed) {
          collapsedParentIds.push(pillar.id);
        }
      }
    });

    const f = flatPillars.map((pillar, index) => {
      const kpi = pillar;
      const isKpi = pillar.pillars ? false : true;
      const isPillarParent = pillar.pillars?.length > 0;
      const isKpiParent = pillar.kpis?.length > 0;

      const hasChildren = isPillarParent || isKpiParent;

      if (!isKpi && !collapsedParentIds.includes(pillar.parentId)) {
        return {
          description: (
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item sx={{ width: pillar.level * 40 }}></Grid>
              <Grid item>
                {hasChildren ? (
                  <IconButton
                    size="small"
                    onClick={() => {
                      toggleCollapse(index);
                    }}
                  >
                    <Icon>
                      {pillar.collapsed
                        ? "keyboard_arrow_right"
                        : "keyboard_arrow_down"}
                    </Icon>
                  </IconButton>
                ) : (
                  <IconButton sx={{ pointerEvents: "none" }} size="small">
                    <Icon></Icon>
                  </IconButton>
                )}
              </Grid>

              <Grid
                item
                sx={{
                  cursor: hasChildren ? "pointer" : "auto",
                  overflow: "hidden",
                  flex: "1",
                }}
                onClick={() => {
                  if (hasChildren) toggleCollapse(index);
                }}
              >
                <MDTypography
                  variant="inherit"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <MDBadge
                    badgeContent="PILLAR"
                    variant="gradient"
                    color="primary"
                    container
                  />
                  &nbsp;&nbsp;
                  {pillar.description}
                </MDTypography>
              </Grid>
            </Grid>
          ),
          key: `Datatablecell: ${pillar.id}`,
        };
      } else if (isKpi && !collapsedParentIds.includes(kpi.pillarId)) {
        return {
          key: `Datatablecell: ${kpi?.id} ${kpi?.pillarId} ${kpi?.auditKpis[0].id}`,
          description: (
            <MDBox
              display="flex"
              flexDirection="column"
              gap={1}
              key={`Description for ${pillar.id} ${index}`}
              sx={{ flex: 1, width: "100%" }}
            >
              <MDBox display="flex" flexDirection="row" sx={{ width: "100%" }}>
                <MDBox sx={{ width: kpi.level * 40 }}></MDBox>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  sx={{
                    flex: 1,
                    width: "100%",
                    gap: "6px",
                    overflow: "hidden",
                  }}
                >
                  <MDBadge
                    badgeContent="KPI"
                    variant="gradient"
                    color="info"
                    container
                  />
                  {/* <p>{kpi.auditKpis[0].sequenceNumber}</p> */}
                  <ConvertibleInput
                    readOnly={!kpi?.editing?.description}
                    fullWidth={true}
                    key={`convertible input: ${kpi?.id} ${kpi?.pillarId} ${kpi?.auditKpis[0].id}`}
                    sx={{
                      flex: 1,
                      whiteSpace: "wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "3",
                      WebkitBoxOrient: "vertical",
                    }}
                    multiline
                    rows={2}
                    typographyProps={{
                      variant: "inherit",
                      color: "dark",
                    }}
                    label={"Description"}
                    value={
                      !kpi?.editing?.description
                        ? kpi.auditKpis[0].description || ""
                        : kpi.auditKpis[0].descriptionTemp ||
                          kpi.auditKpis[0].description ||
                          ""
                    }
                    waitForSave={true}
                    onSave={kpi?.saveDescription}
                    onCancel={kpi?.cancelDescription}
                    onChange={(e) => {
                      const newFlatPillars = [...flatPillars];
                      newFlatPillars[index].auditKpis[0].description =
                        e.target.value;
                      setFlatPillars(newFlatPillars);
                    }}
                  />
                </MDBox>

                <MDBox>
                  {permissions?.update && !kpi?.editing?.description && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        const newFlatPillars = [...flatPillars];
                        if (!newFlatPillars[index].editing)
                          newFlatPillars[index].editing = {};
                        newFlatPillars[index].editing.description = true;
                        setFlatPillars(newFlatPillars);
                      }}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  )}
                  {kpi?.editing?.description && (
                    <>
                      {!flatPillars[index].loading && (
                        <IconButton
                          size="small"
                          onClick={() => {
                            const newFlatPillars = [...flatPillars];
                            newFlatPillars[index].cancelDescription =
                              Math.random();
                            if (!newFlatPillars[index].editing)
                              newFlatPillars[index].editing = {};
                            newFlatPillars[index].editing.description = false;
                            setFlatPillars(newFlatPillars);
                          }}
                        >
                          <Icon>close</Icon>
                        </IconButton>
                      )}
                      <IconButton
                        disabled={flatPillars[index].loading}
                        size="small"
                        onClick={async () => {
                          const newFlatPillars = [...flatPillars];
                          //newFlatPillars[index].loading = true;
                          newFlatPillars[index].saveDescription = Math.random();
                          if (!newFlatPillars[index].editing)
                            newFlatPillars[index].editing = {};
                          newFlatPillars[index].editing.description = false;
                          setFlatPillars(newFlatPillars);

                          //handleUpdateAllKpis(newFlatPillars);
                        }}
                      >
                        {flatPillars[index].loading && (
                          <CircularProgress
                            size={20}
                            thickness={6}
                            color="info"
                          />
                        )}
                        {!flatPillars[index].loading && <Icon>save</Icon>}
                      </IconButton>
                    </>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
          ),
          answer: (
            <MDSelect
              noneOption={!Boolean(kpi.auditKpis[0].kpiValueTypeAnswerId)}
              fullWidth
              readOnly={!permissions?.update}
              label={"Answer"}
              items={kpi.kpiValueType.kpiValueTypeAnswers.map((item) => ({
                value: item.id,
                render: item.description,
              }))}
              value={kpi.auditKpis[0].kpiValueTypeAnswerId || ""}
              onChange={(value) => {
                const newFlatPillars = [...flatPillars];
                newFlatPillars[index].auditKpis[0].kpiValueTypeAnswerId = value;
                setFlatPillars(newFlatPillars);
              }}
              sx={{ minWidth: 20, maxWidth: "300px", textAlign: "start" }}
            />
          ),
          selectDevRiskAction: (
            <React.Fragment
              key={`Select Devi risk action for ${pillar.id} ${index}`}
            >
              {kpi?.auditKpis[0]?.kpiValueTypeAnswerId ===
                4 /* IS DEVIATION */ && (
                <>
                  <Grid
                    container
                    direction="column"
                    spacing={1}
                    sx={{ minWidth: 200 }}
                  >
                    <Grid item xs={true}>
                      <MDSelect
                        readOnly={!permissions?.update}
                        fullWidth
                        label={"Deviation"}
                        // DEVIATION IS WHEN TYPE IS 2
                        items={kpi.kpiRiskActions
                          .filter((deviation) => deviation.type == 2)
                          .map((deviation) => ({
                            value: deviation.id,
                            render: deviation.description,
                          }))}
                        value={
                          kpi.auditKpis[0].kpiDeviationId ||
                          (() => {
                            const value =
                              kpi.kpiRiskActions.filter(
                                (deviation) =>
                                  deviation.type == 2 && deviation.isDefault
                              )[0]?.id || "";

                            if (value != "") {
                              setFlatPillars((realFlatPillars) => {
                                const newFlatPillars = [...realFlatPillars];
                                newFlatPillars[
                                  index
                                ].auditKpis[0].kpiDeviationId = value;
                                return newFlatPillars;
                              });
                            }

                            return value;
                          })()
                        }
                        onChange={(value) => {
                          const newFlatPillars = [...flatPillars];
                          newFlatPillars[index].auditKpis[0].kpiDeviationId =
                            value;

                          setFlatPillars(newFlatPillars);
                        }}
                        sx={{
                          minWidth: 200,
                          maxWidth: "300px",
                          textAlign: "start",
                        }}
                      />
                    </Grid>
                    <Grid item xs={true}>
                      <MDSelect
                        readOnly={!permissions?.update}
                        fullWidth
                        label={"Risk"}
                        // RISK IS WHEN TYPE IS 0
                        items={kpi.kpiRiskActions
                          .filter((risk) => risk.type == 0)
                          .map((risk) => ({
                            value: risk.id,
                            render: risk.description,
                          }))}
                        value={
                          kpi.auditKpis[0].kpiRiskId ||
                          (() => {
                            const value =
                              kpi.kpiRiskActions.filter(
                                (risk) => risk.type == 0 && risk.isDefault
                              )[0]?.id || "";

                            if (value != "") {
                              const newFlatPillars = [...flatPillars];
                              newFlatPillars[index].auditKpis[0].kpiRiskId =
                                value;
                              setFlatPillars([...newFlatPillars]);
                            }
                            return value;
                          })()
                        }
                        onChange={(value) => {
                          const newFlatPillars = [...flatPillars];
                          newFlatPillars[index].auditKpis[0].kpiRiskId = value;
                          setFlatPillars(newFlatPillars);
                        }}
                        sx={{
                          minWidth: 200,
                          maxWidth: "300px",
                          textAlign: "start",
                        }}
                      />
                    </Grid>
                    <Grid item xs={true}>
                      <MDSelect
                        readOnly={!permissions?.update}
                        fullWidth
                        label={"Action"}
                        // ACTION IS WHEN TYPE IS 1
                        items={kpi.kpiRiskActions
                          .filter((action) => action.type == 1)
                          .map((action) => ({
                            value: action.id,
                            render: action.description,
                          }))}
                        value={
                          kpi.auditKpis[0].kpiActionId ||
                          (() => {
                            const value =
                              kpi.kpiRiskActions.filter(
                                (action) => action.type == 1 && action.isDefault
                              )[0]?.id || "";

                            if (value != "") {
                              const newFlatPillars = [...flatPillars];
                              newFlatPillars[index].auditKpis[0].kpiActionId =
                                value;
                              setFlatPillars([...newFlatPillars]);
                            }

                            return value;
                          })()
                        }
                        onChange={(value) => {
                          const newFlatPillars = [...flatPillars];
                          newFlatPillars[index].auditKpis[0].kpiActionId =
                            value;
                          setFlatPillars([...newFlatPillars]);
                        }}
                        sx={{
                          minWidth: 200,
                          maxWidth: "300px",
                          textAlign: "start",
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {kpi?.auditKpis[0]?.kpiValueTypeAnswerId !== 4 &&
                kpi?.kpiValueType?.kpiValueTypeAnswers.find(
                  (value) =>
                    value.id === (kpi.auditKpis[0].kpiValueTypeAnswerId || 3)
                )?.kpiAnswerReasons?.length > 0 && (
                  <>
                    <Grid container direction="column" spacing={1}>
                      <Grid item xs={true}>
                        <MDSelect
                          readOnly={!permissions?.update}
                          fullWidth
                          label={"Reason"}
                          // DEVIATION IS WHEN TYPE IS 2
                          items={kpi?.kpiValueType?.kpiValueTypeAnswers
                            .find(
                              (value) =>
                                value.id ===
                                (kpi.auditKpis[0].kpiValueTypeAnswerId || 3)
                            )
                            ?.kpiAnswerReasons.map((reason) => ({
                              value: reason.id,
                              render: reason.description,
                            }))}
                          value={kpi.auditKpis[0].kpiAnswerReasonId || ""}
                          onChange={(value) => {
                            const newFlatPillars = [...flatPillars];
                            newFlatPillars[
                              index
                            ].auditKpis[0].kpiAnswerReasonId = value;

                            setFlatPillars(newFlatPillars);
                          }}
                          sx={{
                            minWidth: 200,
                            maxWidth: "300px",
                            textAlign: "start",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
            </React.Fragment>
          ),
          remarks: (
            <MDBox
              display="flex"
              flexDirection="row"
              sx={{ width: "100%" }}
              key={`Remark for ${pillar.id} ${index}`}
            >
              <ConvertibleInput
                readOnly={!kpi?.editing?.remarks}
                fullWidth={true}
                sx={{
                  flex: 1,
                  gap: "6px",
                  whiteSpace: "wrap",
                  textAlign: "start",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                }}
                multiline
                rows={2}
                typographyProps={{
                  variant: "inherit",
                  color: "dark",
                }}
                label={"Remarks"}
                waitForSave={true}
                onSave={kpi?.saveAnswerRemark}
                onCancel={kpi?.cancelAnswerRemark}
                value={
                  !kpi?.editing?.remarks
                    ? kpi.auditKpis[0].answerRemark || ""
                    : kpi.auditKpis[0].answerRemarkTemp ||
                      kpi.auditKpis[0].answerRemark ||
                      ""
                }
                onChange={(e) => {
                  const newFlatPillars = [...flatPillars];
                  newFlatPillars[index].auditKpis[0].answerRemark =
                    e.target.value;
                  setFlatPillars(newFlatPillars);
                }}
              />

              <MDBox>
                {permissions?.update && !kpi?.editing?.remarks && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      const newFlatPillars = [...flatPillars];
                      if (!newFlatPillars[index].editing)
                        newFlatPillars[index].editing = {};
                      newFlatPillars[index].editing.remarks = true;
                      setFlatPillars(newFlatPillars);
                    }}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                )}
                {kpi?.editing?.remarks && (
                  <>
                    {!flatPillars[index].loading && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          const newFlatPillars = [...flatPillars];
                          newFlatPillars[index].cancelAnswerRemark =
                            Math.random();
                          if (!newFlatPillars[index].editing)
                            newFlatPillars[index].editing = {};
                          newFlatPillars[index].editing.remarks = false;
                          setFlatPillars(newFlatPillars);
                        }}
                      >
                        <Icon>close</Icon>
                      </IconButton>
                    )}
                    <IconButton
                      disabled={flatPillars[index].loading}
                      size="small"
                      onClick={async () => {
                        const newFlatPillars = [...flatPillars];
                        //newFlatPillars[index].loading = true;
                        newFlatPillars[index].saveAnswerRemark = Math.random();
                        if (!newFlatPillars[index].editing)
                          newFlatPillars[index].editing = {};
                        newFlatPillars[index].editing.remarks = false;
                        setFlatPillars(newFlatPillars);

                        //handleUpdateAllKpis(newFlatPillars);
                      }}
                    >
                      {flatPillars[index].loading && (
                        <CircularProgress
                          size={20}
                          thickness={6}
                          color="info"
                        />
                      )}
                      {!flatPillars[index].loading && <Icon>save</Icon>}
                    </IconButton>
                  </>
                )}
              </MDBox>
            </MDBox>
          ),
          attachments: (
            <Attachments
              readOnly={!permissions.update}
              attachments={kpi.auditKpis[0].attachments || []}
              setAttachments={(attachments) => {
                const newFlatPillars = [...flatPillars];
                attachments = attachments.map((a) => {
                  if (!a.file) return a;

                  const newFile = new File(
                    [a.file],
                    `${kpi.auditKpis[0].id}_${a.file.name}`,
                    {
                      type: a.file.type,
                      kpiId: kpi.auditKpis[0].id,
                    }
                  );
                  return { ...a, file: newFile };
                });
                newFlatPillars[index].auditKpis[0].attachments = [
                  ...attachments,
                ];
                setFlatPillars(newFlatPillars);
              }}
              key={`Attachements: ${kpi.id}`}
            />
          ),
          actions: "",
        };
      } else if (!isKpi) {
        collapsedParentIds.push(pillar.id);
        return null;
      }
      return null;
    });

    const newF = f.filter((element) => element !== null);

    return newF;
  }

  useEffect(() => {
    if (flatPillars) updateRows(flatPillars);
  }, [generateReportDialogOpen]);

  const updatePillarsMemoized = useCallback(
    (flatPillars) => {
      return updateRows(flatPillars);
    },
    [flatPillars]
  );

  async function handleEditAuditInfo(newAudit) {
    /*newAudit = {
      contactId: newAudit.contactId,
      firstAnsweredAt: newAudit.firstAnsweredAt,
      branchId: newAudit.branchId,
      description: newAudit.description,
      date: newAudit.date,
      statusId: newAudit.statusId,
      userId: newAudit.userId,
      auditTypeId: newAudit.auditTypeId,
    };*/

    const [response, data] = await auditsService.updateAudit(
      audit.id,
      newAudit
    );
    if (response.ok) {
      setAudit(data);
      setOldAudit({ ...data, pillars: [...data.pillars] });
      return { success: true };
    } else {
      console.log(`Error while editing audit info: ${data.error}`);
      return { error: data.error };
    }
  }

  async function handleEditAuditKpis(auditKpis) {
    /*const id = newAudit.id;
    newAudit = {
      auditKpis: newAudit.auditKpis,
    };
    const [response, data] = await auditsService.updateAudit(id, newAudit);
    if (response.ok) {
      setAudit(data);
      setOldAudit({ ...data, pillars: [...data.pillars] });
      return { success: true };
    } else {
      console.log(`Error while editing audit info: ${data.error}`);
      return { error: data.error };
    }*/

    const [response, data] = await auditsService.updateAuditBaseKpis(audit.id, {
      auditKpis: auditKpis.map((auditKpi) => {
        const item = audit.auditKpis.find(
          (item) => item.kpiId == auditKpi.kpiId
        );

        if (item?.id) {
          auditKpi.id = item.id;
        }

        return auditKpi;
      }),
    });
    if (response.ok) {
      setAudit(JSON.parse(JSON.stringify(data)));
      setOldAudit(JSON.parse(JSON.stringify(data)));
      console.log("SET AUDITS DONE: ", data);
      return { success: true };
    } else {
      console.log(`Error while editing audit info: ${data.error}`);
      return { error: data.error };
    }
  }

  async function handleUpdateAllKpis(
    updatedFlatPillars,
    updatedOldFlatPillars,
    updatedAudit
  ) {
    let _oldFlatPillars = oldFlatPillars;
    if (updatedOldFlatPillars) _oldFlatPillars = updatedOldFlatPillars;

    let _flatPillars = flatPillars;
    if (updatedFlatPillars) _flatPillars = updatedFlatPillars;

    let _audit = audit;
    if (updatedAudit) _audit = updatedAudit;

    let kpis = _flatPillars.filter((f) => {
      return !f.pillars;
    });

    let oldKpis = _oldFlatPillars.filter((f) => {
      return !f.pillars;
    });

    kpis = kpis.map((kpi) => {
      return kpi.auditKpis[0];
    });

    oldKpis = oldKpis.map((kpi) => {
      return kpi.auditKpis[0];
    });

    const toBeUpdatedKpis = [];
    oldKpis.map((oldKpi, index) => {
      if (JSON.stringify(oldKpi) != JSON.stringify(kpis[index])) {
        const kpi = { ...kpis[index] };
        const difference = jsondiffpatch.diff(oldKpi, kpi);
        const newKpi = { id: kpi.id, kpiId: kpi.kpiId };
        Object.keys(difference).map((key) => {
          newKpi[key] = kpi[key];
        });
        toBeUpdatedKpis.push(newKpi);
      }
    });

    if (toBeUpdatedKpis.length == 0) {
      return;
    }

    let files = toBeUpdatedKpis.flatMap((kpi) => {
      return kpi.attachments;
    });

    files = files?.map((file) => file?.file).filter((file) => file) || [];

    setSaving((s) => {
      return s + 1;
    });

    const [response, data] = await auditsService.updateAuditKpis(_audit.id, {
      body: toBeUpdatedKpis,
      files: files,
    });

    let currentSaving = -1;
    setSaving((s) => {
      currentSaving = s - 1;
      return s - 1;
    });

    if (response.ok) {
      const newAudit = { ...audit, pillars: [...audit.pillars] };
      for (let i = 0; i < newAudit.pillars.length; i++) {
        for (let j = 0; j < newAudit.pillars[i].kpis?.length; j++) {
          const id = newAudit?.pillars[i]?.kpis[j]?.auditKpis[0]?.id;
          const newAuditKpi = data.find((auditKpi) => id == auditKpi.id);

          if (newAuditKpi) {
            newAudit.pillars[i].kpis[j].auditKpis[0] = newAuditKpi;
          }
        }
      }

      setAudit(newAudit);
      setOldAudit({ ...newAudit, pillars: [...newAudit.pillars] });
      /*if (currentSaving == 0) {
        setAudit(data);
        setOldAudit({ ...data, pillars: [...data.pillars] });
      }*/
    } else {
      console.log(`Error while updating kpis`);
      throw new Error(data.error);
    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetch(source.token);
    fetchStatuses(source.token);
    return () => {
      source.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    if (flatPillars) {
      let ignoredFieldsFlatPillars = JSON.parse(JSON.stringify(flatPillars))
        .map((p) => {
          p.collapsed = "";
          p.editing = "";
          p.loading = "";
          p.saveDescription = "";
          p.saveAnswerRemark = "";
          p.cancelDescription = "";
          p.cancelAnswerRemark = "";

          if (p.auditKpis?.length > 0) {
            p.auditKpis[0].descriptionTemp = "";
            p.auditKpis[0].answerRemarkTemp = "";
          }

          return p;
        })
        .filter((p) => !p.pillars);

      let ignoredFieldsOldFlatPillars = JSON.parse(
        JSON.stringify(oldFlatPillars)
      )
        .map((p) => {
          p.collapsed = "";
          p.editing = "";
          p.loading = "";
          p.saveDescription = "";
          p.saveAnswerRemark = "";
          p.cancelDescription = "";
          p.cancelAnswerRemark = "";

          if (p.auditKpis?.length > 0) {
            p.auditKpis[0].descriptionTemp = "";
            p.auditKpis[0].answerRemarkTemp = "";
          }

          return p;
        })
        .filter((p) => !p.pillars);

      const diff = jsondiffpatch.diff(
        ignoredFieldsFlatPillars,
        ignoredFieldsOldFlatPillars
      );

      setPillarRows(updatePillarsMemoized(flatPillars));

      if (diff) {
        save(flatPillars, oldFlatPillars, audit, () => {});
      }
    } else {
      setPillarRows([]);
    }
  }, [flatPillars]);

  useEffect(() => {
    if (audit) {
      setCanGenerateReport(
        GENERATE_REPORT_STATUS_IDS.includes(Number(audit.statusId))
      );
    }
  }, [audit]);

  useEffect(() => {
    if (oldAudit && setRoute) {
      setRoute(["audits", oldAudit?.description]);
    }
  }, [oldAudit]);

  useEffect(() => {
    if (!audit || !audit.pillars) {
      setFlatPillars([]);
      setOldFlatPillars([]);
      return;
    }

    let oldCollapsed = new Object();
    if (flatPillars) {
      flatPillars.map((pillar) => {
        oldCollapsed["" + pillar.id] = pillar.collapsed;
      });
    }
    const flat = [];

    for (let i = 0; i < audit.pillars.length; i++) {
      depthFirstSearch(audit.pillars[i], 0, flat, oldCollapsed);
    }

    setFlatPillars(flat);
    setOldFlatPillars(JSON.parse(JSON.stringify(flat)));

    function depthFirstSearch(node, level = 0, arr, oldCollapsed, isKpi) {
      if (node !== null) {
        arr.push({
          ...node,
          level: level,
          collapsed: isKpi
            ? false
            : oldCollapsed[node.id] !== undefined
              ? oldCollapsed[node.id]
              : false,
        });
        if (node.pillars) {
          for (const child of node.pillars) {
            depthFirstSearch(child, level + 1, arr, oldCollapsed);
          }
        }
        if (node.kpis) {
          for (const child of node.kpis) {
            depthFirstSearch(child, level + 1, arr, oldCollapsed, true);
          }
        }
      }
    }
  }, [audit?.pillars]);

  return (
    <>
      {audit && auditState === "ok" && (
        <div data-html2canvas-ignore={true}>
          <AuditSummaryGroupsDialog
            setAudit={setAudit}
            isOpen={auditSummaryGroupsDialogOpen}
            audit={audit}
          />
          <AuditGenerateReportDialog
            isOpen={generateReportDialogOpen}
            audit={audit}
            contacts={contacts}
            fetchContacts={fetchContacts}
            siblingBranches={siblingBranches}
            fetchSiblingBranches={fetchSiblingBranches}
            handleEditAuditInfo={handleEditAuditInfo}
          />

          <AuditInfoDialogv2
            open={auditInfoDialogOpen}
            setOpen={setAuditInfoDialogOpen}
            audit={audit}
            onSubmit={handleEditAuditInfo}
            //statuses={statuses}
          />
          {/*<AuditInfoDialog
            action="edit"
            isOpen={auditInfoDialogOpen}
            data={{ audit: audit }}
            onSubmit={handleEditAuditInfo}
            statuses={statuses}
          /> */}
          {/*<AuditKpisDialog
            action="edit"
            isOpen={auditKpisDialogOpen}
            setOpen={setAuditKpisDialogOpen}
            data={{ audit: audit }}
            onSubmit={handleEditAuditKpis}
          />*/}
          <AuditKpisDialogv2
            open={auditKpisDialogOpen}
            setOpen={setAuditKpisDialogOpen}
            audit={audit}
            onSubmit={handleEditAuditKpis}
          />

          <MDBox display="flex" flexDirection="column" gap={1}>
            <Card>
              <MDBox p={3} display="flex" flexDirection="column" gap={2}>
                <MDBox display="flex" justifyContent="space-between" gap={2}>
                  <MDBox display="flex" alignItems="start" gap={2}>
                    <MDTypography variant="h6">Info</MDTypography>
                    {permissions?.update && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          setAuditInfoDialogOpen(Math.random());
                        }}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    )}
                  </MDBox>
                  <MDBox display="flex" flexDirection="row" gap={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        setAuditSummaryGroupsDialogOpen(Math.random());
                      }}
                    >
                      Summary groups
                    </MDButton>
                    {canGenerateReport ? (
                      <LoadingButton
                        type="button"
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          setGenerateReportDialogOpen(Math.random());
                          fetch(null);
                        }}
                      >
                        Generate Report
                      </LoadingButton>
                    ) : null}
                  </MDBox>
                </MDBox>

                <MDBox
                  display="flex"
                  alignItems="stretch"
                  flexDirection="column"
                  gap={2}
                >
                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                  >
                    <MDTypography variant="h6">
                      {audit.branch.branchGroup.name +
                        " - " +
                        audit.branch.name}
                    </MDTypography>
                    <MDTypography
                      variant="subtitle2"
                      sx={{ fontWeight: "400" }}
                    >
                      {moment(audit.date).format("MMMM Do YYYY, h:mm a")}
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                  >
                    <MDTypography variant="h5">
                      {audit.description}
                    </MDTypography>
                    {audit.auditTypeId == 1 && (
                      <MDTypography
                        variant="h6"
                        sx={{
                          backgroundColor: theme.palette.primary.light,
                          color: theme.palette.primary.contrastText,
                          borderRadius: "4px",
                          padding: "2px 10px",
                        }}
                      >
                        SCORELESS
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                  >
                    <MDTypography
                      variant="h6"
                      sx={{
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.contrastText,
                        borderRadius: "4px",
                        padding: "2px 10px",
                      }}
                    >
                      {audit.assignedTo.name}
                    </MDTypography>
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap={1}
                    >
                      <CustomizedMenus
                        loading={loading}
                        setLoading={setLoading}
                        statuses={statuses}
                        currentStatus={{
                          id: audit?.status?.id,
                          description: audit?.status?.description,
                        }}
                        onChange={(newStatusId) =>
                          handleEditAuditInfo({
                            //...audit,
                            statusId: newStatusId,
                          })
                        }
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="row"
                    justifyContent="end"
                  >
                    {audit?.siteOut && audit?.siteIn && (
                      <MDTypography
                        variant="subtitle2"
                        sx={{
                          fontWeight: "400",
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        }}
                      >
                        Time taken on site:
                        <MDTypography variant="h6">
                          {formatTimePeriod(
                            new Date(audit.siteOut) - new Date(audit.siteIn)
                          )}
                        </MDTypography>
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>

            <Card>
              <MDBox p={3}>
                <MDBox display="flex" alignItems="center" gap={2}>
                  <MDTypography variant="h6">
                    KPIs {/*"(" + flatPillars.length + ")"*/}
                  </MDTypography>
                  {permissions?.update && audit?.statusId === 1 && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setAuditKpisDialogOpen(Math.random());
                      }}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  )}
                </MDBox>
                <DataTable
                  table={{
                    columns: [
                      {
                        Header: "Description",
                        accessor: "description",
                        align: "left",
                        width: "400px",
                        bodyCellStyle: {
                          minWidth: "300px",
                        },
                      },
                      {
                        Header: "Answer",
                        accessor: "answer",
                        align: "center",
                      },
                      {
                        Header: "Deviation, Risk & Action",
                        accessor: "selectDevRiskAction",
                        align: "center",
                        width: "400px",
                        bodyCellStyle: {
                          minWidth: "300px",
                        },
                      },
                      {
                        Header: "Remarks",
                        accessor: "remarks",
                        align: "center",
                        width: "400px",
                        bodyCellStyle: {
                          minWidth: "300px",
                        },
                      },
                      {
                        Header: "Attachments",
                        accessor: "attachments",
                        align: "center",
                      },
                      /*{
                        Header: "",
                        accessor: "actions",
                        align: "right",
                      },*/
                    ],
                    rows:
                      (pillarRows || []).length == 0
                        ? loading
                          ? [
                              {
                                description: (
                                  <MDTypography
                                    variant="inherit"
                                    color="inherit"
                                  >
                                    Loading...
                                  </MDTypography>
                                ),
                              },
                            ]
                          : [
                              {
                                description: (
                                  <MDTypography
                                    variant="inherit"
                                    color="inherit"
                                  >
                                    There are no kpis for your category.
                                  </MDTypography>
                                ),
                              },
                            ]
                        : pillarRows,
                  }}
                  isSorted={false}
                  entriesPerPage={{ defaultValue: 500 }}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </MDBox>
        </div>
      )}
      {auditState === "deleted" && <Deleted item="audit" />}
    </>
  );
}

function formatTimePeriod(ms) {
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
  const days = Math.floor(ms / (1000 * 60 * 60 * 24));

  const formattedDays = days > 0 ? `${days} day${days > 1 ? "s" : ""}, ` : "";
  const formattedHours =
    hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""}, ` : "";
  const formattedMinutes =
    minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""} and ` : "";
  const formattedSeconds = `${seconds} second${seconds > 1 ? "s" : ""}`;

  return `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`;
}
