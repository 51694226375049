import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Icon from "@mui/material/Icon";
import ListSubheader from "@mui/material/ListSubheader";
import MDBox from "components/MDBox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(item, selectedItems, theme) {
  if (!selectedItems) selectedItems = [];
  return {
    fontWeight:
      selectedItems.indexOf(item) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function GroupedSelectMultiple({
  items,
  template,
  value,
  onChange,
  label,
  ...rest
}) {
  const theme = useTheme();
  const [linearizedItems, setLinearizedItems] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (!items) return;

    if (!template) {
      const linear = [];
      for (var i = 0; i < items.length; i++) {
        linear.push({
          type: "parent",
          render: items[i].render,
        });
        for (var j = 0; j < items[i].children.length; j++) {
          linear.push({
            type: "child",
            value: items[i].children[j].value,
            render: items[i].children[j].render,
          });
        }
      }
      setLinearizedItems(linear);
    } else {
      const linear = [];
      for (var i = 0; i < items.length; i++) {
        linear.push({
          type: "parent",
          render: items[i][template.render],
        });
        for (var j = 0; j < items[i][template.children].length; j++) {
          linear.push({
            type: "child",
            value: items[i][template.children][j][template._children.value],
            render: items[i][template.children][j][template._children.render],
          });
        }
      }
      setLinearizedItems(linear);
    }
  }, [items]);

  return (
    <MDBox sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      <FormControl sx={{ minWidth: 120 }} {...rest}>
        <InputLabel id={label + "ID MGS"}>{label}</InputLabel>
        <Select
          IconComponent={() => (
            <Icon sx={{ cursor: "pointer", m: 1 }} color="inherit">
              arrow_drop_down
            </Icon>
          )}
          gap={1}
          sx={{ minHeight: 44 }}
          labelId={label + "ID MGS"}
          id="demo-multiple-name"
          multiple
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          MenuProps={MenuProps}
          open={open}
          onClose={handleClose}
          onClick={() => {
            if (!open) {
              handleOpen();
            } else {
              handleClose();
            }
          }}
          //onOpen={handleOpen}
        >
          {linearizedItems.map((item, index) => {
            if (item.type == "child") {
              return (
                <MenuItem
                  key={"Key: " + item.value}
                  value={item.value}
                  style={getStyles(item.value, value, theme)}
                >
                  {item.render}
                </MenuItem>
              );
            } else {
              return (
                <ListSubheader key={"ParentKey: " + item.render}>
                  {item.render}
                </ListSubheader>
              );
            }
          })}
        </Select>
      </FormControl>
    </MDBox>
  );
}
