import { createContext, useContext, useEffect, useState } from "react";

const LoadingContext = createContext();

export default function LoadingContextProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [subscribers, setSubscribers] = useState({});

  const startLoading = (id) => {
    setSubscribers((curr) => {
      const newCurr = { ...curr };
      newCurr[id] = true;
      return newCurr;
    });
  };

  const stopLoading = (id) => {
    setSubscribers((curr) => {
      const newCurr = { ...curr };
      delete newCurr[id];
      return newCurr;
    });
  };

  useEffect(() => {
    setLoading(Object.keys(subscribers).length !== 0);
  }, [subscribers]);

  return (
    <LoadingContext.Provider value={{ loading, startLoading, stopLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  return useContext(LoadingContext);
}
