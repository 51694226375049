import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
import { useEffect, useState, useRef } from "react";

export default function ConvertibleInput({
  value,
  multiline,
  fullWidth,
  onChange,
  readOnly,
  inputProps,
  typographyProps,
  onSave,
  waitForSave,
  onCancel,
  ...rest
}) {
  const [cursor, setCursor] = useState(null);
  const [localValue, setLocalValue] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    setLocalValue(value);
  }, [onCancel]);

  useEffect(() => {
    if (!waitForSave) {
      onChange && onChange({ target: { value: localValue } });
    }
  }, [localValue]);

  useEffect(() => {
    if (onSave) {
      onChange && onChange({ target: { value: localValue } });
    }
  }, [onSave]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    //if (!waitForSave) onChange && onChange(e);
    setLocalValue(e.target.value);
  };

  return (
    <>
      {!readOnly && (
        <MDInput
          disableInjectingGlobalStyles
          inputRef={ref}
          {...rest}
          {...inputProps}
          sx={inputProps ? { mt: 1, ...inputProps.sx } : { mt: 1 }}
          fullWidth={fullWidth}
          multiline={multiline}
          value={localValue}
          onChange={handleChange}
        />
      )}
      {readOnly && (
        <MDTypography
          color="inherit"
          variant="inherit"
          {...rest}
          {...typographyProps}
        >
          {localValue}
        </MDTypography>
      )}
    </>
  );
}
