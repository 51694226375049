import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "components/LoadingButton";
import MDButton from "components/MDButton";

export default function AlertDialog({
  open,
  setOpen,
  title,
  content,
  onOk,
  onCancel,
}) {
  //const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(Math.random());
  };

  React.useEffect(() => {
    setLoading(false);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = React.useState(false);

  return (
    <React.Fragment>
      {/*<Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
  </Button>*/}
      <Dialog
        open={open ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id={"alert-dialog-title: " + title}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => {
              if (onCancel) onCancel();
              handleClose();
            }}
          >
            Cancel
          </MDButton>
          <LoadingButton
            loading={loading}
            onClick={async () => {
              setLoading(true);
              if (onOk) await onOk();
              setLoading(false);
              handleClose();
            }}
            color="info"
            variant="gradient"
            autoFocus
          >
            Ok
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
