import { Close as CloseIcon } from "@mui/icons-material";
import {
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useMemo, useState } from "react";
import summaryGroupsService from "services/summary-groups-service";

export default function AuditSummaryGroupsDialog({
  isOpen,
  audit,
  setAudit,
  onUpdate,
  noDialog,
  mode = "edit",
  subcategoryId,
  branchGroupId,
}) {
  const [open, setOpen] = useState(false);
  const [summaryGroups, setSummaryGroups] = useState(null);
  const [currentPillar, setCurrentPillar] = useState(null);
  const [currentSummaryGroup, setCurrentSummaryGroup] = useState(null);
  const [internalAudit, setInternalAudit] = useState(audit);
  const [loading, setLoading] = useState({ fetch: true });

  const fetchSummaryGroups = async () => {
    setLoading({ ...loading, fetch: true });

    let query = {};
    if (mode == "create") {
      query = { creatingAudit: branchGroupId, subcategoryId: subcategoryId };
    }
    const [response, data] = await summaryGroupsService.getSummaryGroups(query);
    if (response.ok) {
      setSummaryGroups(data);
      if (mode == "create" && onUpdate) {
        data.map((summaryGroup) => {
          summaryGroup.pillars.map((pillarId) => {
            updatePillarSummaryGroup(pillarId, summaryGroup.id);
          });
        });
      }
    }

    setLoading({ ...loading, fetch: false });
  };

  const updatePillarSummaryGroup = async (pillarId, summaryGroupId) => {
    setLoading({ ...loading, update: true });

    const auditId = internalAudit.id;

    if (onUpdate) {
      setLoading({ ...loading, update: false });

      setInternalAudit((curr) => {
        const newAudit = JSON.parse(JSON.stringify({ ...curr }));
        const index = newAudit.pillars.findIndex(
          (pillar) => pillar.id == pillarId
        );
        if (newAudit.pillars[index])
          newAudit.pillars[index].auditPillar = {
            summaryGroupId: summaryGroupId,
          };
        return newAudit;
      });

      return onUpdate({ pillarId, summaryGroupId });
    }

    const newAudit = JSON.parse(JSON.stringify({ ...internalAudit }));
    const index = newAudit.pillars.findIndex((pillar) => pillar.id == pillarId);
    if (newAudit.pillars[index])
      newAudit.pillars[index].auditPillar = { summaryGroupId: summaryGroupId };

    const [response, data] =
      await summaryGroupsService.updatePillarSummaryGroup(
        { auditId, pillarId },
        { summaryGroupId: summaryGroupId || null }
      );

    if (response.ok) {
      setInternalAudit(newAudit);
      setAudit(newAudit);
    }

    setLoading({ ...loading, update: false });
  };

  const onDrag = (pillar) => (e) => {
    setCurrentPillar(pillar);
    e.dataTransfer.effectAllowed = "move";
  };

  const onDrop = () => (e) => {
    e.preventDefault();
    updatePillarSummaryGroup(currentPillar.id, currentSummaryGroup?.id);
  };

  const onDragOver = (summaryGroup) => (e) => {
    setCurrentSummaryGroup(summaryGroup);
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const pillars = useMemo(() => {
    return internalAudit.pillars;
  }, [internalAudit.pillars]);

  const pillarsBySummaryGroup = useMemo(() => {
    if (!summaryGroups || !pillars) return {};

    const result = {};
    summaryGroups?.map((summaryGroup) => {
      result[summaryGroup.id] = pillars.filter(
        (pillar) => pillar?.auditPillar?.summaryGroupId == summaryGroup.id
      );
    });

    result["none"] = pillars.filter(
      (pillar) =>
        !summaryGroups
          ?.map((s) => s.id)
          .includes(pillar?.auditPillar?.summaryGroupId)
    );
    return result;
  }, [internalAudit.pillars, pillars, summaryGroups]);

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  useEffect(() => {
    setInternalAudit(audit);
  }, [audit]);

  useEffect(() => {
    fetchSummaryGroups();
  }, []);

  if (noDialog) {
    if (loading.fetch) {
      return (
        <div
          style={{
            height: "500px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="info" />
        </div>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card
              sx={{
                padding: 1,
                border: "1px solid rgba(0, 0, 0, 0.05)",
              }}
              variant="outlined"
              onDrop={onDrop()}
              onDragOver={onDragOver(null)}
            >
              <MDTypography variant="h6" sx={{ marginBottom: 1 }}>
                Pillars with no summary groups
              </MDTypography>
              <Grid container spacing={1}>
                {pillarsBySummaryGroup["none"]?.length > 0 ? (
                  pillarsBySummaryGroup["none"]?.map((pillar) => {
                    return (
                      <Grid key={pillar.id} item>
                        <MDBadge
                          size="lg"
                          badgeContent={
                            <>
                              <Icon
                                fontSize="medium"
                                sx={{
                                  margin: "-10px",
                                  marginLeft: "-16px",
                                  marginRight: "0px",
                                }}
                              >
                                drag_indicator
                              </Icon>
                              {pillar.description}
                            </>
                          }
                          variant="gradient"
                          color="primary"
                          container
                          sx={{ cursor: "move" }}
                          draggable
                          onDrag={onDrag(pillar)}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <MDTypography
                    variant="caption"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(0, 0, 0, 0.5)",
                      padding: "0px 8px",
                      paddingTop: "8px",
                    }}
                  >
                    No pillars with no summary group
                  </MDTypography>
                )}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6" sx={{ marginTop: 2 }}>
              All summary groups
            </MDTypography>
          </Grid>
          {summaryGroups?.map((summaryGroup) => (
            <Grid alignItems="stretch" item xs={6} key={summaryGroup.id}>
              <Card
                sx={{
                  padding: 1,
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
                variant="outlined"
                onDrop={onDrop()}
                onDragOver={onDragOver(summaryGroup)}
              >
                <MDTypography variant="h6" sx={{ marginBottom: 1 }}>
                  {summaryGroup.description}
                </MDTypography>

                <Grid container spacing={1}>
                  {pillarsBySummaryGroup[summaryGroup.id]?.length > 0 ? (
                    pillarsBySummaryGroup[summaryGroup.id]?.map((pillar) => {
                      return (
                        <Grid key={pillar.id} item>
                          <MDBadge
                            size="lg"
                            badgeContent={
                              <>
                                <Icon
                                  fontSize="medium"
                                  sx={{
                                    margin: "-10px",
                                    marginLeft: "-16px",
                                    marginRight: "0px",
                                  }}
                                >
                                  drag_indicator
                                </Icon>
                                {pillar.description}
                              </>
                            }
                            variant="gradient"
                            color="primary"
                            container
                            sx={{ cursor: "move" }}
                            draggable
                            onDrag={onDrag(pillar)}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <MDTypography
                      variant="caption"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "rgba(0, 0, 0, 0.5)",
                        padding: "0px 8px",
                        paddingTop: "8px",
                      }}
                    >
                      No pillars in this summary group
                    </MDTypography>
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      );
    }
  }

  return (
    <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
      <DialogTitle>
        <MDBox display="flex" justifyContent="space-between" gap={2}>
          <MDTypography variant="h5">Assign summary groups</MDTypography>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </MDBox>
      </DialogTitle>
      <DialogContent style={{ position: "relative" }}>
        {loading.fetch ? (
          <div
            style={{
              height: "500px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="info" />
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                sx={{
                  padding: 1,
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
                variant="outlined"
                onDrop={onDrop()}
                onDragOver={onDragOver(null)}
              >
                <MDTypography variant="h6" sx={{ marginBottom: 1 }}>
                  Pillars with no summary groups
                </MDTypography>
                <Grid container spacing={1}>
                  {pillarsBySummaryGroup["none"]?.length > 0 ? (
                    pillarsBySummaryGroup["none"]?.map((pillar) => {
                      return (
                        <Grid key={pillar.id} item>
                          <MDBadge
                            size="lg"
                            badgeContent={
                              <>
                                <Icon
                                  fontSize="medium"
                                  sx={{
                                    margin: "-10px",
                                    marginLeft: "-16px",
                                    marginRight: "0px",
                                  }}
                                >
                                  drag_indicator
                                </Icon>
                                {pillar.description}
                              </>
                            }
                            variant="gradient"
                            color="primary"
                            container
                            sx={{ cursor: "move" }}
                            draggable
                            onDrag={onDrag(pillar)}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <MDTypography
                      variant="caption"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "rgba(0, 0, 0, 0.5)",
                        padding: "0px 8px",
                        paddingTop: "8px",
                      }}
                    >
                      No pillars with no summary group
                    </MDTypography>
                  )}
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="h6" sx={{ marginTop: 2 }}>
                All summary groups
              </MDTypography>
            </Grid>
            {summaryGroups?.map((summaryGroup) => (
              <Grid alignItems="stretch" item xs={6} key={summaryGroup.id}>
                <Card
                  sx={{
                    padding: 1,
                    border: "1px solid rgba(0, 0, 0, 0.05)",
                  }}
                  variant="outlined"
                  onDrop={onDrop()}
                  onDragOver={onDragOver(summaryGroup)}
                >
                  <MDTypography variant="h6" sx={{ marginBottom: 1 }}>
                    {summaryGroup.description}
                  </MDTypography>

                  <Grid container spacing={1}>
                    {pillarsBySummaryGroup[summaryGroup.id]?.length > 0 ? (
                      pillarsBySummaryGroup[summaryGroup.id]?.map((pillar) => {
                        return (
                          <Grid key={pillar.id} item>
                            <MDBadge
                              size="lg"
                              badgeContent={
                                <>
                                  <Icon
                                    fontSize="medium"
                                    sx={{
                                      margin: "-10px",
                                      marginLeft: "-16px",
                                      marginRight: "0px",
                                    }}
                                  >
                                    drag_indicator
                                  </Icon>
                                  {pillar.description}
                                </>
                              }
                              variant="gradient"
                              color="primary"
                              container
                              sx={{ cursor: "move" }}
                              draggable
                              onDrag={onDrag(pillar)}
                            />
                          </Grid>
                        );
                      })
                    ) : (
                      <MDTypography
                        variant="caption"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "rgba(0, 0, 0, 0.5)",
                          padding: "0px 8px",
                          paddingTop: "8px",
                        }}
                      >
                        No pillars in this summary group
                      </MDTypography>
                    )}
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {loading.update && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 200,
              backgroundColor: "rgba(245, 245, 245, 0.738)",
              /*margin: 16,
            marginTop: 0,*/

              width: "calc(100%)",
              height: "calc(100%)",

              backdropFilter: "blur(2px)",

              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
