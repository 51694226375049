import { useState } from "react";

export default function useLoading(initial = {}) {
  const [loading, setLoading] = useState(initial);

  function changeLoading(field, value) {
    setLoading((loading) => ({
      ...loading,
      [field]: value,
    }));
  }

  return { loading, changeLoading };
}
