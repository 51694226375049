/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import profileService from "services/profile-service";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function PlatformSettings({ profile }) {
  const [auditAssigned, setAuditAssigned] = useState(false);
  const [auditApproved, setAuditApproved] = useState(false);
  const [auditCompleted, setAuditCompleted] = useState(false);

  useEffect(() => {
    setAuditAssigned(profile.auditAssigned);
    setAuditApproved(profile.auditApproved);
    setAuditCompleted(profile.auditCompleted);
  }, [profile]);

  async function handleUpdateProfile(
    newAuditAssigned,
    newAuditApproved,
    newAuditCompleted
  ) {
    const [response, data] = await profileService.updateUser(profile.id, {
      auditAssigned:newAuditAssigned,
      auditApproved:newAuditApproved,
      auditCompleted:newAuditCompleted,
    });
    if (response.ok) {
    } else {
      console.log("ERROR while updating profile: " + data.error);
    }
  }

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          platform settings
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDTypography
          variant="caption"
          fontWeight="bold"
          color="text"
          textTransform="uppercase"
        >
          account
        </MDTypography>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch
              checked={auditAssigned}
              onChange={(event) => {
                const checked = event.target.checked;
                handleUpdateProfile(checked, auditApproved, auditCompleted);
                setAuditAssigned(!auditAssigned);
              }}
            />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Email me when new audit is assigned to me
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch
              checked={auditApproved}
              onChange={(event) => {
                const checked = event.target.checked;
                handleUpdateProfile(auditAssigned, checked, auditCompleted);
                setAuditApproved(checked);
              }}
            />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Email me when my audits get approved
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch
              checked={auditCompleted}
              onChange={(event) => {
                const checked = event.target.checked;
                handleUpdateProfile(auditAssigned, auditApproved, checked);
                setAuditCompleted(!auditCompleted);
              }}
            />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Email me when audit is Completed
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
