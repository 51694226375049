import HttpService from "./htttp.service";

class SectionsService {
  getSections = async (payload, cancelToken) => {
    const endpoint = "sections";
    return await HttpService.get(endpoint, payload, cancelToken);
  };
}

export default new SectionsService();
