import { Navigate } from "react-router-dom";
import routes from "../routes";
import sectionsService from "./sections-service";

async function filteredRoutes(cancelToken) {
  const [response, data] = await sectionsService.getSections(null, cancelToken);
  const filtered = [];

  if (response?.ok) {
    routes.map((route) => {
      if (route.type === "group") {
        const acceptable = [];
        route.components.map((route) => {
          const section = data.find((section) => section.key == route.key);
          if (section) {
            acceptable.push({ ...route, section });
          }
        });

        if (acceptable.length) {
          filtered.push({ ...route, components: acceptable });
        }
      } else {
        const section = data.find((section) => section.key == route.key);
        if (section) {
          filtered.push({ ...route, section });
        }
      }
    });
  } else return [];

  const dashboardRoute = filtered.find((route) => route.key === "dashboard");
  if (!dashboardRoute)
    filtered.push({
      type: "hidden",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      component: <Navigate to="/audits" />,
    });

  return filtered;
}

export default filteredRoutes;
