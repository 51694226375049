import MDButton from "components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingButton({ loading, children, ...rest }) {
  return (
    <MDButton disabled={loading} {...rest}>
      {loading ? <CircularProgress size="20px" color="light" /> : children}
    </MDButton>
  );
}
