// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import testService from "services/test-service";

import { useState } from "react";

import axios from "axios";
import MDInput from "components/MDInput";
import FormData from "form-data";

function Test() {
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);

  const handleFileSelected = (e) => {
    const files = Array.from(e.target.files);
    setFile(files[0]);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const [response, data] = await testService.uploadImage(file);
    if (response.ok) {
    } else {
    }
  }

  async function uploadImage(file) {
    let data = new FormData();
    data.append("image", file);

    await axios.post(url, data, {
      header: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data`,
      },
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <form onSubmit={handleSubmit}>
          <MDInput
            onChange={handleFileSelected}
            type="file"
            name="image"
            accept="image/*"
          />
          <MDButton type="submit">Upload Image</MDButton>
        </form>
      </Card>
    </DashboardLayout>
  );
}

export default Test;
