import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Icon, Card, CircularProgress } from "@mui/material";
import MDTypography from "components/MDTypography";
import "./index.scss";
import MDButton from "components/MDButton";
import { useSearchParams } from "react-router-dom";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const RESOLUTION = 30;

export default function BigCalendar({
  initialEvents,
  onEventsChange,
  loading,
  generatePdf,
}) {
  const [searchParams, setSearchParams] = useSearchParams({
    layout: "month",
    date: `${new Date().toLocaleString()}`,
  });

  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(new Date(searchParams.get("date")));
  const [draggedEvent, setDraggedEvent] = useState(null);
  const [dragOver, setDragOver] = useState(null);
  const [draggingOver, setDraggingOver] = useState(false);

  const debounceTimer = useRef();
  const tableRef = useRef();

  const monthRows = useMemo(() => {
    return getMonthRows(date);
  }, [date.getMonth(), date.getFullYear()]);

  const weekRows = useMemo(() => {
    return getWeekRows(date);
  }, [date]);

  const dayRows = useMemo(() => {
    return getDayRows(date);
  }, [date]);

  const onDrag = useCallback(
    (e, event) => {
      setDraggedEvent(event);
      e.dataTransfer.effectAllowed = "move";
    },
    [setDraggedEvent]
  );

  const onDrop = useCallback(
    (e, date) => {
      e.preventDefault();
      if (!date) return;
      const newEvents = [...events];
      const index = newEvents.findIndex((event) => event.id == draggedEvent.id);
      newEvents[index].date = date;
      setEvents(newEvents);
      onEventsChange && onEventsChange(newEvents, newEvents[index]);
      setDragOver(null);
      setDraggedEvent(null);
    },
    [events, setEvents, setDragOver, setDraggedEvent, draggedEvent]
  );

  const onDragOver = useCallback(
    (e, date) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = "move";
      setDragOver(date);
    },
    [setDragOver]
  );

  const getEventByDate = useCallback(
    (date) => {
      if (!date) return [];
      const eventsForDate = [];
      events.forEach((event) => {
        if (compareDatesByDay(event.date, date) === 0) {
          eventsForDate.push(event);
        }
      });
      return eventsForDate;
    },
    [events]
  );

  const getEventByTime = useCallback(
    (date) => {
      if (!date) return [];
      const eventsForTime = [];
      events.forEach((event) => {
        if (compareDatesByMinute(event.date, date) === 0) {
          eventsForTime.push(event);
        }
      });
      return eventsForTime;
    },
    [events]
  );

  const changeDate = useCallback(
    (amount, type) => {
      setDate((curr) => {
        const d = new Date(curr);

        if (type === "month") {
          let newMonth = d.getMonth() + amount;
          d.setMonth(newMonth);

          while (d.getMonth() !== ((newMonth % 12) + 12) % 12) {
            d.setDate(d.getDate() - 1);
          }
        }

        if (type === "year") {
          d.setFullYear(d.getFullYear() + amount);
        }

        if (type === "day") {
          d.setDate(d.getDate() + amount);
        }

        return d;
      });
    },
    [setDate]
  );

  const switchLayout = useCallback(
    (newLayout, newDate) => {
      //setLayout(newLayout);
      setSearchParams(
        (curr) => {
          curr.set("layout", newLayout);
          return curr;
        },
        { replace: true }
      );
      if (newDate) {
        setDate(newDate);
      }
    },
    [setSearchParams, setDate]
  );

  const dragOverDebounced = (func, delay) => {
    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      func && func();
    }, delay);
  };

  const onDragEnter = (e, value, type) => {
    e.preventDefault();
    setDraggingOver(type + value);
    dragOverDebounced(() => {
      if (draggingOver != type + value) return;
      changeDate(value, type);
      setDraggingOver(null);
    }, 200);
  };

  useEffect(() => {
    if (!tableRef.current) return;
    handleResize();

    function handleResize() {
      if (!tableRef.current) return;
      document.documentElement.style.setProperty(
        "--event-max-width",
        tableRef.current.offsetWidth / 7 - 2 + "px"
      );
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [tableRef]);

  useEffect(() => {
    if (!initialEvents) return;

    setEvents(
      initialEvents.sort((a, b) => {
        if (a.date > b.date) return 1;
        else if (a.date < b.date) return -1;
        else return 0;
      })
    );
  }, [initialEvents]);

  useEffect(() => {
    if (
      searchParams.get("layout") !== "month" &&
      searchParams.get("layout") !== "week" &&
      searchParams.get("layout") !== "day"
    ) {
      setSearchParams(
        (curr) => {
          curr.set("layout", "month");
          return curr;
        },
        { replace: true }
      );
    }
  }, [searchParams.get("layout")]);

  useEffect(() => {
    if (
      new Date(searchParams.get("date")).toLocaleDateString() ==
      date.toLocaleDateString()
    ) {
      return;
    }

    if (!searchParams.get("date")) {
      setDate(new Date());
    } else {
      setDate(new Date(searchParams.get("date")));
    }
  }, [searchParams.get("date")]);

  useEffect(() => {
    if (
      date &&
      new Date(searchParams.get("date")).toLocaleDateString() ==
        date.toLocaleDateString()
    ) {
      setSearchParams(
        (prev) => {
          prev.set("date", date.toLocaleString());
          return prev;
        },
        { replace: true }
      );
      return;
    }

    setSearchParams(
      (prev) => {
        prev.set("date", date.toLocaleString());
        return prev;
      },
      { replace: true }
    );
  }, [date]);

  return (
    <Card className="big-calendar-container" sx={{ overflow: "hidden" }}>
      <header>
        <div className="controllers">
          <div className="controller">
            {!loading.generatingPdf && (
              <button
                className="controller-button"
                onClick={() => {
                  changeDate(-1, "year");
                }}
                onDragEnterCapture={(e) => {
                  onDragEnter(e, -1, "year");
                }}
                onDragExit={() => {
                  setDraggingOver(null);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.dataTransfer.dropEffect = "move";
                }}
              >
                {/*<Icon fontSize="14" color="secondary">
                chevron_left
              </Icon>*/}
                <span
                  style={{
                    fontFamily: "Material Icons",
                    fontSize: "24px",
                    color: "gray",
                  }}
                >
                  &#xe5cb;
                </span>
              </button>
            )}
            <MDTypography className="controller-description">
              {date.getFullYear()}
            </MDTypography>
            {!loading.generatingPdf && (
              <button
                className="controller-button"
                onClick={() => {
                  changeDate(1, "year");
                }}
                onDragEnterCapture={(e) => {
                  onDragEnter(e, 1, "year");
                }}
                onDragExit={() => {
                  setDraggingOver(null);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.dataTransfer.dropEffect = "move";
                }}
              >
                {/*<Icon fontSize="14" color="secondary">
                chevron_right e5cc
              </Icon>*/}
                <span
                  style={{
                    fontFamily: "Material Icons",
                    fontSize: "24px",
                    color: "gray",
                  }}
                >
                  &#xe5cc;
                </span>
              </button>
            )}
          </div>
          <div className="controller">
            {!loading.generatingPdf && (
              <button
                className="controller-button"
                onClick={() => {
                  changeDate(-1, "month");
                }}
                onDragEnterCapture={(e) => {
                  onDragEnter(e, -1, "month");
                }}
                onDragExit={() => {
                  setDraggingOver(null);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.dataTransfer.dropEffect = "move";
                }}
              >
                {/*<Icon fontSize="14" color="secondary">
                chevron_left
              </Icon>*/}
                <span
                  style={{
                    fontFamily: "Material Icons",
                    fontSize: "24px",
                    color: "gray",
                  }}
                >
                  &#xe5cb;
                </span>
              </button>
            )}
            <MDTypography className="controller-description">
              {months[date.getMonth()]}
            </MDTypography>

            {!loading.generatingPdf && (
              <button
                className="controller-button"
                onClick={() => {
                  changeDate(1, "month");
                }}
                onDragEnterCapture={(e) => {
                  onDragEnter(e, 1, "month");
                }}
                onDragExit={() => {
                  setDraggingOver(null);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.dataTransfer.dropEffect = "move";
                }}
              >
                {/*<Icon fontSize="14" color="secondary">
                chevron_right
              </Icon>*/}

                <span
                  style={{
                    fontFamily: "Material Icons",
                    fontSize: "24px",
                    color: "gray",
                  }}
                >
                  &#xe5cc;
                </span>
              </button>
            )}
          </div>
          {["day"].includes(searchParams.get("layout")) && (
            <div className="controller">
              {!loading.generatingPdf && (
                <button
                  className="controller-button"
                  onClick={() => {
                    changeDate(-1, "day");
                  }}
                  onDragEnterCapture={(e) => {
                    onDragEnter(e, -1, "day");
                  }}
                  onDragExit={() => {
                    setDraggingOver(null);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.dataTransfer.dropEffect = "move";
                  }}
                >
                  {/*<Icon fontSize="14" color="secondary">
                chevron_left
              </Icon>*/}
                  <span
                    style={{
                      fontFamily: "Material Icons",
                      fontSize: "24px",
                      color: "gray",
                    }}
                  >
                    &#xe5cb;
                  </span>
                </button>
              )}
              <MDTypography className="controller-description">
                {date.getDate()}
              </MDTypography>
              {!loading.generatingPdf && (
                <button
                  className="controller-button"
                  onClick={() => {
                    changeDate(1, "day");
                  }}
                  onDragEnterCapture={(e) => {
                    onDragEnter(e, 1, "day");
                  }}
                  onDragExit={() => {
                    setDraggingOver(null);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.dataTransfer.dropEffect = "move";
                  }}
                >
                  {/*<Icon fontSize="14" color="secondary">
                chevron_right
              </Icon>*/}

                  <span
                    style={{
                      fontFamily: "Material Icons",
                      fontSize: "24px",
                      color: "gray",
                    }}
                  >
                    &#xe5cc;
                  </span>
                </button>
              )}
            </div>
          )}

          {searchParams.get("layout") == "week" && (
            <div className="controller">
              {!loading.generatingPdf && (
                <button
                  className="controller-button"
                  onClick={() => {
                    changeDate(-7, "day");
                  }}
                  onDragEnterCapture={(e) => {
                    onDragEnter(e, -7, "day");
                  }}
                  onDragExit={() => {
                    setDraggingOver(null);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.dataTransfer.dropEffect = "move";
                  }}
                >
                  {/*<Icon fontSize="14" color="secondary">
                chevron_left
              </Icon>*/}
                  <span
                    style={{
                      fontFamily: "Material Icons",
                      fontSize: "24px",
                      color: "gray",
                    }}
                  >
                    &#xe5cb;
                  </span>
                </button>
              )}
              <MDTypography className="controller-description">
                Week
              </MDTypography>
              {!loading.generatingPdf && (
                <button
                  className="controller-button"
                  onClick={() => {
                    changeDate(7, "day");
                  }}
                  onDragEnterCapture={(e) => {
                    onDragEnter(e, 7, "day");
                  }}
                  onDragExit={() => {
                    setDraggingOver(null);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.dataTransfer.dropEffect = "move";
                  }}
                >
                  {/*<Icon fontSize="14" color="secondary">
                chevron_right
              </Icon>*/}

                  <span
                    style={{
                      fontFamily: "Material Icons",
                      fontSize: "24px",
                      color: "gray",
                    }}
                  >
                    &#xe5cc;
                  </span>
                </button>
              )}
            </div>
          )}

          {loading.fetch && (
            <CircularProgress color="info" size={20} thickness={6} />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "12px",
          }}
        >
          {!loading.generatingPdf && (
            <>
              <div className="layout-switcher">
                <button
                  onClick={() => {
                    switchLayout("day");
                  }}
                  className={`layout-element ${
                    searchParams.get("layout") == "day" ? "selected" : ""
                  }`}
                >
                  Day
                </button>
                <button
                  onClick={() => {
                    switchLayout("week");
                  }}
                  className={`layout-element ${
                    searchParams.get("layout") == "week" ? "selected" : ""
                  }`}
                >
                  Week
                </button>
                <button
                  onClick={() => {
                    switchLayout("month");
                  }}
                  className={`layout-element ${
                    searchParams.get("layout") == "month" ? "selected" : ""
                  }`}
                >
                  Month
                </button>
              </div>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={() => {
                  generatePdf();
                }}
              >
                Generate pdf
              </MDButton>
            </>
          )}
        </div>
      </header>
      {searchParams.get("layout") == "month" && (
        <table ref={tableRef} className="big-calendar-table">
          <thead>
            <tr className="big-calendar-table-tr head">
              <th>
                <MDTypography fontWeight={"bold"}>SUN</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>MON</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>TUE</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>WED</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>THU</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>FRI</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>SAT</MDTypography>
              </th>
            </tr>
          </thead>
          <tbody>
            {monthRows.map((value, index) => (
              <tr key={index} className="big-calendar-table-tr">
                {[
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ].map((day) => {
                  return (
                    <MonthCell
                      key={day + index}
                      forwardKey={day + index}
                      date={value[day]}
                      events={getEventByDate(value[day])}
                      utils={{
                        onDrag,
                        onDrop,
                        onDragOver,
                        switchLayout,
                        loading,
                      }}
                      draggingOver={
                        compareDatesByDay(dragOver, value[day]) === 0
                      }
                    />
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {searchParams.get("layout") == "week" && (
        <table ref={tableRef} className="big-calendar-table">
          <thead>
            <tr className="big-calendar-table-tr head">
              <th>
                <MDTypography fontWeight={"bold"}>SUN</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>MON</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>TUE</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>WED</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>THU</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>FRI</MDTypography>
              </th>
              <th>
                <MDTypography fontWeight={"bold"}>SAT</MDTypography>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="big-calendar-table-tr">
              {[
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ].map((day, index) => {
                return (
                  <MonthCell
                    key={day + index}
                    forwardKey={day + index}
                    date={weekRows[day]}
                    events={getEventByDate(weekRows[day])}
                    utils={{
                      onDrag,
                      onDrop,
                      onDragOver,
                      switchLayout,
                      loading,
                    }}
                    includeMonth
                    draggingOver={
                      compareDatesByDay(dragOver, weekRows[day]) === 0
                    }
                  />
                );
              })}
            </tr>
          </tbody>
        </table>
      )}
      {searchParams.get("layout") == "day" && (
        <table className="big-calendar-table">
          <tbody>
            {dayRows.map((time) => {
              return (
                <tr
                  key={time.toISOString()}
                  className="big-calendar-table-tr time"
                >
                  <DayCell
                    date={time}
                    events={getEventByTime(time)}
                    utils={{ onDrag, onDrop, onDragOver, switchLayout }}
                    draggingOver={compareDatesByMinute(dragOver, time) === 0}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {/*loading.fetch && (
        <div className="loading">
          <CircularProgress />
        </div>
      )*/}
    </Card>
  );
}

function MonthCell({
  forwardKey,
  date,
  events,
  utils,
  draggingOver,
  includeMonth,
}) {
  const today = useMemo(() => {
    return new Date();
  }, []);

  return (
    <td
      key={forwardKey}
      className={`${Boolean(date) ? "" : "none"} ${
        date &&
        date.getDate() == today.getDate() &&
        date.getFullYear() == today.getFullYear() &&
        date.getMonth() == today.getMonth()
          ? "today"
          : ""
      } ${date && draggingOver ? "dragging-over" : ""} ${
        utils.loading.generatingPdf ? "generating-pdf" : ""
      }`}
      onDrop={(e) => {
        utils.onDrop(e, date);
      }}
      onDragOver={(e) => {
        utils.onDragOver(e, date);
      }}
      onDoubleClick={() => {
        if (!date) return;
        utils.switchLayout("day", date);
      }}
    >
      <MDTypography className="date">
        {includeMonth ? months[date.getMonth()].substring(0, 3) : ""}{" "}
        {date?.getDate() || ""}
      </MDTypography>
      <div
        className={`events ${
          utils.loading.generatingPdf ? "generating-pdf" : ""
        }`}
      >
        {utils.loading.generatingPdf || events?.length < 5 ? (
          events?.map((event) => {
            return <MonthEvent key={event.id} event={event} utils={utils} />;
          })
        ) : (
          <>
            <MonthEvent event={events[0]} utils={utils} />
            <MonthEvent event={events[1]} utils={utils} />
            <MonthEvent event={events[2]} utils={utils} />
            <button
              className="show-more"
              onClick={() => {
                if (!date) return;
                utils.switchLayout("day", date);
              }}
            >
              {events.length - 3} more...
            </button>
          </>
        )}
      </div>
    </td>
  );
}

function MonthEvent({ event, utils }) {
  return (
    <div className="month-event">
      <Event event={event} utils={utils} />
      <span className="time">{formatTime(event.date)}</span>
    </div>
  );
}

function Event({ event, utils }) {
  function isLight(color) {
    //<--color in the way '#RRGGBB
    if (color.length == 7) {
      const rgb = [
        parseInt(color.substring(1, 3), 16),
        parseInt(color.substring(3, 5), 16),
        parseInt(color.substring(5), 16),
      ];
      const luminance =
        (0.2126 * rgb[0]) / 255 +
        (0.7152 * rgb[1]) / 255 +
        (0.0722 * rgb[2]) / 255;
      return luminance > 0.5;
    }
    return false;
  }
  return (
    <div
      draggable
      className={`event ${
        utils?.loading?.generatingPdf ? "generating-pdf" : ""
      }`}
      onDragStart={(e) => {
        utils.onDrag(e, event);
      }}
      style={
        event.backgroundColor /*&& event.color*/
          ? {
              backgroundColor: event.backgroundColor,
              color: isLight(event.backgroundColor) ? "#344767" : "#ffffff",
              /*border: `1px solid #34476755`,
              boxSizing: "border-box",*/
            }
          : {}
      }
    >
      {event.description}
    </div>
  );
}

function DayCell({ date, events, utils, draggingOver }) {
  return (
    <td
      className={`${
        date && compareDatesByMinute(date, new Date()) === 0 ? "today" : ""
      }${date && draggingOver ? "dragging-over" : ""}`}
      onDrop={(e) => {
        utils.onDrop(e, date);
      }}
      onDragOver={(e) => {
        utils.onDragOver(e, date);
      }}
      onDoubleClick={() => {
        utils.switchLayout("day", date);
      }}
    >
      <MDTypography className="time-value">
        {formatTime(date) || ""}
      </MDTypography>
      <div className="events time">
        {events?.map((event) => {
          return <Event event={event} utils={utils} />;
        })}
      </div>
    </td>
  );
}

function getMonthRows(date) {
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const weeks = [];
  let currentWeek = {};

  for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
    currentWeek[daysOfWeek[day.getDay()]] = new Date(day);

    if (day.getDay() === 6) {
      weeks.push(currentWeek);
      currentWeek = {};
    }
  }

  if (Object.keys(currentWeek).length > 0) {
    weeks.push(currentWeek);
  }

  return weeks;
}

function getWeekRows(date) {
  function addDays(date, day) {
    const newDate = new Date(date); // Create a new Date object to avoid mutating the original date
    newDate.setDate(date.getDate() + day);
    return newDate;
  }

  const startDate = new Date(date);
  //const endDate = new Date(date);
  startDate.setDate(date.getDate() - date.getDay());
  //endDate.setDate(date.getDate() + (6 - date.getDay()));
  startDate.setHours(0, 0, 0, 0);
  //endDate.setHours(23, 59, 59, 999);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let currentWeek = {
    Sunday: startDate,
    Monday: addDays(startDate, 1),
    Tuesday: addDays(startDate, 2),
    Wednesday: addDays(startDate, 3),
    Thursday: addDays(startDate, 4),
    Friday: addDays(startDate, 5),
    Saturday: addDays(startDate, 6),
  };

  return currentWeek;
}

function getDayRows(date) {
  const rows = [];
  const startOfDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  const msInMinute = 60 * 1000;

  for (let i = 0; i < 24 * 60; i += RESOLUTION) {
    const newTime = new Date(startOfDay.getTime() + i * msInMinute);
    rows.push(newTime);
  }

  return rows;
}

function compareDatesByDay(date1, date2) {
  if (!date1 || !date2) return "";

  function stripTime(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  const strippedDate1 = stripTime(date1);
  const strippedDate2 = stripTime(date2);

  if (strippedDate1 > strippedDate2) {
    return 1;
  } else if (strippedDate1 < strippedDate2) {
    return -1;
  } else {
    return 0;
  }
}

function roundDate(date) {
  const msInMinute = 60 * 1000;
  const msInResolution = RESOLUTION * msInMinute;

  const roundedTime =
    Math.floor(date.getTime() / msInResolution) * msInResolution;

  return new Date(roundedTime);
}

function compareDatesByMinute(date1, date2) {
  if (!date1 || !date2) return "";

  const strippedDate1 = roundDate(date1);
  const strippedDate2 = roundDate(date2);

  if (strippedDate1 > strippedDate2) {
    return 1;
  } else if (strippedDate1 < strippedDate2) {
    return -1;
  } else {
    return 0;
  }
}

function formatTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  minutes = minutes < 10 ? "0" + minutes : minutes;

  const formattedTime =
    minutes != 0 ? `${hours}:${minutes} ${ampm}` : `${hours} ${ampm}`;
  return formattedTime;
}
