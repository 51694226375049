import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { useNavigate } from "react-router-dom";
import deletedImage from "assets/images/trash.png";

function Deleted({ item }) {
  const navigate = useNavigate();

  return (
    <MDBox pt={6} pb={3}>
      <MDBox
        mx={2}
        mt={-3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={0}
      >
        <img
          style={{ maxWidth: "150px", width: "100%" }}
          src={deletedImage}
          loading="lazy"
        />
        <MDTypography variant="h3" sx={{ textAlign: "center", mt: 3 }}>
          This{item ? " " + item : ""} was deleted!
        </MDTypography>

        <MDTypography variant="body" sx={{ textAlign: "center" }}>
          <p>You are attempting to access a deleted {item ? item : "item"}.</p>
        </MDTypography>

        <br />
        <MDButton
          sx={{ mt: 5 }}
          onClick={() => navigate(-1)}
          variant="gradient"
          color="info"
        >
          <Icon sx={{ padding: 0 }}>arrow_back</Icon>
          &nbsp;Back
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

export default Deleted;
