import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import LoadingButton from "components/LoadingButton";
import { Grid } from "@mui/material";

export default function CategoryDialog({
  isOpen,
  type,
  action,
  data,
  onSubmit,
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [category, setCategory] = React.useState({
    description: "",
  });

  const [subcategory, setSubcategory] = React.useState({
    description: "",
  });

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);

    if (action != "edit") {
      setCategory({
        description: "",
      });
      setSubcategory({
        description: "",
      });
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (action == "create") {
      if (type == "category") {
        setCategory({
          description: "",
        });
        setSubcategory({
          description: "",
        });
      } else {
        setCategory({ ...data?.category });
        setSubcategory({
          description: "",
          categoryId: data?.category?.id,
        });
      }
    } else {
      if (data?.category) setCategory({ ...data?.category });
      if (data?.subcategory)
        setSubcategory({
          ...data?.subcategory,
          categoryId: data?.category?.id,
        });
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          if (type == "category") {
            const result = await onSubmit(category);
            if (result.success) {
              handleClose();
            }

            setLoading(false);
          } else {
            const result = await onSubmit(subcategory);
            if (result.success) {
              handleClose();
            }

            setLoading(false);
          }
        },
      }}
    >
      <DialogTitle>
        {action == "edit" ? "Edit" : "Create"}{" "}
        {type == "category" ? "category" : "subcategory"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: -0.5 }}>
          <Grid item xs={12}>
            <MDInput
              id="category name-input"
              autoFocus
              required
              margin="dense"
              name="cat"
              label={type == "category" ? "Category" : "Subcategory"}
              type="text"
              value={
                type == "category"
                  ? category.description
                  : subcategory.description
              }
              onChange={(e) => {
                if (type == "category") {
                  setCategory({ ...category, description: e.target.value });
                } else {
                  setSubcategory({
                    ...subcategory,
                    description: e.target.value,
                  });
                }
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          {action == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
