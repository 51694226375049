import Applications from "layouts/apps";
import Audits from "layouts/audits";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Billing from "layouts/billing";
import Branches from "layouts/branches";
import Categories from "layouts/categories";
import Dashboard from "layouts/dashboard";
import Notifications from "layouts/notifications";
import Pillars from "layouts/pillars";
import Profile from "layouts/profile";
import RTL from "layouts/rtl";
import Subscriptions from "layouts/subscriptions";
import SummaryGroups from "layouts/summary-groups";
import Tables from "layouts/tables";
import Test from "layouts/test";
import Themes from "layouts/themes";
import Users from "layouts/users";

import UserManagement from "layouts/user-management";
import UserProfile from "layouts/user-profile";

import Login from "auth/login";
import Register from "auth/register";

import Icon from "@mui/material/Icon";

import Accounts from "layouts/accounts";
import Roles from "layouts/roles";
import CalendarPage from "./layouts/calendar";
import Countries from "layouts/countries";
import Territories from "layouts/territories";
import Regions from "layouts/regions";
import Templates from "layouts/templates";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Audits",
    key: "audits",
    icon: <Icon fontSize="small">query_stats</Icon>,
    route: "/audits",
    component: <Audits />,
  },
  {
    type: "collapse",
    name: "Calendar",
    key: "calendar",
    icon: <Icon fontSize="small">calendar_month</Icon>,
    route: "/calendar",
    component: <CalendarPage />,
  },
  {
    type: "collapse",
    name: "Accounts",
    key: "accounts",
    icon: <Icon fontSize="small">badge</Icon>,
    route: "/accounts",
    component: <Accounts />,
  },
  {
    type: "collapse",
    name: "Branches",
    key: "branches",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/branches",
    component: <Branches />,
  },
  {
    type: "collapse",
    name: "Templates",
    key: "templates",
    icon: <Icon fontSize="small">extension</Icon>,
    route: "/templates",
    component: <Templates />,
  },
  {
    type: "collapse",
    name: "Pillars",
    key: "pillars",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "/pillars",
    component: <Pillars />,
  },
  {
    type: "collapse",
    name: "Summary groups",
    key: "summary-groups",
    icon: <Icon fontSize="small">workspaces</Icon>,
    route: "/summary-groups",
    component: <SummaryGroups />,
  },
  {
    type: "collapse",
    name: "Subscriptions",
    key: "subscriptions",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/subscriptions",
    component: <Subscriptions />,
  },
  {
    type: "collapse",
    name: "Applications",
    key: "apps",
    icon: <Icon fontSize="small">apps</Icon>,
    route: "/apps",
    component: <Applications />,
  },
  {
    type: "group",
    name: "General config",
    key: "generalConfig",
    icon: <Icon fontSize="small">settings</Icon>,
    components: [
      {
        type: "collapse",
        name: "Color palettes",
        key: "colorPalettes",
        icon: <Icon fontSize="small">color_lens</Icon>,
        route: "/colorPalettes",
        component: <Themes />,
      },
      {
        type: "collapse",
        name: "Categories",
        key: "categories",
        icon: <Icon fontSize="small">category</Icon>,
        route: "/categories",
        component: <Categories />,
      },
      {
        type: "collapse",
        name: "Roles",
        key: "roles",
        icon: <Icon fontSize="small">assignment_ind</Icon>,
        route: "/roles",
        component: <Roles />,
      },
    ],
  },

  {
    type: "group",
    name: "Location",
    key: "location",
    icon: <Icon fontSize="small">push_pin</Icon>,
    components: [
      {
        type: "collapse",
        name: "Countries",
        key: "countries",
        icon: <Icon fontSize="small">flag</Icon>,
        route: "/countries",
        component: <Countries />,
        hidden: true,
      },
      {
        type: "collapse",
        name: "Territories",
        key: "territories",
        icon: <Icon fontSize="small">terrain</Icon>,
        route: "/territories",
        component: <Territories />,
        hidden: true,
      },
      {
        type: "collapse",
        name: "Regions",
        key: "regions",
        icon: <Icon fontSize="small">place</Icon>,
        route: "/regions",
        component: <Regions />,
        hidden: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: <Users />,
  },

  {
    type: "collapse",
    name: "Test",
    key: "test",
    icon: <Icon fontSize="small">bug_report</Icon>,
    route: "/test",
    component: <Test />,
  },

  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "examples",
    name: "User Profile",
    key: "user-profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user-profile",
    component: <UserProfile />,
  },
  {
    type: "examples",
    name: "User Management",
    key: "user-management",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/user-management",
    component: <UserManagement />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "auth",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
  {
    type: "auth",
    name: "Register",
    key: "register",
    icon: <Icon fontSize="small">register</Icon>,
    route: "/auth/register",
    component: <Register />,
  },
  {
    type: "auth",
    name: "Register",
    key: "register",
    icon: <Icon fontSize="small">register</Icon>,
    route: "/auth/register",
    component: <Register />,
  },

  /*{
    type: "collapse",
    name: "Dash",
    key: "dash",
    icon: <Icon fontSize="small">reigster</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Register />,
      },
      {
        name: "Sales",
        key: "sales",
        route: "/dashboards/sales",
        component: <Register />,
      },
    ],
  },*/
];

export default routes;
