import jsPDF from "jspdf";
import * as DomToImage from "dom-to-image";
import * as HtmlToImage from "html-to-image";

const footerText1 =
  "IFPC retains the copyright for the 360° Assessment Report and the Consolidated Report, formally registered in";
const footerText2 =
  "the Ministry of Economy and Trade under the serial number 7870, dated 12/12/2023";

export default async function downloadPdf(name = "data") {
  let actions = [];
  const captures = document.querySelectorAll(".printable");

  const options = {
    orientation: "p",
    unit: "px",
    format: "a4",
    putOnlyUsedFonts: true,
    compress: true,
  };
  const pdf = new jsPDF(options);

  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight() - 48;

  let startY = 0;
  let endY = 0;
  let title = null;
  let titleChanged = false;
  let lastLayout = "";

  for (let i = 0; i < captures.length; i++) {
    const capture = captures[i];
    i += 0;
    const classNames = Array.from(capture.classList);
    const id = capture.id;

    let layout;

    if (["cover"].includes(id)) {
      layout = "full-screen";
    } else if (["table"].includes(id)) {
      layout = "table-with-title";
    } else if (["table-scrollable"].includes(id)) {
      layout = "table-with-title-scrollable";
    } else if (classNames.includes("title")) {
      title = capture;
      titleChanged = true;
    } else if (classNames.includes("no-title")) {
      layout = "normal";
    } else {
      layout = "normal-with-title";
    }

    const ratio = pdfWidth / capture.offsetWidth;
    let height = 0;

    const titleHeight = title ? title?.offsetHeight * ratio : null;

    if (titleChanged) {
      actions.push({
        type: "page",
        params: ["a4", "portrait"],
      });
      startY = 0;
      endY = titleHeight;
      actions.push({
        type: "pdf-image",
        params: [title, "JPEG", 0, 0, pdfWidth, titleHeight, null, "SLOW"],
      });

      titleChanged = false;
    }

    switch (layout) {
      case "full-screen":
        height = pdfHeight;

        startY = 0;
        endY = 0;

        actions.push({
          type: "pdf-image",
          params: [capture, "JPEG", 0, startY, pdfWidth, height, null, "SLOW"],
        });
        break;
      case "full-screen-with-title":
        actions.push({ type: "page", params: ["a4", "portrait"] });
        height = (title.offsetHeight * pdfWidth) / title.offsetWidth;
        startY = 0;
        endY = height;

        actions.push({
          type: "pdf-image",
          params: [title, "JPEG", 0, startY, pdfWidth, height, null, "SLOW"],
        });

        startY = height;
        height = pdfHeight - height;
        endY = pdfHeight;

        actions.push({
          type: "pdf-image",
          params: [capture, "JPEG", 0, startY, pdfWidth, height, null, "SLOW"],
        });

        break;
      case "table-with-title-scrollable":
        {
          const table = findTable(capture);

          if (endY !== titleHeight) endY += 10;

          const [heights, images, imageWidths] = await splitLogic(
            table,
            pdfHeight,
            endY,
            ratio,
            titleHeight,
            capture
          );

          images.forEach((image, index) => {
            if (!heights[index]) {
              actions.push({ type: "page", params: ["a4", "portrait"] });
              actions.push({
                type: "pdf-image",
                params: [
                  title,
                  "JPEG",
                  0,
                  0,
                  pdfWidth,
                  titleHeight,
                  null,
                  "SLOW",
                ],
              });
              startY = 0;
              endY = titleHeight;
              return;
            }

            actions.push({
              type: "pdf-image-table",
              params: [
                image,
                "JPEG",
                0,
                endY,
                imageWidths[index],
                heights[index],
                null,
                "SLOW",
              ],
            });

            startY = endY;
            endY += heights[index];

            if (index !== images.length - 1) {
              actions.push({ type: "page", params: ["a4", "portrait"] });

              actions.push({
                type: "pdf-image",
                params: [
                  title,
                  "JPEG",
                  0,
                  0,
                  pdfWidth,
                  titleHeight,
                  null,
                  "SLOW",
                ],
              });
              startY = 0;
              endY = titleHeight;
            }
          });
        }

        break;
      case "table-with-title":
        {
          const table = findTable(capture);

          if (endY !== titleHeight) endY += 10;

          const [heights, images] = await splitLogic(
            table,
            pdfHeight,
            endY,
            ratio,
            titleHeight,
            capture
          );

          images.forEach((image, index) => {
            if (!heights[index]) {
              actions.push({ type: "page", params: ["a4", "portrait"] });
              actions.push({
                type: "pdf-image",
                params: [
                  title,
                  "JPEG",
                  0,
                  0,
                  pdfWidth,
                  titleHeight,
                  null,
                  "SLOW",
                ],
              });
              startY = 0;
              endY = titleHeight;
              return;
            }

            actions.push({
              type: "pdf-image",
              params: [
                image,
                "JPEG",
                0,
                endY,
                pdfWidth,
                heights[index],
                null,
                "SLOW",
              ],
            });

            startY = endY;
            endY += heights[index];

            if (index !== images.length - 1) {
              actions.push({ type: "page", params: ["a4", "portrait"] });

              actions.push({
                type: "pdf-image",
                params: [
                  title,
                  "JPEG",
                  0,
                  0,
                  pdfWidth,
                  titleHeight,
                  null,
                  "SLOW",
                ],
              });
              startY = 0;
              endY = titleHeight;
            }
          });
        }

        break;
      case "normal-with-title":
        height = capture.offsetHeight * ratio;

        startY = endY;
        endY = startY + height;

        if (endY > pdfHeight) {
          actions.push({ type: "page", params: ["a4", "portrait"] });
          startY = titleHeight;
          endY = titleHeight + height;
          actions.push({
            type: "pdf-image",
            params: [title, "JPEG", 0, 0, pdfWidth, titleHeight, null, "SLOW"],
          });
        }

        actions.push({
          type: "pdf-image",
          params: [capture, "JPEG", 0, startY, pdfWidth, height, null, "SLOW"],
        });

        break;
      case "normal":
        if (lastLayout == "full-screen") {
          actions.push({ type: "page", params: ["a4", "portrait"] });
          startY = 0;
          height = capture.offsetHeight * ratio;
          endY = 0;
          actions.push({
            type: "pdf-image",
            params: [capture, "JPEG", 0, endY, pdfWidth, height, null, "SLOW"],
          });

          break;
        }

        height = capture.offsetHeight * ratio;

        startY = endY;
        endY = startY + height;

        if (endY > pdfHeight) {
          actions.push({ type: "page", params: ["a4", "portrait"] });
          startY = 0;
          endY = 0;
        }

        actions.push({
          type: "pdf-image",
          params: [capture, "JPEG", 0, endY, pdfWidth, height, null, "SLOW"],
        });

        startY = endY;
        endY += height;
        break;
    }

    lastLayout = layout;
  }

  await generatePdf(actions, name, pdf, pdfWidth);
}

async function generatePdf(actions, name, pdf, pdfWidth) {
  var startTime = new Date();
  console.log("Duration: started");

  const scale = 1.5;
  const imageDatas = await Promise.all(
    actions.map((action) => {
      if (action.type === "pdf-image" || action.type === "pdf-image-table") {
        if (action.type === "pdf-image-table") {
          if (
            action.params[0].children[1].offsetWidth >=
            action.params[0].children[1].scrollWidth
          ) {
            action.type = "pdf-image";
          }
        }
        return HtmlToImage.toPng(action.params[0], {
          width:
            action.type === "pdf-image-table"
              ? (action.params[4] + 60) * scale
              : action.params[0].clientWidth * scale,
          height:
            action.type === "pdf-image-table"
              ? action.params[0].clientHeight *
                scale *
                ((action.params[4] + 60) / action.params[0].clientWidth)
              : action.params[0].clientHeight * scale,
          style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
          },
        }).catch(() => null);
        /*return DomToImage.toPng(action.params[0], {
          width: action.params[0].clientWidth * scale,
          height: action.params[0].clientHeight * scale,
          style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
          },
        }).catch(() => null);*/
      } else {
        return async () => {};
      }
    })
  );

  console.log("Duration of conversion to images: ", new Date() - startTime);
  startTime = new Date();

  actions.forEach((action, index) => {
    if (action.type === "page") {
      pdf.addPage(...action.params);
      if (!action.dontIncludeFooter) {
        pdf.setFontSize(10);
        pdf.text(footerText1, 36, pdf.internal.pageSize.getHeight() - 20);
        pdf.text(footerText2, 72, pdf.internal.pageSize.getHeight() - 10);
      }
    } else if (action.type === "footer") {
      pdf.setFontSize(10);
      pdf.text(footerText1, 36, pdf.internal.pageSize.getHeight() - 20);
      pdf.text(footerText2, 72, pdf.internal.pageSize.getHeight() - 10);
    } else if (
      action.type === "pdf-image" ||
      action.type === "pdf-image-table"
    ) {
      if (imageDatas[index]) {
        action.params[1] = "PNG";
        action.params[0] = imageDatas[index];

        action.params[4] = pdfWidth;
        pdf.addImage(...action.params);
      }
    }
  });

  console.log("Duration of pdf building: ", new Date() - startTime);

  const reportContainer = document.getElementById("table-hidden-container");
  reportContainer.innerHTML = "";

  pdf.save(name + ".pdf");
}

function constructTable(trArray, maxHeight, headerRow, ratio) {
  const table = document.createElement("table");
  const tbody = document.createElement("tbody");
  let totalHeight = 0;

  var counter = 0;

  for (let i = 0; i < trArray.length; i++) {
    const tr = trArray[i].cloneNode(true);
    tbody.appendChild(tr);
    totalHeight += trArray[i].offsetHeight;
    if (totalHeight * ratio > maxHeight) {
      tbody.removeChild(tr);
      break;
    } else {
      counter++;
    }
  }

  table.appendChild(tbody);
  return counter - 1;
}

function findTable(element) {
  if (element.tagName.toLowerCase() === "table") {
    return element;
  } else {
    for (let i = 0; i < element.children.length; i++) {
      const table = findTable(element.children[i]);
      if (table) {
        return table;
      }
    }
    return null;
  }
}

const splitLogic = async (
  table,
  pdfHeight,
  imgY,
  ratio,
  titleHeight,
  container
) => {
  var pdfHeightLeft = pdfHeight - imgY;

  const trElements = Array.from(table.rows);
  const headerRow = trElements.length > 0 ? trElements[0] : null;

  const counters = [];
  while (trElements.length > 1) {
    const counter = constructTable(
      trElements,
      counters.length === 0 ? pdfHeightLeft : pdfHeight - titleHeight,
      headerRow,
      ratio
    );

    trElements.splice(1, counter);
    counters.push(counter);
  }

  const reportContainer = document.getElementById("table-hidden-container");

  const baseObjectTableContainer = htmlToObject(container);
  const htmlTableContainers = [];
  var oldCounter = 0;
  counters.forEach((counter) => {
    if (counter <= 0) {
      htmlTableContainers.push(false);
      return;
    }
    const objectTableContainer = JSON.parse(
      JSON.stringify(baseObjectTableContainer)
    );
    var rows =
      objectTableContainer.children[1].children[0].children[1].children;
    objectTableContainer.children[1].children[0].children[1].children =
      rows.slice(oldCounter, oldCounter + counter);
    oldCounter += counter;
    const item = objectToHtml(objectTableContainer);
    htmlTableContainers.push(item);
    reportContainer.appendChild(item);
  });

  var imageHeights = htmlTableContainers.map((htmlTableContainer) =>
    htmlTableContainer ? htmlTableContainer.offsetHeight * ratio : false
  );

  var imageWidths = htmlTableContainers.map((htmlTableContainer) =>
    htmlTableContainer ? htmlTableContainer.offsetWidth * ratio * 2 : false
  );

  const imgDatas = await Promise.all(
    htmlTableContainers.map((htmlTableContainer) => htmlTableContainer)
  );
  return [imageHeights, imgDatas, imageWidths];
};

function htmlToObject(element) {
  let obj = {
    tagName: element.tagName,
    attributes: {},
    children: [],
  };

  Array.from(element.attributes).forEach((attr) => {
    obj.attributes[attr.name] = attr.value;
  });

  Array.from(element.childNodes).forEach((child) => {
    if (child.nodeType === Node.ELEMENT_NODE) {
      obj.children.push(htmlToObject(child));
    } else if (child.nodeType === Node.TEXT_NODE) {
      obj.children.push({
        nodeType: "TEXT_NODE",
        textContent: child.textContent,
      });
    }
  });

  return obj;
}

function objectToHtml(obj) {
  if (obj.nodeType === "TEXT_NODE") {
    return document.createTextNode(obj.textContent);
  }

  let element = document.createElement(obj.tagName);

  Object.keys(obj.attributes).forEach((name) => {
    element.setAttribute(name, obj.attributes[name]);
  });

  obj.children.forEach((childObj) => {
    element.appendChild(objectToHtml(childObj));
  });

  return element;
}
