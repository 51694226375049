import HttpService from "./htttp.service";

class PillarsService {
  getPillars = async (payload, cancelToken) => {
    const endpoint = "pillars";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  createPillar = async (payload) => {
    const endpoint = "pillars";
    return await HttpService.post(endpoint, payload);
  };

  removePillar = async (query) => {
    const endpoint = "pillars/" + query;
    return await HttpService.delete(endpoint);
  };

  updatePillar = async (query, payload) => {
    const endpoint = "pillars/" + query;
    return await HttpService.put(endpoint, payload);
  };
}

export default new PillarsService();
