import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import AllUsers from "./components/AllUsers.js";

import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import PageContainer from "components/PageContainer/index.js";
import Footer from "examples/Footer/index.js";
import AllAudits from "layouts/audits/components/AllAudits.js";
import Audit from "layouts/audits/components/Audit/index.js";

import { useContext, useEffect } from "react";
import { useAuth } from "contexts/auth.context";
import AccessDenied from "components/AccessDenied/index.js";

function Users() {
  const { checkPermission } = useAuth();

  if (
    Object.keys(checkPermission("user")).filter(
      (key) => checkPermission("user")[key]
    ).length === 0
  ) {
    return <AccessDenied type="page" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <PageContainer>
        <Routes>
          <Route
            index
            element={<AllUsers permissions={checkPermission("user")} />}
          />
          <Route path={"/:userId/audits"} element={<AllAudits />} />
          <Route path={"/:userId/audits/:id"} element={<Audit />} />
        </Routes>
      </PageContainer>

      <Footer />
    </DashboardLayout>
  );
}

export default Users;
