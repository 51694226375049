import { Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function Step({ step, start, end, state }) {
  return (
    <Grid xs item>
      <MDBox
        variant="outlined"
        sx={(theme) => ({
          borderRadius: "0px",
          borderRight: end ? "none" : "1px solid " + theme.borders.borderColor,
          height: "100%",
          px: 2,
          py: 1.5,
          pt: 1.25,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow:
            state === "Not Started"
              ? null
              : "inset 0 -4px 0 " +
                (state === "Complete"
                  ? theme.palette.info.main
                  : theme.palette.primary.main),
          transition: "all 0.4s ease-in-out",
        })}
      >
        <MDBox>
          <MDTypography
            variant="body1"
            sx={(theme) => ({
              fontSize: "0.8rem",
              fontWeight: "500",
              lineHeight: "1.1rem",
              color:
                state == "Complete"
                  ? theme.palette.info.main
                  : state == "Not Started"
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
              transition: "all 0.4s ease-in-out",
            })}
          >
            {state}
          </MDTypography>
          <MDTypography variant="h6">
            {step.level}. {step.label}
          </MDTypography>
          <MDTypography
            variant="body1"
            sx={{
              fontSize: "0.8rem",
              fontWeight: "400",
              lineHeight: "0.9rem",
            }}
          >
            {step.description}
          </MDTypography>
        </MDBox>
        {state === "Complete" ? (
          <Icon
            sx={(theme) => ({
              fontSize: "1.5rem !important",
              color: theme.palette.info.main,
            })}
          >
            check
          </Icon>
        ) : null}
      </MDBox>
    </Grid>
  );
}
