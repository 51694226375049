import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import AccessDenied from "components/AccessDenied";
import LoadingButton from "components/LoadingButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useRef, useState } from "react";
import { APK_DOWNLOAD_URL } from "../../../config";

export default function AllApps({ permissions }) {
  const downloadButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const handleApkDownload = () => {
    setLoading(true);
    if (downloadButtonRef.current) {
      downloadButtonRef.current.click();
    }
    setLoading(false);
  };
  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h6" color="white">
                Applications
              </MDTypography>
            </MDBox>
            {permissions?.get ? (
              <MDBox p={2} mt={2}>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={2}
                >
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap={1}
                  >
                    <MDTypography variant="h6">
                      Download the android application latest verison
                    </MDTypography>
                    <MDTypography variant="caption">
                      Info about this version changes
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap={1}
                  >
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexWrap="wrap"
                      color="text"
                      px={1.5}
                      pt={1.5}
                      mb={0}
                      pb={0}
                      sx={{ display: "none" }}
                    >
                      Download the
                      <Link href={APK_DOWNLOAD_URL} ref={downloadButtonRef}>
                        <MDTypography variant="button" fontWeight="medium">
                          &nbsp;Android App&nbsp;
                        </MDTypography>
                      </Link>
                    </MDBox>
                  </MDBox>
                  <LoadingButton
                    loading={loading}
                    type="button"
                    variant="gradient"
                    color="info"
                    onClick={handleApkDownload}
                  >
                    Download
                  </LoadingButton>
                </MDBox>
              </MDBox>
            ) : (
              <AccessDenied type="component" />
            )}
          </Card>
        </Grid>
      </Grid>
      {/*<DropdownSelect
        items={[
          { label: "Element 1", id: 1 },
          { id: 2, label: "opppa 2" },
        ]}
      />*/}
    </MDBox>
  );
}
