import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import AlertDialog from "components/AlertDialog";
import SummaryGroupsEditNewDialog from "./SummaryGroupsEditNewDialog";
import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import TableSearch from "components/TableSearch";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import axios from "axios";
import AccessDenied from "components/AccessDenied";
import summaryGroupsService from "services/summary-groups-service";

export default function AllSummaryGroups({ permissions }) {
  const [summaryGroups, setSummaryGroups] = useState(null);
  const [currentSummaryGroup, setCurrentSummaryGroup] = useState(null);

  const [rows, setRows] = useState([]);

  const [newDialogIsOpen, setNewDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const columns = [
    {
      Header: "Name",
      accessor: "description",
      width: "100%",
    },
  ];

  if (permissions.update || permissions.delete) {
    columns.push({
      Header: "",
      accessor: "actions",
      align: "right",
      width: "100%",
    });
  }

  /*useEffect(() => {
    const source = axios.CancelToken.source();
    fetch(source.token);
    return () => {
      source.cancel("Component unmounted");
    };
  }, []);*/

  useEffect(() => {
    updateRows();
  }, [summaryGroups]);

  /*async function fetch(cancelToken) {
    if (!permissions.get) return;
    const [response, data] = await summaryGroupsService.getSummaryGroups(
      null,
      cancelToken
    );
    if (response.ok) {
      setSummaryGroups(data);
    }
  }*/

  async function handleAddSummaryGroup(newElement) {
    const [response, data] =
      await summaryGroupsService.createSummaryGroup(newElement);
    if (response.ok) {
      setSummaryGroups(data);
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  async function handleUpdateSummaryGroup(newElement) {
    const [response, data] = await summaryGroupsService.updateSummaryGroup(
      newElement.id,
      newElement
    );
    if (response.ok) {
      setSummaryGroups(data);
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  async function handleDeleteSummaryGroup(id) {
    const [response, data] = await summaryGroupsService.removeSummaryGroup(id);
    if (response.ok) {
      setSummaryGroups(data);
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  function updateRows() {
    setRows(
      summaryGroups?.length > 0
        ? summaryGroups.map((summaryGroup) => {
            return {
              description: (
                <MDTypography variant="inherit">
                  {summaryGroup.description}
                </MDTypography>
              ),

              actions: (
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="end"
                  alignItems="center"
                >
                  {permissions.update && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setEditDialogIsOpen(Math.random());
                        setCurrentSummaryGroup(summaryGroup);
                      }}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  )}
                  {permissions.delete && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setAlertIsOpen(Math.random());
                        setCurrentSummaryGroup(summaryGroup);
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  )}
                </MDBox>
              ),
            };
          })
        : summaryGroups?.length === 0
        ? [
            {
              description: "No summary groups were found.",
            },
          ]
        : [
            {
              description: "Loading...",
            },
          ]
    );
  }

  return (
    <>
      <AlertDialog
        open={alertIsOpen}
        setOpen={setAlertIsOpen}
        title={"Are you sure?"}
        content={"Delete this summary group?"}
        onOk={async () => {
          await handleDeleteSummaryGroup(currentSummaryGroup.id);
        }}
      />
      <SummaryGroupsEditNewDialog
        isOpen={newDialogIsOpen}
        onSubmit={handleAddSummaryGroup}
      />
      <SummaryGroupsEditNewDialog
        type="edit"
        isOpen={editDialogIsOpen}
        onSubmit={handleUpdateSummaryGroup}
        data={currentSummaryGroup}
      />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Summary groups{" "}
                  {permissions.get ? `(${summaryGroups?.length || 0})` : ""}
                </MDTypography>
                {permissions.create && (
                  <MDButton
                    onClick={() => {
                      setNewDialogIsOpen(Math.random());
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new summary group
                  </MDButton>
                )}
              </MDBox>
              {permissions.get ? (
                <>
                  <MDBox p={2} mt={2}>
                    <TableSearch
                      id="all-summaryGroups"
                      internalLoading={!Boolean(summaryGroups)}
                      fullWidth
                      setData={setSummaryGroups}
                      onSearch={async (request, callback) => {
                        callback(
                          await summaryGroupsService.getSummaryGroups(request)
                        );
                      }}
                      label={"Search summary groups"}
                    />
                  </MDBox>
                  <MDBox>
                    <DataTable
                      loading={!Boolean(summaryGroups)}
                      table={{ columns: columns, rows: rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </>
              ) : (
                <AccessDenied type="component" />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
