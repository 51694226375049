import ClearIcon from "@mui/icons-material/Clear";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";

export default function FileAvatar({
  attachment,
  setAttachment,
  readOnly,
  ...props
}) {
  const theme = useTheme();

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setAttachment(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    if (attachment) {
      return () => URL.revokeObjectURL(attachment);
    }
  }, []);

  if (attachment) {
    if (attachment instanceof File) {
      return (
        <Box
          sx={{
            fill: "white",
            position: "relative",
          }}
        >
          <ClearIcon
            sx={{
              fill: "white",
              backgroundColor: "rgba(0,0,0,.2)",
              borderRadius: "50%",
              position: "absolute",
              top: "0",
              right: "0",
              zIndex: "2",
              cursor: "pointer",
            }}
            onClick={() => setAttachment(null)}
          />
          <Avatar
            sx={{
              width: "80px",
              height: "80px",
              //backgroundColor: theme.palette.primary.main,
              cursor: "pointer",
              position: "relative",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              "& img": {
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              },

              ...props.sx,
            }}
            variant="rounded"
            {...getRootProps()}
            src={URL.createObjectURL(attachment)}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(URL.createObjectURL(attachment));
            }}
          >
            <input {...getInputProps()} />
          </Avatar>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          fill: "white",
          position: "relative",
        }}
      >
        {!readOnly && (
          <ClearIcon
            sx={{
              fill: "white",
              backgroundColor: "rgba(0,0,0,.2)",
              borderRadius: "50%",
              position: "absolute",
              top: "0",
              right: "0",
              zIndex: "2",
              cursor: "pointer",
            }}
            onClick={() => setAttachment(null)}
          />
        )}
        <Avatar
          sx={{
            width: "80px",
            height: "80px",
            //backgroundColor: theme.palette.primary.main,
            cursor: "pointer",
            position: "relative",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            "& img": {
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            },

            ...props.sx,
          }}
          variant="rounded"
          //{...getRootProps()}
          src={attachment} //${UPLOAD_URL}/
        >
          {/*!readOnly && <input {...getInputProps()} />*/}
        </Avatar>
      </Box>
    );
  }

  return (
    <Avatar
      sx={{
        width: "80px",
        height: "80px",
        backgroundColor: theme.palette.primary.main,
        cursor: "pointer",
        position: "relative",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        "& img": {
          height: "100%",
          width: "100%",
          objectFit: "cover",
          objectPosition: "center",
        },

        ...props.sx,
      }}
      variant="rounded"
      {...getRootProps()}
    >
      {!readOnly && (
        <>
          <input {...getInputProps()} />
          <FileUploadIcon />
        </>
      )}
    </Avatar>
  );
}
