import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import LoadingButton from "components/LoadingButton";
import MSelect from "components/MSelect";
import toast from "react-hot-toast";
import DropdownSelect from "components/DropdownSelect";
import { Checkbox, FormControlLabel, Grid, SvgIcon } from "@mui/material";
import MDButton from "components/MDButton";
import IndeterminateIcon from "components/IndeterminateIcon";

export default function RolesDialog({ isOpen, action, data, onSubmit }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [role, setRole] = React.useState({
    description: "",
    permissions: [],
  });

  const [permissions, setPermissions] = React.useState([]);

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);
  }, [isOpen]);

  React.useEffect(() => {
    if (action == "create") {
      setRole(data?.role);
      setPermissions(data?.permissions);
    } else {
      setRole(data?.role);
      setPermissions(data?.permissions);
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          const result = await onSubmit(role);
          if (result.success) {
            handleClose();
          } else {
            if (!result.error.fields)
              toast.error(
                `${result.error.message + "\n\n" || ""}Try refreshing the page`
              );
          }

          setLoading(false);
        },
      }}
    >
      <DialogTitle>
        {action == "edit" ? "Edit" : "Create"}
        {" role"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: 0.5 }}>
          <Grid item xs={12}>
            <MDInput
              autoFocus
              required
              margin="dense"
              id="role-input"
              name="r"
              label="Role description"
              type="text"
              value={role?.description}
              onChange={(e) => {
                setRole({ ...role, description: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item container spacing={1}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        role?.permissions?.length === permissions?.length
                      }
                      indeterminate={
                        role?.permissions?.length !== permissions?.length &&
                        role?.permissions?.length !== 0
                      }
                      indeterminateIcon={<IndeterminateIcon />}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setRole({ ...role, permissions });
                        } else {
                          setRole({ ...role, permissions: [] });
                        }
                      }}
                    />
                  }
                  label="Select all"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DropdownSelect
                label="Permissions"
                id="permissions-select"
                initialValue={
                  role?.permissions?.map((permission) => permission.id) || []
                }
                items={
                  permissions?.map((permission, index) => {
                    return { id: permission.id, label: permission.description };
                  }) || []
                }
                onChange={(newPermissions) => {
                  setRole({ ...role, permissions: newPermissions });
                  //setSelectedPermission([...newPermissions]);
                }}
                disableCloseOnSelect
                multiple
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          {action == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
