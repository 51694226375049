import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  TextField,
} from "@mui/material";
import ifpcEmailSignature from "assets/images/ifpc-email-signature.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function isValidEmailLocalPart(localPart) {
  if (localPart?.length === 0) return true;
  if (!localPart) return false;
  const regex =
    /^[a-zA-Z0-9!#$%&'*+/=.?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+/=.?^_`{|}~]+)*$/;

  if (localPart.length > 64) {
    return false;
  }

  return regex.test(localPart);
}

export default function SendEmailDialog({
  handleClose,
  open,
  audit,
  handleSubmit,
  emailContent,
  setEmailContent,
}) {
  return (
    <Dialog fullWidth={true} maxWidth={"lg"} sx={{ p: 1 }} open={open}>
      <DialogTitle>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          gap={5}
        >
          <MDTypography variant="h4">Send email</MDTypography>
          <MDBox display="flex" flexDirection="row" alignItems="center" gap={2}>
            <MDButton
              variant="gradient"
              color="info"
              endIcon={<Icon>send</Icon>}
              onClick={() => {
                handleSubmit(emailContent);
                //generateLink(true);
              }}
            >
              Send
            </MDButton>
            <MDBox
              sx={{ flex: "1" }}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <IconButton onClick={handleClose} sx={{ padding: "0px" }}>
                <Icon>close</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0 !important",
          p: 1,
          mt: -1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid
            item
            container
            xs={12}
            spacing={1}
            sx={{ alignItems: "center" }}
          >
            <Grid item>
              <TextField
                value={emailContent.from || ""}
                onChange={(e) => {
                  if (isValidEmailLocalPart(e.target.value))
                    setEmailContent({ ...emailContent, from: e.target.value });
                }}
                label="From"
                inputProps={{ maxLength: 64 }}
                sx={{
                  width: `${Math.max(
                    80,
                    Math.min(emailContent.from.length * 10, 50000)
                  )}px`,
                }}
              />
            </Grid>
            <Grid item sx={{ color: "black", opacity: 1 }}>
              @ifpc360.com
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={emailContent.to || ""}
              onChange={(e) => {
                setEmailContent({ ...emailContent, to: e.target.value });
              }}
              label="To (Enter multiple emails separated by commas)"
              fullWidth
              type="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={emailContent.cc || ""}
              onChange={(e) => {
                setEmailContent({ ...emailContent, cc: e.target.value });
              }}
              label={`CC (Enter multiple emails separated by commas)`}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={emailContent.subject || ""}
              onChange={(e) => {
                setEmailContent({ ...emailContent, subject: e.target.value });
              }}
              label="Subject"
              fullWidth
              type="text"
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 1 }}>
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.15)",
                padding: "8px",
                borderRadius: "8px",
              }}
            >
              <MDBox
                sx={{
                  all: "unset !important",
                  fontFamily: `sans-serif !important`,
                  fontSize: "16px !important",
                }}
              >
                Dear&nbsp;&nbsp;
                <TextField
                  variant="standard"
                  value={emailContent.toName || ""}
                  onChange={(e) => {
                    setEmailContent({
                      ...emailContent,
                      toName: e.target.value,
                    });
                  }}
                  type="text"
                />
                ,
                <br />
                <br />
                Hope all is well.
                <br />
                <br />
                Kindly click on the link below to access the 360° Assessment
                Report corresponding to{" "}
                <TextField
                  variant="standard"
                  value={emailContent.reportInfo || ""}
                  onChange={(e) => {
                    setEmailContent({
                      ...emailContent,
                      reportInfo: e.target.value,
                    });
                  }}
                  sx={{ width: "500px" }}
                  type="text"
                />
                :
                <br />
                <strong style={{ color: "red", fontSize: "24px" }}>→</strong>
                &nbsp;
                <a href="#">
                  https://ifpc-uploads.s3.eu-north-1.amazonaws.com/assessment_reports/ID-dwlrsce71Kerzb7yQBxTVQ%3D%3D_SG-u_AR.pdf{" "}
                </a>
                <br />
                <br />
                If you need any additional information, feel free to contact our
                account managers:
                <br />
                <br />
                Sabine Mouawad:{" "}
                <a href="mailto:sabine.mouawad@ifpc360.com">
                  sabine.mouawad@ifpc360.com
                </a>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+961
                71 529 846
                <br />
                Rita Karam:{" "}
                <a href="mailto:rita.karam@ifpc360.com">
                  rita.karam@ifpc360.com
                </a>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+961
                81 008 301
                <br /> <br />
                Best regards,
                <br />
                <br />
                <img
                  alt=""
                  style={{ width: "300px" }}
                  src={ifpcEmailSignature}
                />
                <br />
                <br />
                <TextField
                  variant="standard"
                  value={emailContent.fromName || ""}
                  onChange={(e) => {
                    setEmailContent({
                      ...emailContent,
                      fromName: e.target.value,
                    });
                  }}
                  fullWidth
                  type="text"
                />
                <br />
                <br />
                <strong>Mobile Number: </strong>+961 71 681 067
                <br />
                <strong>Website: </strong>
                <a href="https://ifpc-intl.com">www.ifpc-intl.com</a>
                <br />
                <strong>Lebanon</strong>, Matn, Jdeideh, Anwar Street, King
                Jesus Building, 5th floor
              </MDBox>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
