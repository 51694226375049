import HttpService from "./htttp.service";

class NotificationsServices {
  markNotificationAsRead = async (query, payload) => {
    const endpoint = "notifications/" + query;
    return await HttpService.put(endpoint, payload);
  };

  getNotifications = async (query, payload) => {
    const endpoint = "notifications";
    return await HttpService.get(endpoint, payload);
  };
}

export default new NotificationsServices();
