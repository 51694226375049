import { DEVELOPMENT } from "../config";
import HttpService from "./htttp.service";

class AccountsService {
  getAccounts = async (payload, cancelToken) => {
    if (!DEVELOPMENT) return [{ ok: true }, null];
    const endpoint = "accounts";

    return await HttpService.get(endpoint, payload, cancelToken);
  };

  getAccountTypes = async (payload, cancelToken) => {
    const endpoint = "accounts/types";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  getAccount = async (id, query, cancelToken) => {
    const endpoint = "accounts/" + id;
    return await HttpService.get(endpoint, query, cancelToken);
  };

  createAccount = async (payload) => {
    const endpoint = "accounts";
    const { logo, ...account } = payload;
    if (!logo || typeof logo == "string") {
      return await HttpService.uploadFilesWithData(
        endpoint,
        [],
        payload,
        "post"
      );
    }

    return await HttpService.uploadFilesWithData(
      endpoint,
      [logo],
      account,
      "post"
    );
  };

  updateAccount = async (id, payload) => {
    const endpoint = "accounts/" + id;
    const { logo, ...account } = payload;
    if (!logo || typeof logo == "string") {
      return await HttpService.uploadFilesWithData(
        endpoint,
        [],
        payload,
        "put"
      );
    }
    return await HttpService.uploadFilesWithData(
      endpoint,
      [logo],
      account,
      "put"
    );
  };

  subscribe = async (id, payload) => {
    const endpoint = "accounts/" + id + "/subscribe/" + payload.subscriptionId;
    return await HttpService.post(endpoint, payload, null);
  };

  unsubscribe = async (id, payload) => {
    const endpoint =
      "accounts/" + id + "/unsubscribe/" + payload.subscriptionId;
    return await HttpService.post(endpoint, payload, null);
  };

  removeAccount = async (query, payload) => {
    const endpoint = "accounts/" + query;
    return await HttpService.delete(endpoint, payload);
  };
}

export default new AccountsService();
