import { TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export default function ResizableTextarea({ onChange, value, ...props }) {
  const textareaRef = useRef(null);
  const [originalBoundingRect, setOriginalBoundingRect] = useState(0);

  useEffect(() => {
    return;
    const textarea = textareaRef.current;

    const currentHeight = textarea.parentElement.getBoundingClientRect().height;
    setOriginalBoundingRect(currentHeight);

    textarea.style.height = `${
      textarea.scrollHeight < currentHeight
        ? currentHeight
        : textarea.scrollHeight
    }px`;
  }, [value]);

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
    return;
    const textarea = textareaRef.current;
    textarea.style.height = props?.style?.height || "auto";

    textarea.style.height = `${
      textarea.scrollHeight < originalBoundingRect
        ? originalBoundingRect
        : textarea.scrollHeight
    }px`;
  };

  return (
    <TextField
      {...props}
      multiline
      minRows={6}
      onChange={handleChange}
      value={value}
      ref={textareaRef}
    />
  );
}
