import {
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import Autocomplete, {
  createFilterOptions,
  autocompleteClasses,
} from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import "./index.css";

export default function DropdownSelect({
  items,
  initialValue,
  onChange,
  label,
  required,
  multiple,
  hasAll,
  renderOption,
  loading,
  ...rest
}) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(multiple ? [] : "");
  const [firstRender, setFirstRender] = useState(true);

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      (rest.groupBy ? rest.groupBy(option) + " " : "") + option.label,
  });

  useEffect(() => {
    if (!items) setOptions([]);
    else {
      setOptions(
        hasAll && multiple ? [{ id: -1, label: "All" }, ...items] : items
      );
    }
  }, [items]);

  useEffect(() => {
    //if (!firstRender) return;

    setFirstRender(false);
    if (items) {
      if (multiple) {
        setValue(items.filter((i) => initialValue.includes(i.id)));
      } else {
        setValue(items.find((i) => i.id == initialValue));
      }
    } else {
      setValue(null);
    }
  }, [initialValue, items]);

  return (
    <Autocomplete
      size="medium"
      value={value}
      onChange={(event, newValue) => {
        if (multiple) {
          const allPresentNow = newValue.findIndex((v) => v.id === -1) !== -1;
          const allPresentBefore = value.findIndex((v) => v.id === -1) !== -1;
          if (allPresentNow && !allPresentBefore) {
            setValue(options);
            onChange && onChange(options);
            return;
          } else if (allPresentNow && allPresentBefore) {
            const filteredOptions = newValue.filter((v) => v.id !== -1);
            setValue(filteredOptions);
            onChange && onChange(filteredOptions);
            return;
          } else if (!allPresentNow && allPresentBefore) {
            setValue([]);
            onChange && onChange([]);
            return;
          }
        }

        setValue(newValue);
        onChange && onChange(newValue);
      }}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option?.label || "";
      }}
      getOptionKey={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option?.id;
      }}
      isOptionEqualToValue={(option, value) => {
        return option?.id == value || option == value;
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <MenuItem component="li" key={key} {...optionProps}>
            {renderOption ? renderOption(option) : option?.label}
          </MenuItem>
        );
      }}
      freeSolo
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              label={label}
              required={required}
              InputProps={{
                ...params.InputProps,
              }}
              FormHelperTextProps={{
                sx: { fontWeight: "400" }, // Change font weight here
              }}
              {...rest?.inputProps}
            />
            {loading && (
              <CircularProgress
                sx={{ position: "absolute", right: 13, top: 13 }}
                size={20}
                color="info"
                thickness={4}
              />
            )}
          </>
        );
      }}
      readOnly={loading || rest?.readOnly}
      renderTags={(value, getTagProps) => {
        const allIndex = value.findIndex((v) => v.id === -1);
        return (
          <Grid container sx={{ padding: 1 }}>
            {allIndex !== -1 ? (
              <Grid item key={getTagProps({ allIndex }).key}>
                <Chip
                  size="small"
                  variant="outlined"
                  label={"All"}
                  {...getTagProps({ allIndex })}
                />
              </Grid>
            ) : (
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Grid key={key} item>
                    <Chip
                      variant="outlined"
                      label={option.label}
                      {...tagProps}
                    />
                  </Grid>
                );
              })
            )}
          </Grid>
        );
      }}
      multiple={multiple}
      sx={{ position: "relative", ...rest?.sx }}
      {...rest}
    />
  );
}
