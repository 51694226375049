import { Icon, IconButton } from "@mui/material";
import BasicPopover from "components/BasicPopover";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";

export default function InfoPanel({ children, color, size, ...props }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        size={size}
        onClick={(e) => {
          setOpen(e.currentTarget);
        }}
      >
        <Icon size={size} sx={{ color }}>
          info
        </Icon>
      </IconButton>
      <BasicPopover
        open={open}
        anchorEl={open}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        content={
          <MDBox sx={{ p: 0, m: -1, maxWidth: "200px" }}>
            <MDTypography variant="h6" sx={{ mt: -0.4 }}>
              More info
            </MDTypography>
            {children}
          </MDBox>
        }
      />
    </>
  );
}
