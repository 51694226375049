import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import PageContainer from "components/PageContainer";
import Footer from "examples/Footer";

import { useEffect, useContext, useState } from "react";
import { useAuth } from "contexts/auth.context";
import accessDeniedImage from "assets/images/access-denied-1.png";

function AccessDenied({ type, route }) {
  const navigate = useNavigate();

  if (type == "component") {
    return (
      <MDBox pt={6} pb={3}>
        <MDBox
          mx={2}
          mt={-3}
          /*    borderRadius="lg"
          coloredShadow="info"*/
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <img
            style={{ maxWidth: "300px", width: "100%" }}
            src={accessDeniedImage}
            loading="lazy"
          />
          <MDTypography
            variant="body"
            sx={{ textAlign: "center", fontSize: 16 }}
          >
            <p>This part is restricted and requires higher permissions.</p>
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  }

  if (type == "half-page") {
    return (
      <MDBox pt={6} pb={3}>
        <MDBox
          mx={2}
          mt={-3}
          /*    borderRadius="lg"
                  coloredShadow="info"*/
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <img
            style={{ maxWidth: "300px", width: "100%" }}
            src={accessDeniedImage}
            loading="lazy"
          />
          <MDTypography variant="body" sx={{ textAlign: "center" }}>
            <p>
              You are attempting to access a page with restricted permissions.
            </p>
            <p>Kindly consult the system administrator for assistance.</p>
          </MDTypography>
          <br />
          <br />
          <MDButton
            onClick={() => navigate(-1)}
            variant="gradient"
            color="info"
          >
            <Icon sx={{ padding: 0 }}>arrow_back</Icon>
            &nbsp;Back
          </MDButton>
        </MDBox>
      </MDBox>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar customRoute={route} />

      <PageContainer>
        <MDBox pt={6} pb={3}>
          <MDBox
            mx={2}
            mt={-3}
            /*    borderRadius="lg"
                coloredShadow="info"*/
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <img
              style={{ maxWidth: "300px", width: "100%" }}
              src={accessDeniedImage}
              loading="lazy"
            />
            <MDTypography variant="body" sx={{ textAlign: "center" }}>
              <p>
                You are attempting to access a page with restricted permissions.
              </p>
              <p>Kindly consult the system administrator for assistance.</p>
            </MDTypography>
            <br />
            <br />
            <MDButton
              onClick={() => navigate(-1)}
              variant="gradient"
              color="info"
            >
              <Icon sx={{ padding: 0 }}>arrow_back</Icon>
              &nbsp;Back
            </MDButton>
          </MDBox>
        </MDBox>
      </PageContainer>

      <Footer />
    </DashboardLayout>
  );
}

export default AccessDenied;
