import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Icon,
  Collapse,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Divider,
  ButtonGroup,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
  SvgIcon,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "components/LoadingButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { NestedDropdown } from "mui-nested-menu";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import auditsService from "services/audits-service";
import branchesService from "services/branches-service";
import ConsolidatedReportDialog from "./ConsolidatedReportDialog";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import IndeterminateIcon from "components/IndeterminateIcon";

export default function ConsolidatedCreationReportDialog({
  open,
  setOpen,
  branchGroupId,
}) {
  const [selected, setSelected] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [consolidatedReportDialogOpen, setConsolidatedReportDialogOpen] =
    useState(false);
  const [consolidatedReportType, setConsolidatedReportType] = useState(null);
  const [branchGroup, setBranchGroup] = useState(null);
  const [loading, setLoading] = useState({ fetchAudits: true });
  const [isOpen, setIsOpen] = useState({ generate: false });

  const generateButtonAnchorElement = useRef();

  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = "hidden";
      setCollapsed(false);
      fetchAudits();
    } else {
      document.documentElement.style.overflow = "scroll";
      setCollapsed(false);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchAudits = async () => {
    setLoading((curr) => ({ ...curr, fetchAudits: true }));
    const [response, data] = await branchesService.getBranches({
      branchGroupId: branchGroupId,
      filterAuditsByGeneratedReports: true,
      includeColorPalette: true,
    });

    if (response.ok) {
      const _branchGroup = data[0];
      setBranchGroup(data[0]);
      setSelected(() => {
        return _branchGroup.branches.map((branch) => {
          return [];
        });
      });

      setCollapsed(true);
    }

    setLoading((curr) => ({ ...curr, fetchAudits: false }));
  };

  const getBranches = useCallback(() => {
    return branchGroup.branches
      .map((branch, index) => {
        if (selected[index]?.length > 0) {
          const audits = branch.audits.filter((audit) =>
            selected[index].includes(audit.id)
          );
          return { ...branch, audits };
        } else {
          return { ...branch, audits: [] };
        }
      })
      .filter((branch) => branch.audits.length > 0);
  }, [selected, branchGroup]);

  const regions = useMemo(() => {
    if (!branchGroup?.branches) return [];
    const allRegions = {};
    branchGroup.branches.map((branch) => {
      allRegions[branch.region.id] = branch.region;
    });
    return Object.values(allRegions);
  }, [branchGroup]);

  const branchesByRegion = useMemo(() => {
    const branches = {};
    branchGroup?.branches?.map((branch) => {
      if (!branches[branch.region.id]) branches[branch.region.id] = [];
      branches[branch.region.id].push(branch);
    });
    return branches;
  }, [branchGroup]);

  return (
    <>
      {consolidatedReportDialogOpen && (
        <ConsolidatedReportDialog
          isOpen={consolidatedReportDialogOpen}
          branches={getBranches()}
          selected={branchGroup.branches.map((branch, index) => ({
            id: branch.id,
            selected: selected[index] || [],
          }))}
          branchGroup={branchGroup}
          type={consolidatedReportType}
          setIsOpen={setConsolidatedReportDialogOpen}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <MDTypography variant="h5">
              Generate Consolidated Report
            </MDTypography>
            <MDBox display="flex" alignItems="center" gap={1}>
              <NestedDropdown
                ButtonProps={{
                  sx: {
                    backgroundColor: "info.main",
                    color: "white !important",

                    "&:hover": {
                      backgroundColor: "info.main",
                      color: "white !important",
                      opacity: 0.5,
                    },
                  },

                  disabled:
                    loading.fetchAudits ||
                    selected?.filter((element) => element?.length > 0)
                      .length === 0,
                }}
                MenuProps={{
                  sx: {
                    "& .MuiBox-root": {
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                      },
                    },
                  },
                }}
                menuItemsData={{
                  label: "Generate",
                  items: [
                    {
                      label: "Generate All",
                      callback: () => {
                        setConsolidatedReportType({ value: "normal" });
                        setConsolidatedReportDialogOpen(Math.random());
                      },
                      sx: {
                        mx: -1,
                      },
                    },

                    {
                      label: "Generate By Region",
                      items: regions?.map((region) => {
                        /*const active =
                          selected.findIndex(
                            (s, index) =>
                              s.length === 0 &&
                              branchGroup.branches[index].region.id ===
                                region.id
                          ) === -1;*/

                        const active =
                          (selected.findIndex(
                            (s, index) =>
                              s.length === 0 &&
                              branchGroup.branches[index].region.id ===
                                region.id
                          ) !== -1 &&
                            selected.findIndex(
                              (s, index) =>
                                s.length !== 0 &&
                                branchGroup.branches[index].region.id ===
                                  region.id
                            ) !== -1) ||
                          selected.findIndex(
                            (s, index) =>
                              s.length === 0 &&
                              branchGroup.branches[index].region.id ===
                                region.id
                          ) === -1;

                        return {
                          label: region.description,
                          callback: () => {
                            setConsolidatedReportType({
                              value: "region",
                              region,
                            });
                            setConsolidatedReportDialogOpen(Math.random());
                          },
                          sx: {
                            "& .MuiBox-root": {
                              "& .MuiTypography-root": {
                                fontSize: "14px",
                              },
                            },
                            mx: -1,
                          },

                          disabled: !active,
                        };
                      }),
                      sx: {
                        mx: -1,
                      },
                    },
                    {
                      label: "Generate Comprehensive (for all regions)",
                      callback: () => {
                        setConsolidatedReportType({
                          value: "comprehensive",
                          regions,
                        });
                        setConsolidatedReportDialogOpen(Math.random());
                      },
                      disabled: !regions.reduce((prev, curr) => {
                        const active =
                          (selected.findIndex(
                            (s, index) =>
                              s.length === 0 &&
                              branchGroup.branches[index].region.id === curr.id
                          ) !== -1 &&
                            selected.findIndex(
                              (s, index) =>
                                s.length !== 0 &&
                                branchGroup.branches[index].region.id ===
                                  curr.id
                            ) !== -1) ||
                          selected.findIndex(
                            (s, index) =>
                              s.length === 0 &&
                              branchGroup.branches[index].region.id === curr.id
                          ) === -1;

                        return prev && active;
                      }, true),
                      sx: {
                        mx: -1,
                      },
                    },
                  ],
                }}
              />

              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </MDBox>
          </MDBox>
        </DialogTitle>
        <DialogContent>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Grid container>
              {/*<Grid container item xs={12}>
                <Grid container item xs>
                  <Grid item xs={12}>
                    <MDTypography variant="h6">
                      Consolidated Report
                      <MDTypography variant="caption">
                        {` (${
                          selected?.filter((element) => element?.length > 0)
                            .length
                        } branches selected)`}
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid item style={{ padding: "0 4px" }}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setCollapsed((prev) => !prev);
                    }}
                  >
                    {!loading.fetchAudits && (
                      <Icon>
                        {collapsed
                          ? "keyboard_arrow_up"
                          : "keyboard_arrow_down"}
                      </Icon>
                    )}
                  </IconButton>
                </Grid>
                <Grid item>
                  <NestedDropdown
                    ButtonProps={{
                      sx: {
                        backgroundColor: "info.main",
                        color: "white !important",

                        "&:hover": {
                          backgroundColor: "info.main",
                          color: "white !important",
                          opacity: 0.5,
                        },
                      },

                      disabled:
                        loading.fetchAudits ||
                        selected?.filter((element) => element?.length > 0)
                          .length === 0,
                    }}
                    MenuProps={{
                      sx: {
                        "& .MuiBox-root": {
                          "& .MuiTypography-root": {
                            fontSize: "16px",
                          },
                        },
                      },
                    }}
                    menuItemsData={{
                      label: "Generate",
                      items: [
                        {
                          label: "Generate Normal",
                          callback: () => {
                            setConsolidatedReportType({ value: "normal" });
                            setConsolidatedReportDialogOpen(Math.random());
                          },
                        },
                        {
                          label: "Generate By Region",
                          items: regions?.map((region) => ({
                            label: region.description,
                            callback: () => {
                              setConsolidatedReportType({
                                value: "region",
                                region,
                              });
                              setConsolidatedReportDialogOpen(Math.random());
                            },
                            sx: {
                              "& .MuiBox-root": {
                                "& .MuiTypography-root": {
                                  fontSize: "16px",
                                },
                              },
                            },
                          })),
                        },
                        {
                          label: "Generate Comprehensive (for all regions)",
                          callback: () => {
                            setConsolidatedReportType({
                              value: "comprehensive",
                              regions,
                            });
                            setConsolidatedReportDialogOpen(Math.random());
                          },
                        },
                      ],
                    }}
                  />
                  <Popper
                    anchorEl={generateButtonAnchorElement.current}
                    open={isOpen.generate}
                    style={{ zIndex: 1500 }}
                    transition
                    placement="bottom-end"
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow {...TransitionProps}>
                        <Paper>
                          <ClickAwayListener
                            onClickAway={() => {
                              setIsOpen((curr) => ({
                                ...curr,
                                generate: false,
                              }));
                            }}
                          >
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  setConsolidatedReportDialogOpen(
                                    Math.random()
                                  );
                                  setIsOpen((curr) => ({
                                    ...curr,
                                    generate: false,
                                  }));
                                }}
                              >
                                Generate Normal
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setConsolidatedReportDialogOpen(
                                    Math.random()
                                  );
                                  setIsOpen((curr) => ({
                                    ...curr,
                                    generate: false,
                                  }));
                                }}
                              >
                                Generate By Region&nbsp;
                                <Icon>arrow_drop_down</Icon>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setConsolidatedReportDialogOpen(
                                    Math.random()
                                  );
                                  setIsOpen((curr) => ({
                                    ...curr,
                                    generate: false,
                                  }));
                                }}
                              >
                                Generate Comprehensive
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              </Grid>*/}
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Collapse
                    style={{ width: "100%" }}
                    in={open && !loading.fetchAudits}
                  >
                    <FormControlLabel
                      label={`Select all (${
                        selected?.filter((element) => element?.length > 0)
                          .length
                      }/${selected.length})`}
                      control={
                        <Checkbox
                          checked={
                            selected.findIndex(
                              (s, index) =>
                                s.length !==
                                branchGroup?.branches[index]?.audits?.length
                            ) === -1
                          }
                          indeterminate={
                            selected.findIndex(
                              (s, index) =>
                                s.length ===
                                branchGroup?.branches[index]?.audits?.length
                            ) !== -1 &&
                            selected.findIndex(
                              (s, index) =>
                                s.length !==
                                branchGroup?.branches[index]?.audits?.length
                            ) !== -1
                          }
                          indeterminateIcon={<IndeterminateIcon />}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            if (checked) {
                              setSelected((curr) => {
                                const newSelected = [...curr];
                                branchGroup?.branches?.map((branch, index) => {
                                  newSelected[index] = branch?.audits?.map(
                                    (audit) => audit.id
                                  );
                                });

                                return newSelected;
                              });
                            } else {
                              setSelected((curr) => {
                                const newSelected = [...curr];
                                branchGroup?.branches?.map((_, index) => {
                                  newSelected[index] = [];
                                });
                                return newSelected;
                              });
                            }
                          }}
                        />
                      }
                    />
                    {regions.map((region) => {
                      return (
                        <FormControlLabel
                          label={`Select all from ${region.description.trim()} (${
                            selected.filter((value, index) => {
                              return (
                                value.length > 0 &&
                                branchGroup.branches[index].region.id ==
                                  region.id
                              );
                            }).length
                          }/${branchesByRegion[region.id].length})`}
                          control={
                            <Checkbox
                              checked={
                                selected.findIndex(
                                  (s, index) =>
                                    s.length === 0 &&
                                    branchGroup.branches[index].region.id ===
                                      region.id
                                ) === -1
                              }
                              indeterminate={
                                selected.findIndex(
                                  (s, index) =>
                                    s.length === 0 &&
                                    branchGroup.branches[index].region.id ===
                                      region.id
                                ) !== -1 &&
                                selected.findIndex(
                                  (s, index) =>
                                    s.length !== 0 &&
                                    branchGroup.branches[index].region.id ===
                                      region.id
                                ) !== -1
                              }
                              indeterminateIcon={<IndeterminateIcon />}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                if (checked) {
                                  setSelected((curr) => {
                                    const newSelected = [...curr];
                                    branchGroup?.branches?.map(
                                      (branch, index) => {
                                        if (branch.region.id === region.id)
                                          newSelected[index] =
                                            branch?.audits?.map(
                                              (audit) => audit.id
                                            );
                                        else {
                                        }
                                      }
                                    );

                                    return newSelected;
                                  });
                                } else {
                                  setSelected((curr) => {
                                    const newSelected = [...curr];
                                    branchGroup?.branches?.map(
                                      (branch, index) => {
                                        if (branch.region.id === region.id)
                                          newSelected[index] = [];
                                      }
                                    );
                                    return newSelected;
                                  });
                                }
                              }}
                            />
                          }
                        />
                      );
                    })}
                    <Divider />
                    {branchGroup?.branches.map((branch, index) => {
                      return (
                        <ListItem
                          branch={branch}
                          selected={selected}
                          branchIndex={index}
                          setSelected={setSelected}
                        />
                      );
                    })}
                  </Collapse>
                </Grid>

                {loading.fetchAudits && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      width: "100%",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      sx={{ margin: "0 auto" }}
                      color="info"
                      size={30}
                      thickness={6}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
      </Dialog>
    </>
  );
}

function ListItem({ branch, selected, branchIndex, setSelected }) {
  return (
    <MDBox sx={{ width: "100%" }}>
      <FormControlLabel
        label={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>{branch?.name}</span>
            <MDBadge badgeContent={branch?.region.description} />
          </div>
        }
        sx={{
          width: "100%",
          ".MuiFormControlLabel-label": {
            width: "calc(100% - 45px)",
          },
        }}
        control={
          <Checkbox
            checked={selected[branchIndex]?.length == branch?.audits?.length}
            indeterminate={
              selected[branchIndex]?.length !== 0 &&
              selected[branchIndex]?.length !== branch?.audits?.length
            }
            indeterminateIcon={<IndeterminateIcon />}
            onChange={(e) => {
              const checked = e.target.checked;
              if (checked) {
                setSelected((curr) => {
                  const newSelected = [...curr];
                  newSelected[branchIndex] = branch?.audits?.map(
                    (audit) => audit.id
                  );
                  return newSelected;
                });
              } else {
                setSelected((curr) => {
                  const newSelected = [...curr];
                  newSelected[branchIndex] = [];
                  return newSelected;
                });
              }
            }}
          />
        }
      />
      <MDBox sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        {branch?.audits?.map((audit) => {
          return (
            <FormControlLabel
              sx={{
                marginTop: 0,
                marginBottom: 0,
              }}
              label={audit?.description}
              control={
                <Checkbox
                  checked={selected[branchIndex]?.includes(audit.id)}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      setSelected((curr) => {
                        const newSelected = [...curr];
                        const index = newSelected[branchIndex].findIndex(
                          (value) => value == audit.id
                        );

                        if (index == -1) {
                          newSelected[branchIndex].push(audit.id);
                        }

                        return newSelected;
                      });
                    } else {
                      setSelected((curr) => {
                        const newSelected = [...curr];
                        const index = newSelected[branchIndex].findIndex(
                          (value) => value == audit.id
                        );

                        newSelected[branchIndex].splice(index, 1);
                        return newSelected;
                      });
                    }
                  }}
                />
              }
            />
          );
        })}
      </MDBox>
      <Divider />
    </MDBox>
  );
}
