import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "components/LoadingButton";
import MDSelect from "components/MDSelect";
import React, { useEffect, useMemo, useRef } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import downloadPdf from "./GenerateReportv3";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled, useTheme } from "@mui/material/styles";

import {
  DEV_ANSWER_TYPE_ID,
  NA_ANSWER_TYPE_ID,
  STD_ANSWER_TYPE_ID,
  SUBMITTED_STATUS_ID,
} from "../../../../const";

import {
  devPillar,
  flagColor,
  flagColorBasedOnString,
  formatDateTimeFromISOString,
  percentage,
  percentageScore,
  perfs,
  transformToTime,
} from "./utils";

//import confidentialSticker from "assets/images/confidential.svg";
import { Icon } from "@mui/material";
import confidentialSticker from "assets/images/confidential.png";
import ifpcSticker from "assets/images/ifpc-cover.jpg";
import MDBadge from "components/MDBadge";
import auditsService from "services/audits-service";
import SendEmailDialog from "./dialogs/SendEmailDialog.v2";
import styles from "./index-assessment.module.css";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CustomizedMenus = ({
  audit,
  loading,
  setLoading,
  includeTime,
  contact,
  summaryGroups,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [emailAnchorEl, setEmailAnchorEl] = React.useState(null);
  const [emailContent, setEmailContent] = React.useState({
    subject: "",
    from: "info",
    to: "",
    text: "",
    toName: "Mr. ",
    reportInfo: "",
    fromName: "QMS Department",
    fromFunction: "Senior Quality Management System Coordinator",
  });
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setEmailAnchorEl(null);
  };

  const generateLink = async (sendAsEmail) => {
    if (!audit?.id) {
      return;
    }

    setLoading(true);
    handleClose();
    try {
      const sentSummaryGroups = Object.keys(summaryGroups)
        .filter((key) => summaryGroups[key])
        .map((key) => Number(key) || "" + key);

      const [response, data] = await auditsService.generateAssessmentReportLink(
        audit.id,
        sendAsEmail
          ? {
              includeTime: includeTime ? "include" : "",
              contact: contact,
              to: emailContent.to,
            }
          : {
              includeTime: includeTime ? "include" : "",
              contact: contact,
            },
        sendAsEmail
          ? { ...emailContent, summaryGroups: sentSummaryGroups }
          : { summaryGroups: sentSummaryGroups }
      );

      if (response.ok) {
        await downloadPDF(
          data.url,
          `${audit.branch.branchGroup.name}-${
            audit.branch.name
          }-ifpc360-${new Date(audit.date).toLocaleDateString("en-US", {
            month: "2-digit",
            year: "numeric",
          })}.pdf`
        );
        setLoading(false);
        return data;
      }
    } catch (err) {
      console.log("Error occured when generating pdf link: ", err);
    }

    setLoading(false);
  };

  async function downloadPDF(url, filename) {
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }

  const handleGenerate = async () => {
    handleClose();
    setLoading(true);
    await downloadPdf(`${audit.description}`);
    setLoading(false);
  };

  useEffect(() => {
    if (!audit) return;
    setEmailContent({
      ...emailContent,

      to: audit.branch.email || audit.branch.branchGroup.email,
      subject: `IFPC x ${audit.branch.branchGroup.name} | ${
        audit.branch.name
      } | 360° Assessment Report | ${
        months[new Date(audit.date).getMonth()]
      } ${new Date(audit.date).getFullYear()}`,
      reportInfo: `${audit.branch.branchGroup.name}, ${
        audit.branch.name
      }, for ${months[new Date(audit.date).getMonth()]} ${new Date(
        audit.date
      ).getFullYear()}`,
    });
  }, [audit]);

  return (
    <div>
      <LoadingButton
        loading={loading}
        type="button"
        variant="gradient"
        color="info"
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </LoadingButton>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          sx={{
            p: 1,
            px: 2,
            m: 0,
            mx: -1,
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}
          onClick={handleGenerate}
        >
          <Icon>file_download</Icon>
          Download as PDF
        </MenuItem>
        <MenuItem
          sx={{
            p: 1,
            px: 2,
            m: 0,
            mx: -1,
            display: "flex",
            flexDirection: "row",
          }}
          onClick={() => {
            generateLink(false);
          }}
        >
          <Icon sx={{ mr: 1 }}>file_download</Icon>
          Download as PDF
          <MDBadge badgeContent="BETA" color="primary" />
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            setEmailAnchorEl(Math.random());
          }}
          sx={{
            p: 1,
            px: 2,
            m: 0,
            mx: -1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Icon sx={{ mr: 1 }}>send</Icon>
          Download as PDF and share by email
          <MDBadge badgeContent="BETA" color="primary" />
        </MenuItem>
      </Menu>

      {Boolean(emailAnchorEl) && (
        <SendEmailDialog
          open={Boolean(emailAnchorEl)}
          handleClose={handleClose}
          emailContent={emailContent || {}}
          setEmailContent={setEmailContent}
          handleSubmit={() => {
            generateLink(true);
          }}
        />
      )}
    </div>
  );
};

export default function ReportDialog({
  isOpen,
  audit,
  summaryGroups,
  contacts,
  handleEditAuditInfo,
  fetchSiblingBranches,
}) {
  const theme = useTheme();

  const [contactId, setContactId] = React.useState("");
  const [timeRecord, setTimeRecord] = React.useState("");
  const [firstAnswerTime, setFirstAnswerTime] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [reportData, setReportData] = React.useState(null);

  async function fetch() {
    if (!audit?.id) return;
    const [ok, data] = await auditsService.generateReport(audit.id);
    if (ok) {
      setReportData(data);
      fetchSiblingBranches();
    }
  }

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);
    setTimeRecord("");

    fetch();
  }, [isOpen]);

  React.useEffect(() => {
    if (audit) {
      if (audit.contactId == null) {
        setContactId("");
      } else {
        setContactId(audit.contactId);
      }
    }
  }, [audit]);

  const handleClose = () => {
    setOpen(false);
  };

  const contactName = useMemo(() => {
    if (contactId === "" || !contacts) return "";
    const contactSelected = contacts?.filter(
      (contactObj) => contactObj.id === contactId
    );
    if (contactSelected.length) {
      return contactSelected[0].name;
    }
    return "";
  }, [contactId, contacts]);

  const pdfRef = useRef(null); // the item's index that is currently being dragged
  const draggedOverItem = useRef(0); // the item's index that we are currently dragging something else over it
  let scrollInterval;

  function getDragAfterElement(container, y) {
    const draggableElements = [
      ...container.querySelectorAll(".draggable-item:not(.dragging)"),
    ];
    return draggableElements.reduce(
      (closest, child) => {
        const box = child.getBoundingClientRect();
        const offset = y - box.top - box.height / 2;
        if (offset < 0 && offset > closest.offset) {
          return { offset: offset, element: child };
        } else {
          return closest;
        }
      },
      { offset: Number.NEGATIVE_INFINITY }
    ).element;
  }

  const { DashboardTable, reportScore } = useMemo(() => {
    const { score, dashboardTableHtml } = DashboardTableConstruct(
      audit?.pillars
    );
    return { DashboardTable: dashboardTableHtml, reportScore: score };
  }, [audit]);

  const ActionPlanTable = useMemo(() => {
    return ActionPlanTableConstruct(audit?.pillars, audit?.deviationOfBranch);
  }, [audit]);

  const CheckListTable = useMemo(() => {
    return CheckListTableConstruct(audit?.pillars);
  }, [audit]);

  const ReportedPhotos = useMemo(() => {
    return ReportedPhotosContruct(audit?.pillars);
  }, [audit]);

  useEffect(() => {
    if (audit?.branch?.colorPalette) {
      const {
        color1,
        color2,
        color3,
        fontColor1,
        fontColor2,
        fontColor3,
        ...rest
      } = audit?.branch?.colorPalette;
      document.documentElement.style.setProperty("--var-color-1", color1);
      document.documentElement.style.setProperty("--var-color-2", color2);
      document.documentElement.style.setProperty("--var-color-3", color3);

      document.documentElement.style.setProperty("--var-font-1", fontColor1);
      document.documentElement.style.setProperty("--var-font-2", fontColor2);
      document.documentElement.style.setProperty("--var-font-3", fontColor3);
    }
    // Add more variable changes as needed
  }, [audit]);

  const handleTimeCheckbox = (event) => {
    const checked = event.target.checked;
    if (checked) {
      const index = audit?.auditLogStatuses?.findIndex(
        (item) => item.statusId === SUBMITTED_STATUS_ID
      );

      if (index !== -1) {
        const time = audit.auditLogStatuses[index].time;
        const timeString = transformToTime(time);
        setTimeRecord(timeString);
        let i = 0;

        setFirstAnswerTime(audit.firstAnsweredAt || "--/--/----");

        /*let minTime = null;
        for (i = 0; i < audit?.auditKpis?.length; i++) {
          const currentAuditKpiAnswer = audit.auditKpis[i].kpiValueTypeAnswer;

          if (currentAuditKpiAnswer) {
            if (minTime === null) {
              minTime = currentAuditKpiAnswer.createdAt;
            } else {
              minTime = findSmallestDate(
                minTime,
                currentAuditKpiAnswer.createdAt
              );
            }
          }
        }
        if (minTime) {
          setFirstAnswerTime(minTime);
        }*/
      } else {
        setTimeRecord("");
      }
    } else {
      setTimeRecord("");
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
      <DialogTitle>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={5}
        >
          <MDTypography variant="h4">Assessment Report</MDTypography>
          <MDBox
            sx={{ flex: "1" }}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
          >
            <MDBox display="flex" alignItems="center">
              <Checkbox
                checked={!(timeRecord === "")}
                name="agree"
                id="agree"
                onChange={handleTimeCheckbox}
              />
              <InputLabel
                variant="standard"
                fontWeight="regular"
                color="text"
                sx={{ lineHeight: "1.5", cursor: "pointer" }}
                htmlFor="agree"
              >
                Include time&nbsp;
              </InputLabel>
            </MDBox>
            <MDSelect
              noneOption={true}
              label={"On Duty Contact"}
              value={contactId}
              onChange={(newValue) => {
                setContactId(newValue);
                let onDutyContact = null;
                if (newValue !== "") {
                  onDutyContact = newValue;
                }

                handleEditAuditInfo({
                  ...audit,
                  contactId: onDutyContact,
                });
              }}
              items={
                contacts
                  ? contacts?.map((user) => {
                      return { value: user.id, render: user.name };
                    })
                  : []
              }
              fullWidth
            />
            <CustomizedMenus
              summaryGroups={summaryGroups}
              audit={audit}
              loading={loading}
              setLoading={setLoading}
              contact={contactName}
              includeTime={timeRecord != ""}
            />
            <IconButton
              onClick={handleClose}
              disabled={loading}
              sx={{ padding: "0px" }}
            >
              <CloseIcon />
            </IconButton>
          </MDBox>
        </MDBox>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0 !important",
          backgroundColor: "whitesmoke !important",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className={styles["pdf-container"]}
          ref={pdfRef}
          onDragOver={(e) => {
            e.preventDefault();
            const afterElement = getDragAfterElement(pdfRef.current, e.clientY);
            const draggable = document.querySelector(".dragging");
            if (afterElement == null) {
              pdfRef.current.appendChild(draggable);
            } else {
              pdfRef.current.insertBefore(draggable, afterElement);
            }
          }}
        >
          {/* cover */}
          <div
            className={`${styles["pdf-item-container"]} printable`}
            id="cover"
          >
            <div className={styles["pdf-item"]}>
              <div className={styles["report-cover"]}>
                <Avatar
                  className={styles["report-cover-logo"]}
                  variant="rounded"
                  src={audit?.branch?.logo} // `${UPLOAD_URL}/${audit?.branch?.logo}`
                ></Avatar>
                <div className={styles["cover-info-container-box"]}>
                  <div className={styles["cover-info-container"]}>
                    <div>
                      <span className={styles["assessment"]}>
                        360<span>&deg;</span>
                      </span>
                    </div>
                    <div>
                      <span
                        style={
                          contactId === "" && timeRecord === ""
                            ? { fontSize: "1.5rem" }
                            : {}
                        }
                      >
                        {audit?.branch?.name}
                      </span>
                      {contactId !== "" ? (
                        <span
                          style={{ display: "block" }}
                        >{`On Duty Contact : ${contactName}`}</span>
                      ) : null}
                      {timeRecord !== "" ? (
                        <>
                          {/*<span
                            style={{ display: "block" }}
                      >{`Time taken : ${timeRecord}`}</span>*/}
                          <span
                            style={{ display: "block" }}
                          >{`Audit Time : ${formatDateTimeFromISOString(
                            firstAnswerTime
                          )}`}</span>
                        </>
                      ) : null}
                    </div>
                  </div>
                  {audit.auditTypeId != 1 && (
                    <div className={styles["cover-info-rating"]}>
                      <span>
                        <span>Rating</span>
                        {isNaN(reportScore) ? null : (
                          <img
                            className={`${styles.flag} ${styles.bx} ${styles["bxs-flag"]}}`}
                            src={flagColor(reportScore)}
                            alt="flag-score"
                          />
                        )}
                      </span>
                      <span>
                        {isNaN(reportScore) ? "-" : reportScore + "%"}
                      </span>
                    </div>
                  )}
                </div>
                <div className={styles["cover-confidental"]}>
                  <img src={ifpcSticker} alt="ifpc" />
                  <img src={confidentialSticker} alt="confidential" />
                </div>
                <div className={styles["cover-footer"]}>
                  <span>
                    360&deg; Assessment Report all rights reserved IFPC S.A.R.L
                    &copy; 2023
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* dashboard table */}
          {audit.auditTypeId != 1 && (
            <div
              className={styles["draggable-item"]}
              draggable
              onDragStart={(event) => {
                event.target.classList.add("dragging");
                scrollInterval = setInterval(() => {
                  const container = pdfRef.current;
                  if (
                    container.scrollTop + container.offsetHeight >=
                    container.scrollHeight
                  ) {
                    clearInterval(scrollInterval);
                  } else {
                    container.scrollTop += 1;
                  }
                }, 50); // Adjust the scroll speed as needed
              }}
              onDragEnd={(event) => {
                event.target.classList.remove("dragging");
                clearInterval(scrollInterval);
              }}
            >
              <div
                className={`${styles["pdf-item-container"]} title printable`}
              >
                <div className={styles["pdf-margin"]}></div>
                <div className={styles["pdf-item"]}>
                  <div className={styles["title"]}>
                    Store Evaluation Dashboard
                  </div>
                </div>
                <div className={styles["pdf-margin"]}></div>
              </div>
              {DashboardTable}
            </div>
          )}

          {/* risk analysis - static table */}
          {/* performance ratings - static table */}
          <div
            className={`${styles["pdf-item-container"]} ${styles["draggable-item"]}`}
            draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}
          >
            <div
              className={`${styles["pdf-item-container"]} printable no-title`}
            >
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className={styles["pdf-item"]}>
                    <div className={styles["subtitle"]}>
                      <div className={styles["line"]}></div>
                      Risk Analysis
                      <div className={styles["line"]}></div>
                    </div>
                    <div className={styles["risk-analysis-table"]}>
                      <div>
                        <Table
                          theads={["Risk Range", "Weight"]}
                          rows={[
                            ["Low", "1"],
                            ["Moderate", "2"],
                            ["Critical", "3"],
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles["pdf-item"]}>
                    <div className={styles["subtitle"]}>
                      <div className={styles["line"]}></div>
                      Performance Ratings
                      <div className={styles["line"]}></div>
                    </div>
                    <div className={styles["perf-ratings"]}>
                      <div className={styles["perfs"]}>
                        {perfs.map((perf, index) => {
                          return (
                            <div
                              className={styles["perf"]}
                              key={"Perf: " + index}
                            >
                              <span>{perf.title}</span>
                              <div className={styles["arrow"]}>
                                <div className={styles["arrow-tail"]}></div>
                                <div className={styles["arrow-head"]}></div>
                              </div>
                              <span>{perf.value}%</span>
                              <img
                                className={`${styles.flag} ${styles.bx} ${styles["bxs-flag"]}}`}
                                src={flagColorBasedOnString(perf.color)}
                                alt="flag-score"
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div className={styles["perf-levels"]}>
                        <span>Low</span>
                        <div className={`${styles.arrow} ${styles.vertical}`}>
                          <div className={styles["arrow-tail"]}></div>
                          <div className={styles["arrow-head"]}></div>
                        </div>
                        <span>High</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles["pdf-margin"]}></div>
            </div>
          </div>

          {/* abbreviations list */}
          <div
            className={`${styles["draggable-item"]}`}
            draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}
          >
            <div
              className={`${styles["pdf-item-container"]} title printable`}
              id="abbreviation"
            >
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>
                <div className={styles["title"]}>List of abbreviations</div>
              </div>
              <div className={styles["pdf-margin"]}></div>
            </div>
            <div
              className={`${styles["pdf-item-container"]} printable`}
              id="abbreviation"
            >
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>
                <div className={styles["abbreviation-list"]}>
                  <ol>
                    <li>1- STD: Standard</li>
                    <li>2- DEV: Deviation</li>
                    <li>3- NA: Not applicable, due to 3 reasons:</li>
                  </ol>
                  <ol>
                    <li>1- Not introduced to employees,refered to as "A"</li>
                    <li>2- Not available in facility yet,refered to as "B"</li>
                    <li>
                      3- Not identified by the inspector during the
                      visit,refered to as "C"
                    </li>
                  </ol>
                </div>
              </div>
              <div className={styles["pdf-margin"]}></div>
            </div>
          </div>

          {/* checklist table */}
          <div
            className={styles["draggable-item"]}
            draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}
          >
            <div
              className={`${styles["pdf-item-container"]} title printable`}
              id="checklist"
            >
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>
                <div className={styles["title"]}>Checklist</div>
              </div>
              <div className={styles["pdf-margin"]}></div>
            </div>
            {CheckListTable}
          </div>
          {/* action plans */}
          <div
            className={styles["draggable-item"]}
            draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}
          >
            <div
              className={`${styles["pdf-item-container"]} printable title`}
              id="action"
            >
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>
                <div className={`${styles["title"]}`}>Action Plan</div>
              </div>
              <div className={styles["pdf-margin"]}></div>
            </div>
            {ActionPlanTable}
          </div>
          {/* reported Photos */}
          <div
            className={styles["draggable-item"]}
            draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}
          >
            <div
              className={`${styles["pdf-item-container"]} printable title`}
              id="photos"
            >
              <div className={styles["pdf-margin"]}></div>
              <div className={styles["pdf-item"]}>
                <div className={styles["title"]}>Reported Photos</div>
              </div>
              <div className={styles["pdf-margin"]}></div>
            </div>
            {ReportedPhotos}
          </div>

          {/* tables container */}
          <div
            className={styles["draggable-item"]}
            draggable
            onDragStart={(event) => {
              event.target.classList.add("dragging");
              scrollInterval = setInterval(() => {
                const container = pdfRef.current;
                if (
                  container.scrollTop + container.offsetHeight >=
                  container.scrollHeight
                ) {
                  clearInterval(scrollInterval);
                } else {
                  container.scrollTop += 1;
                }
              }, 50); // Adjust the scroll speed as needed
            }}
            onDragEnd={(event) => {
              event.target.classList.remove("dragging");
              clearInterval(scrollInterval);
            }}
            id="table-hidden-container"
          ></div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const Table = ({ theads, rows }) => {
  return (
    <table style={{ tableLayout: "unset" }}>
      <thead>
        <tr>
          {theads.map((thead, index) => {
            return <th key={"Thead: " + index}>{thead}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, row_index) => {
          return (
            <tr key={"Row: " + row_index}>
              {row.map((item, item_index) => {
                return <td key={"Item: " + item_index}>{item}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const CheckListTableConstruct = (pillars) => {
  if (!pillars) return null;
  return pillars.map((pillarData, index) => {
    const { referenceCode, description, kpis } = pillarData;
    let std_t = 0;
    let dev_t = 0;
    let na_t = 0;
    return (
      <div
        className={`${styles["pdf-item-container"]} printable`}
        key={`check-sub-table-${index}`}
        id="table"
      >
        <div className={styles["pdf-margin"]}></div>
        <div className={styles["pdf-item"]}>
          <table>
            <thead>
              <tr>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-10-cell"]} ${styles["helvetica-cell"]}} ${styles["width-10-cell"]}`}
                >
                  {referenceCode}
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-10-cell"]} ${styles["helvetica-cell"]} ${styles["width-44-cell"]}`}
                >
                  {description}
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-10-cell"]} ${styles["helvetica-cell"]} ${styles["width-10-cell"]}`}
                >
                  Weight
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-10-cell"]}${styles["helvetica-cell"]} ${styles["width-7-cell"]}`}
                >
                  Std.
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-10-cell"]} ${styles["helvetica-cell"]} ${styles["width-7-cell"]}`}
                >
                  Dev.
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-10-cell"]} ${styles["helvetica-cell"]} ${styles["width-7-cell"]}`}
                >
                  N/A
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-10-cell"]} ${styles["helvetica-cell"]} ${styles["width-25-cell"]}`}
                >
                  Remarks
                </th>
              </tr>
            </thead>
            <tbody>
              {kpis.map((kpiObject, row_index) => {
                const { referenceCode, weight, auditKpis, ...rest } = kpiObject;
                const isstd =
                  auditKpis[0].kpiValueTypeAnswerId === STD_ANSWER_TYPE_ID;
                const isdev =
                  auditKpis[0].kpiValueTypeAnswerId === DEV_ANSWER_TYPE_ID;
                const isna =
                  auditKpis[0].kpiValueTypeAnswerId === NA_ANSWER_TYPE_ID;
                let remark = auditKpis[0].answerRemark || "";
                let description = auditKpis[0].description;
                if (
                  isna &&
                  auditKpis[0].answerRemark &&
                  auditKpis[0].answerRemark !== "" &&
                  auditKpis[0].kpiAnswerReason &&
                  auditKpis[0]?.kpiAnswerReason?.description !== ""
                ) {
                  remark += " - ";
                }
                if (isna) {
                  remark += auditKpis[0]?.kpiAnswerReason?.description;
                }
                if (isstd) std_t += weight;
                if (isdev) dev_t += weight;
                if (isna) na_t += weight;
                return (
                  <tr key={"Row: " + row_index}>
                    <td
                      className={`${styles["color-1-cell"]} ${styles["bold-10-cell"]} ${styles["helvetica-cell"]}`}
                    >
                      {`${auditKpis[0].sequenceNumber} - ${referenceCode}`}
                    </td>
                    <td>{description}</td>
                    <td className={styles["color-2-cell"]}>{weight}</td>
                    <td className={styles["white-cell"]}>{isstd && "X"}</td>
                    <td className={styles["white-cell"]}>{isdev && "X"}</td>
                    <td className={styles["white-cell"]}>{isna && "X"}</td>
                    <td className={styles["white-cell"]}>{remark}</td>
                  </tr>
                );
              })}
              <tr>
                <td
                  colSpan={2}
                  className={`${styles["color-3-cell"]} ${styles["bold-10-cell"]} ${styles["calibri-cell"]}`}
                >
                  TOTAL
                </td>
                <td
                  className={`${styles["color-3-cell"]} ${styles["bold-10-cell"]} ${styles["calibri-cell"]}`}
                >
                  {std_t + dev_t + na_t}
                </td>
                <td
                  className={`${styles["color-3-cell"]} ${styles["bold-10-cell"]} ${styles["calibri-cell"]}`}
                >
                  {std_t}
                </td>
                <td
                  className={`${styles["color-3-cell"]} ${styles["bold-10-cell"]} ${styles["calibri-cell"]}`}
                >
                  {dev_t}
                </td>
                <td
                  className={`${styles["color-3-cell"]} ${styles["bold-10-cell"]} ${styles["calibri-cell"]}`}
                >
                  {na_t}
                </td>
                <td
                  className={`${styles["color-3-cell"]} ${styles["bold-10-cell"]} ${styles["calibri-cell"]}`}
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles["pdf-margin"]}></div>
      </div>
    );
  });
};

const ActionPlanTableConstruct = (pillars, devStat) => {
  if (!pillars) return null;

  return devPillar(pillars).map((pillarData, index) => {
    const { referenceCode, description, kpis } = pillarData;
    return (
      <div
        className={`${styles["pdf-item-container"]} printable`}
        key={`plan-sub-table-${index}`}
        id="table"
      >
        <div className={styles["pdf-margin"]}></div>
        <div className={styles["pdf-item"]}>
          <table>
            <thead>
              <tr>
                <th
                  className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]} ${styles["width-10-cell"]}`}
                >
                  {referenceCode}
                </th>
                <th
                  colSpan={2}
                  className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]} ${styles["width-65-cell"]}`}
                >
                  {description}
                </th>
                <th
                  colSpan={3}
                  className={`${styles["color-1-cell"]} ${styles["helvetica-cell"]} ${styles["width-30-cell"]}`}
                >
                  Frequency of Deviation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className={`${styles["color-2-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                >
                  REF
                </td>
                <td
                  className={`${styles["color-2-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                  style={{ width: "50%" }}
                >
                  DEVIATION
                </td>
                <td
                  className={`${styles["color-2-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                  style={{ width: "50%" }}
                >
                  CORRECTIVE ACTION
                </td>
                <td
                  className={`${styles["color-2-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                >
                  1
                </td>
                <td
                  className={`${styles["color-2-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                >
                  2
                </td>
                <td
                  className={`${styles["color-2-cell"]} ${styles["helvetica-cell"]} ${styles["bold-12-cell"]}`}
                >
                  3+
                </td>
              </tr>
              {kpis.map((kpiObject, row_index) => {
                const { referenceCode, auditKpis } = kpiObject;
                const devUsed = devStat.reduce((acc, id) => {
                  if (id === auditKpis[0]?.kpiDeviationId) {
                    return acc + 1;
                  } else {
                    return acc;
                  }
                }, 0);
                let deviationDescription = "";
                let actionDescription = "";
                let remarks = "";
                if (auditKpis) {
                  deviationDescription = auditKpis[0].kpiDeviation?.description;
                  actionDescription = auditKpis[0].kpiAction?.description;
                  remarks = auditKpis[0].answerRemark;
                }
                return (
                  <tr key={"Row: " + row_index}>
                    <td
                      className={`${styles["color-3-cell"]} ${styles["helvetica-cell"]} ${styles["bold-10-cell"]}`}
                    >
                      {`${auditKpis[0].sequenceNumber} - ${referenceCode}`}
                    </td>
                    <td>
                      {deviationDescription?.trim().toLowerCase() === "other"
                        ? remarks
                        : deviationDescription}
                    </td>
                    <td>{actionDescription}</td>
                    <td className={styles["white-cell"]}>
                      {devUsed === 1 && "X"}
                    </td>
                    <td className={styles["white-cell"]}>
                      {devUsed === 2 && "X"}
                    </td>
                    <td className={styles["white-cell"]}>
                      {devUsed >= 3 && "X"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["pdf-margin"]}></div>
      </div>
    );
  });
};

const DashboardTableConstruct = (pillars) => {
  if (!pillars)
    return {
      dashboardTableHtml: null,
      score: 0,
    };
  let std_gt = 0;
  let dev_gt = 0;
  let na_gt = 0;
  return {
    dashboardTableHtml: (
      <div className={`${styles["pdf-item-container"]} printable`} id="table">
        <div className={styles["pdf-margin"]}></div>
        <div className={styles["pdf-item"]}>
          <table>
            <thead>
              <tr>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]} ${styles["width-5-cell"]}`}
                >
                  Ref
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]} ${styles["width-45-cell"]}`}
                >
                  Category
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]} ${styles["width-10-cell"]}`}
                >
                  Weight
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]} ${styles["width-15-cell"]}`}
                >
                  Standard Points
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]} ${styles["width-15-cell"]}`}
                >
                  Deviations Points
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]} ${styles["width-15-cell"]}`}
                >
                  N/A Points
                </th>
                <th
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]} ${styles["width-15-cell"]}`}
                >
                  Score %
                </th>
              </tr>
            </thead>
            <tbody>
              {pillars.map((pillarObject, row_index) => {
                const { description, referenceCode, kpis } = pillarObject;
                let std_t = 0;
                let dev_t = 0;
                let na_t = 0;
                for (let index = 0; index < kpis.length; index++) {
                  const element = kpis[index];
                  const weight = element.weight;
                  const auditKpi = element.auditKpis[0];

                  const isstd =
                    auditKpi.kpiValueTypeAnswerId === STD_ANSWER_TYPE_ID;
                  const isdev =
                    auditKpi.kpiValueTypeAnswerId === DEV_ANSWER_TYPE_ID;
                  const isna =
                    auditKpi.kpiValueTypeAnswerId === NA_ANSWER_TYPE_ID;
                  if (isstd) std_t += weight;
                  if (isdev) dev_t += weight;
                  if (isna) na_t += weight;
                }
                std_gt += std_t;
                dev_gt += dev_t;
                na_gt += na_t;

                return (
                  <tr key={"Row: " + row_index}>
                    <td
                      className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                    >
                      {referenceCode}
                    </td>
                    <td
                      className={`${styles["color-3-cell"]} ${styles["left-align-cell"]}`}
                    >
                      {description}
                    </td>
                    <td className={styles["color-2-cell"]}>
                      {std_t + dev_t + na_t}
                    </td>
                    <td className={styles["color-3-cell"]}>{std_t}</td>
                    <td className={styles["color-3-cell"]}>{dev_t}</td>
                    <td className={styles["color-3-cell"]}>{na_t}</td>
                    <td
                      className={`${styles["color-2-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                    >
                      {std_t === 0 ? "-" : percentage(std_t, std_t + dev_t)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td rowSpan={2} className={styles["color-1-cell"]}></td>
                <td className={styles["color-3-cell"]}>Total</td>
                <td className={styles["color-2-cell"]}>
                  {std_gt + dev_gt + na_gt}
                </td>
                <td className={styles["color-3-cell"]}>{std_gt}</td>
                <td className={styles["color-3-cell"]}>{dev_gt}</td>
                <td className={styles["color-3-cell"]}>{na_gt}</td>
                <td
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                  rowSpan={2}
                >
                  {std_gt === 0 ? "-" : percentage(std_gt, std_gt + dev_gt)}
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                >
                  Total %
                </td>
                <td
                  className={`${styles["color-1-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                >
                  100 %
                </td>
                <td
                  className={`${styles["color-2-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                >
                  {percentage(std_gt, std_gt + dev_gt + na_gt)}
                </td>
                <td
                  className={`${styles["color-2-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                >
                  {percentage(dev_gt, std_gt + dev_gt + na_gt)}
                </td>
                <td
                  className={`${styles["color-2-cell"]} ${styles["bold-12-cell"]} ${styles["helvetica-cell"]}`}
                >
                  {percentage(na_gt, std_gt + dev_gt + na_gt)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className={styles["pdf-margin"]}></div>
      </div>
    ),
    score: std_gt === 0 ? "-" : percentageScore(std_gt, std_gt + dev_gt),
  };
};

const ReportedPhotosContruct = (pillars) => {
  if (!pillars) return null;
  return (
    <div>
      {devPillar(pillars).map((pillarObject, index) => {
        const { kpis, ...rest } = pillarObject;
        return kpis.map((kpiObject, row_index) => {
          const { referenceCode, auditKpis, ...rest } = kpiObject;
          const auditKpi = auditKpis[0];
          const riskDescription = auditKpis[0].kpiRisk?.description || "";
          const deviationDescription =
            auditKpis[0].kpiDeviation?.description || "";
          const answerRemark = auditKpis[0].answerRemark;
          const attachments = auditKpis[0].attachments;
          return attachments.map((attachmentObject, row_index) => {
            return (
              <div
                className={`${styles["pdf-item-container"]} printable`}
                key={`attachment-${row_index}`}
              >
                <div className={styles["pdf-margin"]}></div>
                <div className={styles["pdf-item"]}>
                  <div className={styles["photo-container"]}>
                    <Avatar
                      sx={{
                        width: "200px",
                        height: "200px",
                        cursor: "pointer",
                        position: "relative",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        "& img": {
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                        },
                      }}
                      variant="rounded"
                      src={attachmentObject.url}
                    ></Avatar>
                    <div className={styles["photo-info"]}>
                      <span>{`Deviation reference: ${referenceCode}`}</span>
                      {deviationDescription?.trim().toUpperCase() ===
                      "OTHER" ? null : (
                        <span>{deviationDescription}</span>
                      )}
                      <span style={{ color: "#41B06E" }}>{answerRemark}</span>
                      <span>{riskDescription}</span>
                    </div>
                  </div>
                </div>
                <div className={styles["pdf-margin"]}></div>
              </div>
            );
          });
        });
      })}
    </div>
  );
};
