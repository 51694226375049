import MDButton from "components/MDButton";
import { useSearchParams } from "react-router-dom";

export default function TabButton({ value, label }) {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <MDButton
      size="small"
      variant={searchParams.get("tab") === value ? "gradient" : "outlined"}
      color={searchParams.get("tab") === value ? "info" : "text"}
      onClick={() => {
        setSearchParams((prev) => {
          prev.set("tab", value);
          return prev;
        });
      }}
    >
      {label}
    </MDButton>
  );
}
