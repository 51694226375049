import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import AlertDialog from "components/AlertDialog";
import TerritoriesDialog from "./TerritoriesDialog";
import { useState, useEffect, useMemo } from "react";
import MDBox from "components/MDBox";
import TableSearch from "components/TableSearch";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AccessDenied from "components/AccessDenied";
import locationService from "services/location-service";
import { Badge, Box, Chip, Link, Stack, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function AllTerritories({ permissions }) {
  const navigate = useNavigate();

  const [territories, setTerritories] = useState(null);
  const [options, setOptions] = useState({ countries: null });
  const [currentTerritory, setCurrentTerritory] = useState(null);
  const [lastFilter, setLastFilter] = useState(null);

  const [rows, setRows] = useState([]);

  const [newDialogIsOpen, setNewDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Regions",
      accessor: "regions",
      width: "100%",
    },
  ];

  if (permissions.update || permissions.delete) {
    columns.push({
      Header: "",
      accessor: "actions",
      align: "right",
      width: "100%",
    });
  }

  useEffect(() => {
    updateRows();
  }, [territories]);

  useEffect(() => {
    handleGetOptions();
  }, []);

  async function handleGetOptions() {
    async function getCountries() {
      const [response, data] = await locationService.getCountries();
      if (response.ok) {
        setOptions((curr) => ({ ...curr, countries: data }));
      }
    }

    await Promise.all([getCountries()]);
  }

  async function handleAddTerritory(newElement) {
    const [response, data] = await locationService.createTerritory(newElement);
    if (response.ok) {
      const [response, data] = await locationService.getTerritories(lastFilter);
      if (response.ok) {
        setTerritories(data);
      }

      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  async function handleUpdateTerritory(newElement) {
    const [response, data] = await locationService.updateTerritory(
      newElement.id,
      newElement
    );
    if (response.ok) {
      const [response, data] = await locationService.getTerritories(lastFilter);
      if (response.ok) {
        setTerritories(data);
      }
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  async function handleDeleteTerritory(id) {
    const [response, data] = await locationService.removeTerritory(id);
    if (response.ok) {
      const [response, data] = await locationService.getTerritories(lastFilter);
      if (response.ok) {
        setTerritories(data);
      }
      return { success: true };
    } else {
      return { error: data.error };
    }
  }

  const currentCountry = useMemo(() => {
    if (lastFilter?.countryId && options.countries)
      return options.countries.find((t) => t.id == lastFilter.countryId);
  }, [options.countries, lastFilter?.countryId]);

  function updateRows() {
    setRows(
      territories?.length > 0
        ? territories.map((territory) => {
            return {
              name: (
                <MDTypography variant="inherit">{territory.name}</MDTypography>
              ),

              regions: (
                <Stack
                  direction="row"
                  overflow={"hidden"}
                  flexWrap={"wrap"}
                  sx={{ gap: 1 }}
                >
                  {territory.regions?.length > 0
                    ? territory.regions?.map((region) => (
                        <Chip
                          size="small"
                          variant="outlined"
                          label={region.description}
                        />
                      ))
                    : "None"}
                </Stack>
              ),

              actions: (
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="end"
                  alignItems="center"
                >
                  <Tooltip title={"View regions"}>
                    <Badge
                      badgeContent={territory.regions?.length || 0}
                      size="small"
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          navigate(
                            "/regions?autocomplete=&territoryId=" + territory.id
                          );
                        }}
                      >
                        <Icon>place</Icon>
                      </IconButton>
                    </Badge>
                  </Tooltip>
                  {permissions.update && (
                    <Tooltip title={"Edit"}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setEditDialogIsOpen(Math.random());
                          setCurrentTerritory(territory);
                        }}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                  {permissions.delete && (
                    <Tooltip title={"Delete"}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setAlertIsOpen(Math.random());
                          setCurrentTerritory(territory);
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                </MDBox>
              ),
            };
          })
        : territories?.length === 0
        ? [
            {
              name: "No territories were found.",
            },
          ]
        : [
            {
              name: "Loading...",
            },
          ]
    );
  }

  const filterBySelectsItems = useMemo(() => {
    return [
      {
        id: "countryId",
        items: [
          { value: 0, render: "All" },
          ...(options?.countries?.map((country) => {
            return {
              value: country.id,
              render: country.name,
            };
          }) || []),
        ],
        label: "Filter by country",
      },
    ];
  }, [options?.countries]);

  return (
    <>
      <AlertDialog
        open={alertIsOpen}
        setOpen={setAlertIsOpen}
        title={"Are you sure?"}
        content={"Delete this territory?"}
        onOk={async () => {
          await handleDeleteTerritory(currentTerritory.id);
        }}
      />
      <TerritoriesDialog
        isOpen={newDialogIsOpen}
        onSubmit={handleAddTerritory}
        data={{
          countries: options.countries,
          territory: { name: "", countryId: currentCountry?.id || null },
        }}
      />
      <TerritoriesDialog
        type="edit"
        isOpen={editDialogIsOpen}
        onSubmit={handleUpdateTerritory}
        data={{ countries: options.countries, territory: currentTerritory }}
      />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {currentCountry ? `${currentCountry.name}'s ` : " "}
                  Territories{" "}
                  {permissions.get ? `(${territories?.length || 0})` : ""}
                </MDTypography>
                {permissions.create && (
                  <MDButton
                    onClick={() => {
                      setNewDialogIsOpen(Math.random());
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;add new territory
                  </MDButton>
                )}
              </MDBox>
              {permissions.get ? (
                <>
                  <MDBox p={2} mt={2}>
                    <TableSearch
                      id="all-territories"
                      internalLoading={!Boolean(territories)}
                      externalLoading={!Boolean(options.countries)}
                      fullWidth
                      setData={setTerritories}
                      onSearch={async (request, callback) => {
                        setLastFilter(request);
                        callback(await locationService.getTerritories(request));
                      }}
                      label={"Search territories"}
                      filterBySelectsItems={filterBySelectsItems}
                    />
                  </MDBox>
                  <MDBox>
                    <DataTable
                      loading={!Boolean(territories)}
                      table={{ columns: columns, rows: rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </>
              ) : (
                <AccessDenied type="component" />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
