import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";

export default function MDGroupedSelect({
  label,
  items,
  value,
  onChange,
  readOnly,
  createButton,
  fullWidth,
  required,
}) {
  return (
    <MDBox sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      <FormControl fullWidth={fullWidth} sx={{ minWidth: 120 }}>
        <InputLabel htmlFor={"grouped-native-select" + label}>
          {label}
        </InputLabel>
        <Select
          native
          required
          value={value}
          sx={{ pointerEvents: readOnly ? "none" : "auto" }}
          id={"grouped-native-select" + label}
          label={label}
          onChange={(e) => {
            if (readOnly) return;
            onChange(e.target.value);
          }}
        >
          <option key={"Option: " + 0 + "NONE"} value={""}></option>
          {items.map((optionGroup, index) => {
            return (
              <optgroup
                label={optionGroup.name}
                key={"Optiongroup: " + optionGroup.name + index}
              >
                {optionGroup.options.map((option, optionIndex) => {
                  return (
                    <option
                      key={"Option: " + option.value + option.name}
                      value={option.value}
                    >
                      {option.name}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </Select>
      </FormControl>
      {createButton && (
        <div>
          <MDButton
            onClick={createButton}
            variant="gradient"
            color="info"
            iconOnly
          >
            <Icon fontSize="lg">add</Icon>
          </MDButton>
        </div>
      )}
    </MDBox>
  );
}
