import HttpService from "./htttp.service";

class RolesService {
  getRoles = async (payload, cancelToken) => {
    const endpoint = "roles";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  getPermissions = async (payload, cancelToken) => {
    const endpoint = "roles/permissions";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  getMyPermissions = async (cancelToken) => {
    const endpoint = "roles/permissions/me";
    return await HttpService.get(endpoint, null, cancelToken);
  };

  createRole = async (query, payload) => {
    const endpoint = "roles";
    return await HttpService.post(endpoint, payload, query);
  };

  addPermission = async (query, payload) => {
    const endpoint = "roles/permissions";
    return await HttpService.post(endpoint, payload, { ...query });
  };

  updatePermissions = async (id, query, payload) => {
    const endpoint = "roles/" + id + "/permissions";
    return await HttpService.put(endpoint, payload, query);
  };

  updateRole = async (id, query, payload) => {
    const endpoint = "roles/" + id;
    return await HttpService.put(endpoint, payload, query);
  };

  removeRole = async (id, query) => {
    const endpoint = "roles/" + id;
    return await HttpService.delete(endpoint, query);
  };

  removePermission = async (query, payload) => {
    const endpoint = "roles/permissions";
    return await HttpService.delete(endpoint, { ...query });
  };
}

export default new RolesService();
