import PageContainer from "components/PageContainer";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import AuditsCalendar from "./components/AuditsCalendar";

export default function CalendarPage() {
  const [route, setRoute] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setRoute(null);
  }, [location]);

  return (
    <DashboardLayout>
      <DashboardNavbar customRoute={route} />

      <PageContainer>
        <Routes>
          <Route index element={<AuditsCalendar />} />
        </Routes>
      </PageContainer>

      <Footer />
    </DashboardLayout>
  );
}
