import HttpService from "./htttp.service";

class PositionsService {
  getPositions = async (payload, cancelToken) => {
    const endpoint = "positions";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  createPosition = async (payload) => {
    const endpoint = "positions";
    return await HttpService.post(endpoint, payload);
  };
}

export default new PositionsService();
