import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { CardContent, Icon, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBadge from "components/MDBadge";

export default function SubscriptionItem({
  subscription,
  live,
  template,
  selected,
  onUnsubsribe,
  noUnsubscribe = true,
  sx,
}) {
  const plan = subscription?.subscription;
  return (
    <Card
      sx={(theme) => ({
        boxShadow: "none",
        border:
          live || selected
            ? "1px solid " + theme.palette.info.main
            : "1px solid rgba(0, 0, 0, 0.15)",
        boxSizing: "border-box",
        backgroundColor:
          live || selected ? theme.palette.info.main + "11" : "white",
        borderRadius: "6px",
        ...sx,
      })}
    >
      <CardContent sx={{ p: 0, m: 0 }}>
        <MDBox
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="start"
          sx={{ mt: 1.5, mx: 2, mr: 1.5 }}
        >
          <MDTypography variant="h6">{plan?.name}</MDTypography>

          <MDBox
            p={0}
            m={0}
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
          >
            <MDBadge badgeContent={`${plan?.period} months`} />

            {!template && (
              <MDBadge
                sx={{ ml: -1 }}
                color={
                  live
                    ? "success"
                    : subscription.cancelledAt
                    ? "error"
                    : "warning"
                }
                badgeContent={
                  live
                    ? "Live"
                    : subscription.cancelledAt
                    ? "Cancelled"
                    : "Expired"
                }
              />
            )}

            {live && !template && !noUnsubscribe && (
              <IconButton sx={{ m: -0.5 }} size="small" onClick={onUnsubsribe}>
                <Icon sx={(theme) => ({ color: theme.palette.secondary.main })}>
                  cancel
                </Icon>
              </IconButton>
            )}
            {template && (
              <MDTypography variant="h6">
                ${plan?.price.toFixed(2)}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
        <MDTypography
          variant="body2"
          sx={{ fontWeight: "400", fontSize: "14px", mx: 2 }}
        >
          {plan?.description}
        </MDTypography>
        {!template && (
          <Grid container sx={{ px: 2, mt: 1, mb: 1 }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <MDTypography
                variant="body2"
                sx={{ fontWeight: "400", fontSize: "12px" }}
              >
                Started on:
              </MDTypography>
              <MDTypography
                variant="body2"
                sx={{ fontWeight: "500", fontSize: "12px" }}
              >
                {new Date(subscription.startedAt).toLocaleString()}
              </MDTypography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <MDTypography
                variant="body2"
                sx={{ fontWeight: "400", fontSize: "12px" }}
              >
                {live
                  ? "Ends"
                  : subscription.cancelledAt
                  ? "Cancelled"
                  : "Expired"}{" "}
                on:
              </MDTypography>
              <MDTypography
                variant="body2"
                sx={{ fontWeight: "500", fontSize: "12px" }}
              >
                {(subscription.cancelledAt
                  ? new Date(subscription.cancelledAt)
                  : new Date(subscription.expiresAt)
                ).toLocaleString()}
              </MDTypography>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          sx={{ borderTop: "1px solid rgba(0, 0, 0, .15)", mt: 1.5 }}
        >
          {[
            {
              label: "Audit limit per month: ",
              value:
                plan?.maxAuditsMonth == -1 ? "Unlimited" : plan?.maxAuditsMonth,
            },
            {
              label: "Audit limit per period: ",
              value:
                plan?.maxAuditsPeriod == -1
                  ? "Unlimited"
                  : plan?.maxAuditsPeriod,
            },
            {
              label: "Users limit: ",
              value: plan?.maxUsers == -1 ? "Unlimited" : plan?.maxUsers,
            },
            {
              label: "Sub-accounts limit: ",
              value:
                plan?.maxSubAccounts == -1 ? "Unlimited" : plan?.maxSubAccounts,
            },
            {
              label: "Branch groups limit: ",
              value:
                plan?.maxBranchGroups == -1
                  ? "Unlimited"
                  : plan?.maxBranchGroups,
            },
            {
              label: "Type: ",
              value: plan?.type == 2 ? "External" : "Internal",
            },
            {
              label: "Countries: ",
              value: plan?.countries.map((c) => c.name).join(", "),
            },
            {
              label: "Notify expiry to account manager: ",
              value: plan?.notifyExpiry ? "Yes" : "No",
            },
          ].map((feature, index) => (
            <SubscriptionFeature feature={feature} isLast />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

function SubscriptionFeature({ feature, isLast }) {
  return (
    <Grid
      item
      container
      xs={12}
      alignItems="center"
      sx={{
        alignItems: "center",
        borderBottom: "1px solid rgba(0, 0, 0, .15)",
        mx: isLast ? 0 : 2,
        px: isLast ? 2 : 0,
      }}
    >
      <Grid item xs>
        <MDTypography
          variant="body2"
          sx={{ fontWeight: "400", fontSize: "14px" }}
        >
          {feature.label}
        </MDTypography>
      </Grid>
      <Grid item>
        <MDTypography
          variant="body2"
          sx={{ fontWeight: "500", fontSize: "14px" }}
        >
          {feature.value}
        </MDTypography>
      </Grid>
    </Grid>
  );
}
