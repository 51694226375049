import { LinearProgress } from "@mui/material";
import { useLoading } from "contexts/loading.context";

export default function LoadingBar() {
  const { loading } = useLoading();
  return (
    <LinearProgress
      sx={{
        position: "fixed",
        top: 0,
        width: "calc(100vw - 17px)",
        height: loading ? "6px" : 0,
        left: 0,
        //transition: "all 0.1s ease-in-out",
        zIndex: 5000,
      }}
      color="info"
    />
  );
}
