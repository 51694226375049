import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";
import AllApps from "./components/AllApps";

import PageContainer from "components/PageContainer";
import { Route, Routes, useLocation } from "react-router-dom";

import AccessDenied from "components/AccessDenied";
import { useAuth } from "contexts/auth.context";
import { useEffect, useState } from "react";

function Audits() {
  const [route, setRoute] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setRoute(null);
  }, [location]);

  const { checkPermission } = useAuth();

  if (
    Object.keys(checkPermission("app")).filter(
      (key) => checkPermission("app")[key]
    ).length == 0
  ) {
    return <AccessDenied type="page" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar customRoute={route} />

      <PageContainer>
        <Routes>
          <Route
            index
            element={<AllApps permissions={checkPermission("app")} />}
          />
        </Routes>
      </PageContainer>

      <Footer />
    </DashboardLayout>
  );
}

export default Audits;
