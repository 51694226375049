import HttpService from "./htttp.service";

class RegionsService {
  getRegions = async (payload, cancelToken) => {
    const endpoint = "location/regions";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  getCountries = async (payload, cancelToken) => {
    const endpoint = "location/countries";
    return await HttpService.get(endpoint, payload, cancelToken);
  };
}

export default new RegionsService();
