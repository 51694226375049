import HttpService from "./htttp.service";

class TestService {
  test = async (payload) => {
    const endpoint = "test";
    return await HttpService.get(endpoint, payload);
  };

  uploadImage = async (payload) => {
    const endpoint = "test";
    return await HttpService.uploadImage(endpoint, payload);
  };
}

export default new TestService();
