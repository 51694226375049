import { Box, Checkbox, Chip, Grid, Icon, IconButton } from "@mui/material";
import MDTypography from "components/MDTypography";

import IndeterminateIcon from "components/IndeterminateIcon";
import { memo, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/StyledAccordion";
import RowKpi from "./RowKpi";

const RowPillar = memo(
  ({
    pillar,
    pillarIndex,
    utils,
    include,
    updatableDescription,
    defaultExpanded,
  }) => {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const [checkbox, setCheckbox] = useState({
      checked: false,
      indeterminate: false,
    });

    useEffect(
      () => {
        let allSelected = true;
        let noneSelected = true;

        pillar.kpis?.forEach((kpi) => {
          if (kpi.selected) {
            noneSelected = false;
          } else {
            allSelected = false;
          }
        });

        if (allSelected) {
          setCheckbox({ checked: true, indeterminate: false });
        } else if (noneSelected) {
          setCheckbox({ checked: false, indeterminate: false });
        } else {
          setCheckbox({ checked: false, indeterminate: true });
        }
      },
      pillar.kpis.map((kpi) => Boolean(kpi.selected))
    );

    return (
      <Grid item xs={12}>
        <Accordion
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          sx={(theme) => ({
            borderBottom: "none",
          })}
        >
          <AccordionSummary
            aria-controls={pillar.id + "d-content"}
            id={pillar.id + "d-header"}
            sx={(theme) => ({
              position: "relative",
              "&:hover": {
                backgroundColor: theme.palette.primary.main + "11",
              },
            })}
            {...(pillar?.kpis?.length
              ? {}
              : {
                  expandIcon: (
                    <Icon sx={{ fontSize: "0.9rem !important" }}></Icon>
                  ),
                })}
          >
            <Box
              sx={(theme) => ({
                height: "100%",
                width: "4px",
                backgroundColor: theme.palette.primary.main,
                position: "absolute",
                left: 0,
                top: 0,
              })}
            ></Box>

            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                flexWrap: "nowrap",
              }}
              alignItems={"center"}
            >
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  disabled={!pillar?.kpis?.length}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e, checked) => {
                    if (checked) {
                      utils.handleSelectPillar(pillarIndex);
                    } else {
                      utils.handleDeselectPillar(pillarIndex);
                    }
                  }}
                  sx={{
                    opacity: !pillar?.kpis?.length ? 0.5 : 1,
                  }}
                  checked={!pillar?.kpis?.length ? false : checkbox.checked}
                  indeterminate={checkbox.indeterminate}
                  indeterminateIcon={<IndeterminateIcon />}
                />
                <MDTypography
                  sx={{
                    fontSize: "1rem",
                  }}
                >
                  {pillar.referenceCode} — {pillar.description}
                </MDTypography>
              </Grid>
              {include?.weight && (
                <Grid item xs={2}>
                  <Chip
                    label={"Weight: " + pillar.weight}
                    size="small"
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: "500",
                      backgroundColor: "white !important",
                    }}
                    variant="outlined"
                  />
                </Grid>
              )}
              {include?.categories && (
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  {pillar.subcategories.map((subcateogry) => {
                    return (
                      <Chip
                        key={
                          "Pillar: " +
                          pillar.id +
                          "subcategory: " +
                          subcateogry.id
                        }
                        label={subcateogry.description}
                        size="small"
                        sx={{
                          fontSize: "0.8rem",
                          fontWeight: "500",
                          backgroundColor: "white !important",
                        }}
                        variant="outlined"
                      />
                    );
                  })}
                </Grid>
              )}

              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                {include?.pillar?.create && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Icon>add</Icon>
                  </IconButton>
                )}
                {include?.pillar?.update && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                )}
                {include?.pillar?.delete && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ py: 0, px: 0, width: "100%" }}>
            <Grid container sx={{ width: "100%" }}>
              {pillar.kpis.map((kpi, kpiIndex) => {
                return (
                  <RowKpi
                    key={"Pillar: " + pillar.id + "kpi: " + kpi.id}
                    kpi={kpi}
                    pillarIndex={pillarIndex}
                    kpiIndex={kpiIndex}
                    utils={utils}
                    include={include}
                    selected={kpi.selected}
                    description={kpi.description}
                    updatableDescription={updatableDescription}
                  />
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  }
);

export default RowPillar;
