import HttpService from "./htttp.service";

class ThemeService {
  createColorPalette = async (payload, cancelToken) => {
    const endpoint = "themes";
    return await HttpService.post(endpoint, payload, cancelToken);
  };

  getTheme = async (payload, cancelToken) => {
    const endpoint = "themes";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  getColorPalettes = async (payload) => {
    const endpoint = "themes";
    return await HttpService.get(endpoint, payload);
  };

  updateColorPalette = async (query, payload) => {
    const endpoint = "themes/" + query;
    return await HttpService.put(endpoint, payload);
  };

  removeColorPalette = async (query, payload) => {
    const endpoint = "themes/" + query;
    return await HttpService.delete(endpoint, payload);
  };
}

export default new ThemeService();
