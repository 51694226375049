import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import AllAccounts from "./components/AllAccounts.js";

import PageContainer from "components/PageContainer/index.js";
import Footer from "examples/Footer/index.js";
import { Route, Routes, useLocation } from "react-router-dom";

import AccessDenied from "components/AccessDenied/index.js";
import { useAuth } from "contexts/auth.context";
import { useEffect, useMemo, useState } from "react";
import Account from "./components/Account.js";

function Accounts() {
  const [route, setRoute] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setRoute(null);
  }, [location.pathname]);

  const { checkPermission } = useAuth();

  if (
    Object.keys(checkPermission("account")).filter(
      (key) => checkPermission("account")[key]
    ).length === 0
  ) {
    return <AccessDenied type="page" />;
  }

  const requiredPermissions = useMemo(() => {
    return {
      account: checkPermission("account"),
      user: checkPermission("user"),
      branchGroup: checkPermission("branchGroup"),
      branch: checkPermission("branch"),
      role: checkPermission("role"),
      subscriptions: checkPermission("subscription"),
    };
  }, [checkPermission]);

  return (
    <DashboardLayout>
      <DashboardNavbar customRoute={route} />

      <PageContainer>
        <Routes>
          <Route
            index
            element={<AllAccounts permissions={checkPermission("account")} />}
          />
          <Route
            path={"/:id"}
            element={
              <Account setRoute={setRoute} permissions={requiredPermissions} />
            }
          />

          <Route
            path={"/:mode/:id"}
            element={
              <Account setRoute={setRoute} permissions={requiredPermissions} />
            }
          />
        </Routes>
      </PageContainer>

      <Footer />
    </DashboardLayout>
  );
}

export default Accounts;
