import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function BasicDialog({
  button,
  title,
  content,
  actions,
  cancelButton,
  open,
  setOpen,
}) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setDialogOpen(open ? true : false);
  }, [open]);

  return (
    <>
      {button && <div onClick={handleClickOpen}>{button}</div>}
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          {cancelButton && <Button onClick={handleClose}>Cancel</Button>}
          {actions?.map((action) => (
            <Button onClick={action.onClick}>{action.text}</Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
}
