import HttpService from "./htttp.service";

class SummaryGroupsService {
  getSummaryGroups = async (query, cancelToken) => {
    const endpoint = "summary-groups";
    return await HttpService.get(endpoint, query, cancelToken);
  };

  createSummaryGroup = async (payload) => {
    const endpoint = "summary-groups";
    return await HttpService.post(endpoint, payload);
  };

  removeSummaryGroup = async (id) => {
    const endpoint = "summary-groups/" + id;
    return await HttpService.delete(endpoint, id);
  };

  updateSummaryGroup = async (id, payload) => {
    const endpoint = "summary-groups/" + id;
    return await HttpService.put(endpoint, payload);
  };

  updatePillarSummaryGroup = async (query, payload) => {
    const endpoint = `audits/${query.auditId}/pillars/${query.pillarId}`;
    return await HttpService.put(endpoint, payload);
  };
}

export default new SummaryGroupsService();
