import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import AccessDenied from "components/AccessDenied";
import AlertDialog from "components/AlertDialog";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import TableSearch from "components/TableSearch";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import categoriesService from "services/categories-service";
import CategoryDialog from "./CategoryDialog";

export default function AllCategories({ permissions }) {
  const columns = [
    {
      Header: "Categories",
      accessor: "categories",
      width: "20%",
    },
    {
      Header: "Subcategories",
      accessor: "subcategories",
      width: "65%",
    },
  ];

  if (permissions.update || permissions.delete) {
    columns.push({
      Header: "",
      accessor: "actions",
      width: "100%",
      align: "right",
    });
  }

  const [rows, setRows] = useState([]);

  const [categories, setCategories] = useState(null);
  const [currentCategory, setCurrentCategory] = useState({
    description: "",
  });
  const [currentSubcategory, setCurrentSubcategory] = useState({
    description: "",
  });

  const [dialogType, setDialogType] = useState("category");
  const [dialogAction, setDialogAction] = useState("create");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOnSubmit, setDialogOnSubmit] = useState(() => async () => {
    return { success: true };
  });

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogType, setAlertDialogType] = useState("category");
  const [alertDialogOnSubmit, setAlertDialogOnSubmit] = useState(
    () => async () => {
      return { success: true };
    }
  );

  useEffect(() => {
    if (categories) updateRows();
  }, [categories]);

  /*useEffect(() => {
    const source = axios.CancelToken.source();
    handleGetCategories(source.token);
    return () => {
      source.cancel("Component unmounted");
    };
  }, []);*/

  /*async function handleGetCategories(cancelToken) {
    if (!permissions.get) return;
    const [response, data] = await categoriesService.getCategories(
      null,
      cancelToken
    );
    if (response.ok) {
      setCategories(data);
    } else {
      console.log("ERROR while getting categories: " + data.error);
    }
  }*/

  async function handleEditSubcategory(newSubcategory) {
    const [response, data] = await categoriesService.updateSubcategory(
      newSubcategory.id,
      newSubcategory
    );
    if (response.ok) {
      setCategories(data);
      return { success: true };
    } else {
      console.log("ERROR while getting categories: " + data.error);
      return { error: data.error };
    }
  }

  async function handleEditCategory(newCategory) {
    const [response, data] = await categoriesService.updateCategory(
      newCategory.id,
      newCategory
    );
    if (response.ok) {
      setCategories(data);
      return { success: true };
    } else {
      console.log("ERROR while getting categories: " + data.error);
      return { error: data.error };
    }
  }

  async function handleCreateSubcategory(newSubcategory) {
    const [response, data] =
      await categoriesService.createSubcategory(newSubcategory);
    if (response.ok) {
      setCategories(data);
      return { success: true };
    } else {
      console.log("ERROR while getting categories: " + data.error);
      return { error: data.error };
    }
  }

  async function handleCreateCategory(newCategory) {
    const [response, data] =
      await categoriesService.createCategory(newCategory);
    if (response.ok) {
      setCategories(data);
      return { success: true };
    } else {
      console.log("ERROR while getting categories: " + data.error);
      return { error: data.error };
    }
  }

  async function handleRemoveSubcategory(newSubcategory) {
    const [response, data] = await categoriesService.removeSubcategory(
      newSubcategory.id
    );
    if (response.ok) {
      setCategories(data);
      return { success: true };
    } else {
      console.log("ERROR while getting categories: " + data.error);
      return { error: data.error };
    }
  }

  async function handleRemoveCategory(newCategory) {
    const [response, data] = await categoriesService.removeCategory(
      newCategory.id
    );
    if (response.ok) {
      setCategories(data);
      return { success: true };
    } else {
      console.log("ERROR while getting categories: " + data.error);
      return { error: data.error };
    }
  }

  function updateRows() {
    setRows(
      categories.length > 0
        ? categories.map((category) => {
            return {
              categories: (
                <MDTypography variant="inherit">
                  {category.description}
                </MDTypography>
              ),
              subcategories: (
                <Stack
                  direction="row"
                  useFlexGap
                  spacing={{ xs: 1, sm: 1 }}
                  flexWrap="wrap"
                  sx={{ maxWidth: "calc(50vw)" }}
                >
                  {category.subcategories.length > 0 &&
                    category.subcategories.map((subcategory, index) => {
                      return (
                        <Chip
                          size="small"
                          key={"subcategory " + index}
                          label={
                            <MDBox
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              {subcategory.description}
                              <MDBox
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                {permissions.update && (
                                  <IconButton
                                    sx={{
                                      m: -0.35,
                                    }}
                                    size="small"
                                    onClick={() => {
                                      setDialogOpen(Math.random());
                                      setDialogType("subcategory");
                                      setDialogAction("edit");
                                      setCurrentSubcategory({ ...subcategory });
                                      setDialogOnSubmit(
                                        () => handleEditSubcategory
                                      );
                                    }}
                                  >
                                    <Icon
                                      fontSize="small"
                                      sx={{ fontSize: "13px !important" }}
                                    >
                                      edit
                                    </Icon>
                                  </IconButton>
                                )}
                                {permissions.delete && (
                                  <IconButton
                                    sx={{ m: -0.35 }}
                                    size="small"
                                    onClick={() => {
                                      setAlertDialogOpen(Math.random());
                                      setAlertDialogType("subcategory");
                                      setAlertDialogOnSubmit(() => async () => {
                                        return await handleRemoveSubcategory(
                                          subcategory
                                        );
                                      });
                                    }}
                                  >
                                    <Icon
                                      fontSize="small"
                                      sx={{ fontSize: "13px !important" }}
                                    >
                                      delete
                                    </Icon>
                                  </IconButton>
                                )}
                              </MDBox>
                            </MDBox>
                          }
                          variant="outlined"
                        />
                      );
                    })}
                  {category.subcategories.length == 0 && (
                    <MDTypography variant="inherit" color="inherit">
                      There are no subcategories
                    </MDTypography>
                  )}
                </Stack>
              ),
              actions: (
                <>
                  {permissions.update && (
                    <>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setDialogOpen(Math.random());
                          setDialogType("subcategory");
                          setDialogAction("create");
                          setCurrentCategory({ ...category });
                          setDialogOnSubmit(() => handleCreateSubcategory);
                        }}
                      >
                        <Icon>add</Icon>
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          setDialogOpen(Math.random());
                          setDialogType("category");
                          setDialogAction("edit");
                          setCurrentCategory({ ...category });
                          setDialogOnSubmit(() => handleEditCategory);
                        }}
                        size="small"
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    </>
                  )}
                  {permissions.delete && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setAlertDialogOpen(Math.random());
                        setAlertDialogType("category");
                        setAlertDialogOnSubmit(() => async () => {
                          return await handleRemoveCategory(category);
                        });
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  )}
                </>
              ),
            };
          })
        : [{ categories: "No categories were found." }]
    );
  }

  return (
    <>
      {
        <>
          <AlertDialog
            open={alertDialogOpen}
            setOpen={setAlertDialogOpen}
            title={"Are you sure?"}
            content={"Delete this " + alertDialogType + "?"}
            onOk={alertDialogOnSubmit}
          />
          <CategoryDialog
            isOpen={dialogOpen}
            type={dialogType}
            action={dialogAction}
            data={{
              category: { ...currentCategory },
              subcategory: { ...currentSubcategory },
            }}
            onSubmit={dialogOnSubmit}
          />

          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDTypography variant="h6" color="white">
                      Categories {permissions.get ? `(${rows?.length})` : ""}
                    </MDTypography>
                    {permissions.create && (
                      <MDButton
                        onClick={() => {
                          setDialogOpen(Math.random());
                          setDialogType("category");
                          setDialogAction("create");
                          setCurrentCategory({ description: "" });
                          setDialogOnSubmit(() => handleCreateCategory);
                        }}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;add new category
                      </MDButton>
                    )}
                  </MDBox>
                  {permissions.get ? (
                    <>
                      <MDBox p={2} mt={2}>
                        <TableSearch
                          internalLoading={!Boolean(categories)}
                          id="all-categories"
                          fullWidth
                          setData={setCategories}
                          onSearch={async (request, callback) => {
                            callback(
                              await categoriesService.getCategories(request)
                            );
                          }}
                          label={"Search categories and subcategories"}
                        />
                      </MDBox>
                      <MDBox>
                        <DataTable
                          table={{ columns: columns, rows: rows }}
                          loading={!Boolean(categories)}
                          isSorted={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      </MDBox>
                    </>
                  ) : (
                    <AccessDenied type="component" />
                  )}
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      }
    </>
  );
}
