/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import MDButton from "components/MDButton";
import { Skeleton } from "@mui/material";
import { useSearchParams } from "react-router-dom";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  canSort,
  manualSortFunction,
  isScrollable,
  noEndBorder,
  showEntriesPerPage,
  setCsvData,
  loading,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    {
      columns,
      data,
      manualSortBy: Boolean(manualSortFunction),
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  function exportToCsv() {
    return [
      table.columns
        .map((column) => column.Header)
        .filter((item) => item !== ""),
      ...table.rows.map((row) => {
        return table.columns
          .map((column) => {
            return row[column.accessor + "Csv"];
          })
          .filter((item) => item);
      }),
    ];
  }

  const exportToCsvMemoized = useMemo(exportToCsv, [table]);

  const prevTable = useRef();

  useEffect(() => {
    if (
      setCsvData &&
      (!prevTable.current ||
        JSON.stringify(prevTable.current) != JSON.stringify(table))
    ) {
      prevTable.current = table;
      if (setCsvData) setCsvData(exportToCsvMemoized);
    }
  }, [table, setCsvData]);

  // Setting the entries starting point
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {showEntriesPerPage && (
        <>
          {entriesPerPage || canSearch ? (
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              {entriesPerPage && (
                <MDBox display="flex" alignItems="center">
                  <Autocomplete
                    disableClearable
                    value={pageSize.toString()}
                    options={entries}
                    onChange={(event, newValue) => {
                      setEntriesPerPage(parseInt(newValue, 10));
                    }}
                    size="small"
                    sx={{ width: "5rem" }}
                    renderInput={(params) => <MDInput {...params} />}
                  />
                  <MDTypography variant="caption" color="secondary">
                    &nbsp;&nbsp;entries per page
                  </MDTypography>
                </MDBox>
              )}
              {canSearch && (
                <MDBox width="12rem" ml="auto">
                  <MDInput
                    placeholder="Search..."
                    value={search}
                    size="small"
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setSearch(search);
                      onSearchChange(currentTarget.value);
                    }}
                  />
                </MDBox>
              )}
            </MDBox>
          ) : null}
        </>
      )}

      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => {
                const props = column.getHeaderProps(
                  isSorted &&
                    canSort?.includes(column["Header"]) &&
                    column.getSortByToggleProps()
                );

                const onClick = props.onClick;

                if (manualSortFunction) {
                  props.onClick = (...params) => {
                    onClick(...params);
                    let direction = "asce";
                    if (
                      searchParams.get("sortBy")?.split("-")[0] == column.id
                    ) {
                      const paramsDirection = searchParams
                        .get("sortBy")
                        ?.split("-")[1];
                      direction =
                        paramsDirection == "asce"
                          ? "desc"
                          : paramsDirection == "desc"
                          ? "none"
                          : "asce";
                    }

                    setSearchParams((curr) => {
                      curr.set("sortBy", `${column.id}-${direction}`);
                      return curr;
                    });

                    manualSortFunction(column.id, direction);
                  };
                }

                let sorted = canSort?.includes(column["Header"])
                  ? setSortedValue(column)
                  : false;

                if (manualSortFunction) {
                  sorted =
                    searchParams.get("sortBy")?.split("-")[0] == column.id
                      ? searchParams.get("sortBy").split("-")[1]
                      : "none";
                }

                return (
                  <DataTableHeadCell
                    {...props}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sx={column?.headCellStyle || {}}
                    sorted={sorted}
                    isLastCell={
                      isScrollable && index === headerGroup.headers.length - 1
                    }
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                );
              })}
            </TableRow>
          ))}
        </MDBox>
        {!loading && (
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              const reactKey = row.original.key;
              prepareRow(row);
              if (row.original.hidden) return;
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, index) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      key={reactKey || "Key of cell: " + key}
                      align={cell.column.align ? cell.column.align : "left"}
                      width={cell?.column.width || "100%"}
                      {...cell.getCellProps()}
                      sx={{
                        ...cell.getCellProps().sx,
                        ...cell?.column?.bodyCellStyle,
                      }}
                      isLastCell={
                        isScrollable && index === row.cells.length - 1
                      }
                      hidden={row.original.hidden}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>

      {loading && (
        <Box
          sx={{ display: "flex", flexDirection: "column", padding: 2, gap: 2 }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row, key) => {
            return (
              <Skeleton
                key={key}
                sx={{
                  width: "100%",
                  height: "52px",
                  padding: 0,
                  margin: 0,
                  borderRadius: "8px",
                  mt: -1.25,
                  mb: -1.25,
                }}
              />
            );
          })}
        </Box>
      )}

      {!loading && (
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                Showing {entriesStart} to {entriesEnd} of {rows.length} entries
              </MDTypography>
            </MDBox>
          )}
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination item onClick={() => previousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{
                      type: "number",
                      min: 1,
                      max: customizedPageOptions.length,
                    }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={
                      (handleInputPagination, handleInputPaginationValue)
                    }
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination item onClick={() => nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
      )}
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 50000, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  showEntriesPerPage: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  showEntriesPerPage: PropTypes.bool,
};

export default DataTable;
