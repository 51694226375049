import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import AlertDialog from "components/AlertDialog";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useMemo, useState } from "react";
import CreatePillarDialog from "./CreatePillarDialog";

import categoriesService from "services/categories-service";
import kpisService from "services/kpis-service";
import pillarsService from "services/pillars-service";

import { Box } from "@mui/material";
import axios from "axios";
import AccessDenied from "components/AccessDenied";
import CollapsibleDataTable from "components/CollapsibleDataTable/index.v2";
import MDBadge from "components/MDBadge";
import TableSearch from "components/TableSearch";

export default function AllPillars({ permissions }) {
  const columns = [
    {
      Header: "description",
      accessor: "description",
      width: "100%",
      firstField: true,
    },
    {
      Header: "weight",
      accessor: "weight",
      width: 120,
    },
    {
      Header: "reference code",
      accessor: "referenceCode",
      align: "center",
      width: 150,
    },
    {
      Header: "category",
      accessor: "category",
      align: "center",
      width: "100%",
    },
  ];

  if (permissions?.create || permissions?.update || permissions?.delete) {
    columns.push({
      Header: "",
      accessor: "actions",
      align: "right",
      width: "100%",
    });
  }

  const [pillars, setPillars] = useState(null);

  const [openCreatePillar, setOpenCreatePillar] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [createChoice, setCreateChoice] = useState(null);

  const [loading, setLoading] = useState(true);

  const [categories, setCategories] = useState([]);
  const [valueTypes, setValueTypes] = useState([]);

  const [currentPillar, setCurrentPillar] = useState(null);

  async function fetch(cancelToken) {
    setLoading(true);
    const requests = [categoriesService.getCategories(null, cancelToken)];

    if (permissions.get) {
      requests.push(kpisService.getValueTypes(null, cancelToken));
      //requests.push(pillarsService.getPillars(null, cancelToken));
    }

    Promise.all(requests).then((values) => {
      setLoading(false);
      var [response, data] = values[0];
      if (response.ok) {
        setCategories(data);
      } else {
        console.log("Error while fetching categories: " + data.error);
      }

      if (permissions.get) {
        [response, data] = values[1];
        if (response.ok) {
          setValueTypes(data);
        } else {
          console.log("Error while fetching kpi value types: " + data.error);
        }

        /*[response, data] = values[2];
        if (response.ok) {
          setPillars(data);
        } else {
          console.log("Error while fetching pillars: " + data.error);
        }*/
      }
    });
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetch(source.token);
    return () => {
      source.cancel("Component unmounted");
    };
  }, []);

  async function handleAddPillarOrKpi(newItem) {
    if (newItem.isPillar) {
      const [response, data] = await pillarsService.createPillar({
        description: newItem.description,
        referenceCode: newItem.referenceCode,
        parentId: currentPillar?.id,
        subcategoryId: newItem.subcategoryId,
        subcategories: newItem.subcategories,
      });
      if (response.ok) {
        setPillars(data);
      } else {
        return { error: data.error };
      }
    } else {
      const [response, data] = await kpisService.createKpi({
        ...newItem,
      });
      if (response.ok) {
        setPillars(data);
      } else {
        return { error: data.error };
      }
    }

    setLoading(false);

    return { success: true };
  }

  async function handleDeletePillarOrKpi() {
    //setLoading(true);

    if (!currentPillar.isKpi) {
      const [response, data] = await pillarsService.removePillar(
        currentPillar.id
      );
      if (response.ok) {
        setPillars(data);
      } else {
        return { error: data.error };
      }
    } else {
      const [response, data] = await kpisService.removeKpi(currentPillar.id);
      if (response.ok) {
        setPillars(data);
      } else {
        return { error: data.error };
      }
    }

    return { success: true };
  }

  async function handleEditPillarOrKpi(newItem) {
    if (!currentPillar.isKpi) {
      const [response, data] = await pillarsService.updatePillar(newItem.id, {
        ...newItem,
      });
      if (response.ok) {
        setPillars(data);
      } else {
        return { error: data.error };
      }
    } else {
      const [response, data] = await kpisService.updateKpi(newItem.id, {
        ...newItem,
      });
      if (response.ok) {
        setPillars(data);
      } else {
        return { error: data.error };
      }
    }

    return { success: true };
  }

  const newRows = useMemo(() => {
    if (!pillars) return [];
    function generateRow(pillar, type = "pillar") {
      const isKpi = type == "kpi";
      let parentId = pillar.parentId ? type + pillar.parentId : null;
      if (type == "kpi") {
        parentId = pillar.pillarId ? type + pillar.pillarId : null;
      }
      if (isKpi) {
        return {
          id: "kpi" + pillar.id,
          parentId,
          firstField: (
            <MDTypography
              variant="inherit"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <MDBadge
                badgeContent="KPI"
                variant="gradient"
                color="info"
                container
              />
              &nbsp;&nbsp;
              {pillar.description}
            </MDTypography>
          ),
          weight: (
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item>
                <MDTypography variant="inherit">{pillar.weight}</MDTypography>
              </Grid>
            </Grid>
          ),
          referenceCode: (
            <MDTypography variant="inherit">
              {pillar.referenceCode}
            </MDTypography>
          ),

          actions: (
            <Grid container sx={{ alignItems: "center" }} wrap="nowrap">
              {permissions.update && (
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setCurrentPillar({ ...pillar, isKpi: true });
                      setOpenEditDialog(Math.random());
                    }}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </Grid>
              )}
              {permissions.delete && (
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setOpenAlert(Math.random());
                      setCurrentPillar({ ...pillar, isKpi: true });
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ),
          pillars: [],
        };
      } else {
        return {
          id: "pillar" + pillar.id,
          parentId,
          firstField: (
            <MDTypography
              variant="inherit"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <MDBadge
                badgeContent="PILLAR"
                variant="gradient"
                color="primary"
                container
              />
              &nbsp;&nbsp;
              {pillar.description}
            </MDTypography>
          ),
          weight: (
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item>
                <MDTypography variant="inherit">{pillar.weight}</MDTypography>
              </Grid>
            </Grid>
          ),
          referenceCode: (
            <MDTypography variant="inherit">
              {pillar.referenceCode}
            </MDTypography>
          ),
          category: (
            <>
              {!pillar.parentId && (
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                  {pillar.subcategories?.map((item, index) => {
                    return (
                      <Chip
                        size="small"
                        key={`${pillar.id} Cat Chip ${index}`}
                        label={item.description}
                        variant="outlined"
                      />
                    );
                  })}
                </Stack>
              )}
            </>
          ),

          actions: (
            <Grid container sx={{ alignItems: "center" }} wrap="nowrap">
              {permissions.create && pillar.pillars && (
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setCurrentPillar({ ...pillar });
                      setOpenCreatePillar(Math.random());

                      const isKpi = !pillar.pillars;
                      const isPillar = pillar.pillars;

                      const hasChildrenKpis = pillar.kpis?.length > 0;
                      const hasChildrenPillars = pillar.pillars?.length > 0;

                      if (isKpi) {
                        setCreateChoice(null);
                      } else if (isPillar) {
                        if (hasChildrenKpis && !hasChildrenPillars) {
                          setCreateChoice("kpi");
                        } else if (!hasChildrenKpis && hasChildrenPillars) {
                          setCreateChoice("pillar");
                        } else if (!hasChildrenKpis && !hasChildrenPillars) {
                          setCreateChoice(null);
                        } else if (hasChildrenKpis && hasChildrenPillars) {
                          setCreateChoice(null);
                        }
                      }
                    }}
                  >
                    <Icon>add</Icon>
                  </IconButton>
                </Grid>
              )}
              {permissions.update && (
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setCurrentPillar({ ...pillar });
                      setOpenEditDialog(Math.random());
                    }}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </Grid>
              )}
              {permissions.delete && (
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setOpenAlert(Math.random());
                      setCurrentPillar({ ...pillar, isKpi: false });
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ),
          pillars:
            pillar?.pillars?.length > 0
              ? pillar.pillars.map((p) => generateRow(p, "pillar"))
              : pillar.kpis.map((k) => generateRow(k, "kpi")),
        };
      }
    }
    return pillars?.map((pillar) => generateRow(pillar, "pillar"));
  }, [pillars]);

  return (
    <>
      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        title={"Delete?"}
        currentPillar
        content={"Are you sure you want to delete?"}
        onOk={async () => {
          await handleDeletePillarOrKpi();
        }}
        onCancel={() => {}}
      />
      <CreatePillarDialog
        isOpen={openCreatePillar}
        onSubmit={handleAddPillarOrKpi}
        data={{
          categories: categories,
          subcategoryId: currentPillar?.subcategoryId,
          subcategories: currentPillar?.subcategories,
          parentId: currentPillar?.id,
          pillarId: currentPillar?.id,
          valueTypeId: null,
          valueTypes: valueTypes,
          count: currentPillar?.count || pillars?.length,
          parent: currentPillar,
        }}
        title="Create"
        createChoice={createChoice}
      />
      <CreatePillarDialog
        isOpen={openEditDialog}
        onSubmit={handleEditPillarOrKpi}
        data={{
          categories: categories,
          subcategoryId: currentPillar?.subcategoryId,
          subcategories: currentPillar?.subcategories,
          parentId: currentPillar?.parentId,
          pillarId: currentPillar?.pillarId,
          valueTypeId: null,
          valueTypes: valueTypes,
          kpi: currentPillar?.isKpi ? currentPillar : null,
          pillar: !currentPillar?.isKpi ? currentPillar : null,
          count: currentPillar?.count || pillars?.length,
        }}
        title="Edit"
        type="edit"
        createChoice={currentPillar?.isKpi ? "kpi" : "pillar"}
      />
      {!loading && (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Pillars {permissions.get ? `(${pillars?.length || 0})` : ""}
                  </MDTypography>
                  {permissions.create && (
                    <MDButton
                      onClick={() => {
                        setCurrentPillar(null);
                        setOpenCreatePillar(Math.random());
                        setCreateChoice("pillar");
                      }}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;add new pillar
                    </MDButton>
                  )}
                </MDBox>
                {permissions.get ? (
                  <>
                    <MDBox p={2} mt={2}>
                      <TableSearch
                        id="all-pillars"
                        internalLoading={!Boolean(pillars)}
                        fullWidth
                        setData={setPillars}
                        onSearch={async (request, callback) => {
                          callback(await pillarsService.getPillars(request));
                        }}
                        label={"Search pillars"}
                      />
                    </MDBox>
                    <MDBox>
                      <CollapsibleDataTable
                        loading={!Boolean(pillars)}
                        isSorted={false}
                        showTotalEntries={false}
                        noEndBorder
                        table={{
                          columns,
                          rows: newRows,
                        }}
                        childrenField="pillars"
                      ></CollapsibleDataTable>
                    </MDBox>
                  </>
                ) : (
                  <AccessDenied type="component" />
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 150px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color={"info"} />
        </Box>
      )}
    </>
  );
}
