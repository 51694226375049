import HttpService from "./htttp.service";

class SubscriptionsService {
  getSubscriptions = async (payload, cancelToken) => {
    const endpoint = "subscriptions";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  getSubscription = async (query, cancelToken) => {
    const endpoint = "subscriptions/" + query;
    return await HttpService.get(endpoint, query, cancelToken);
  };

  createSubscription = async (payload, query) => {
    const endpoint = "subscriptions";
    return await HttpService.post(endpoint, payload, query);
  };

  updateSubscription = async (id, query, payload) => {
    const endpoint = "subscriptions/" + id;
    return await HttpService.put(endpoint, payload, query);
  };

  removeSubscription = async (id, query) => {
    const endpoint = "subscriptions/" + id;
    return await HttpService.delete(endpoint, query);
  };
}

export default new SubscriptionsService();
