import HttpService from "./htttp.service";

class AuditTemplatesService {
  getAuditTemplates = async (query, cancelToken) => {
    const endpoint = "audit-templates";
    return await HttpService.get(endpoint, query, cancelToken);
  };

  createAuditTemplate = async (payload) => {
    const endpoint = "audit-templates";
    return await HttpService.post(endpoint, payload);
  };

  updateAuditTemplate = async (id, payload) => {
    const endpoint = "audit-templates/" + id;
    return await HttpService.put(endpoint, payload);
  };

  removeAuditTemplate = async (id) => {
    const endpoint = "audit-templates/" + id;
    return await HttpService.delete(endpoint);
  };
}

export default new AuditTemplatesService();
