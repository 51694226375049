import HttpService from "./htttp.service";

class KpisService {
  getValueTypes = async (payload, cancelToken) => {
    const endpoint = "kpis/valueTypes";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  createKpi = async (payload) => {
    const endpoint = "kpis";
    return await HttpService.post(endpoint, payload);
  };

  removeKpi = async (payload) => {
    const endpoint = "kpis/" + payload;
    return await HttpService.delete(endpoint, payload);
  };

  updateKpi = async (query, payload) => {
    const endpoint = "kpis/" + query;
    return await HttpService.put(endpoint, payload);
  };
}

export default new KpisService();
