import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import LoadingButton from "components/LoadingButton";
import MSelect from "components/MSelect";
import MDSelect from "components/MDSelect";
import { Grid } from "@mui/material";

export default function ContactDialog({ isOpen, action, data, onSubmit }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState({
    user: { name: "", email: "" },
    contactPositionId: 0,
  });

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);
  }, [isOpen]);

  React.useEffect(() => {
    if (action == "create") {
      setValue({
        user: { name: "", email: "" },
        contactPositionId: data.positions[0].id,
      });
    } else {
      setValue(data?.contact);
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          const result = await onSubmit(value);
          if (result.success) {
            handleClose();
          }

          setLoading(false);
        },
      }}
    >
      <DialogTitle>
        {action == "edit" ? "Edit" : "Create"}
        {" contact"}
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}
      >
        <Grid container spacing={1} mt={-0.5}>
          <Grid item xs={12}>
            <MDInput
              autoFocus
              required
              margin="dense"
              id="username-input"
              name="r"
              label="Name"
              type="text"
              value={value.user?.name}
              onChange={(e) => {
                setValue({
                  ...value,
                  user: { ...value.user, name: e.target.value },
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              autoFocus
              required
              margin="dense"
              id="useremail-input"
              name="r"
              label="Email"
              type="email"
              value={value.user?.email}
              onChange={(e) => {
                setValue({
                  ...value,
                  user: { ...value.user, email: e.target.value },
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <MSelect
              items={data.positions.map((position) => {
                return {
                  value: position.id,
                  render: position.name,
                };
              })}
              id="contact position-select"
              value={value.contactPositionId}
              onChange={(e) => {
                setValue({ ...value, contactPositionId: e });
              }}
              label="Contact position"
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          {action == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
