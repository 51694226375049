import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import AddPhotoIcon from "@mui/icons-material/AddAPhoto";
import MDBox from "components/MDBox";
import { styled } from "@mui/material/styles";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import MDTypography from "components/MDTypography";
import BasicDialog from "components/BasicDialog";
import AlertDialog from "components/AlertDialog";
import { useState } from "react";
import { API_URL } from "config";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function ({ attachments, setAttachments, readOnly }) {
  const [currentFile, setCurrentFile] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState({
    content: "",
    title: "",
    onOk: () => {},
    onCancel: () => {},
  });

  function handleContactRemove(attachmentIndex) {
    setOpen(true);
    setDialog({
      content: "Are you sure you want to delete this attachment ?",
      title: "Delete kpi attachment ?",
      onOk: () => {
        const newAttachments = [...attachments];
        newAttachments.splice(attachmentIndex, 1);
        setAttachments(newAttachments);
      },
      onCancel: () => {},
    });
  }

  return (
    <>
      <BasicDialog
        title={"Attachment"}
        content={
          <img
            style={{
              width: "100%",
            }}
            src={currentFile?.url}
          />
        }
        cancelButton={true}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
      <AlertDialog open={open} setOpen={setOpen} {...dialog} />
      <MDBox
        display="flex"
        gap={1}
        style={{
          minWidth: "350px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {attachments?.length > 0 &&
          attachments.map((file, index) => (
            <Card
              sx={{ overflow: "hidden" }}
              key={`File: ${file?.name + file?.url + index}`}
            >
              <MDBox
                height="60px"
                width="60px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ position: "relative" }}
                onClick={() => {
                  setCurrentFile(file);
                  setDialogOpen(true);
                }}
              >
                {!readOnly && (
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "3px",
                      top: "3px",
                      color: "white !important",
                      backgroundColor: "rgba(0,0,0,.2)",
                      borderRadius: "50%",
                      padding: "0 !important",
                      cursor: "pointer",
                    }}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleContactRemove(index);
                    }}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                )}
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                    maxHeight: "100px",
                    maxWidth: "100px",
                  }}
                  src={file.url}
                />
              </MDBox>
            </Card>
          ))}
        {!readOnly && (
          <Card sx={{ overflow: "hidden" }} key={`add`}>
            <MDBox
              height="60px"
              width="60px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton size="large" role={undefined} component="label">
                <Icon>add</Icon>
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => {
                    const url = URL.createObjectURL(e.target.files[0]);
                    setAttachments([
                      ...attachments,
                      {
                        name: e.target.files[0].name,
                        url: url,
                        file: e.target.files[0],
                        type: e.target.files[0].type,
                      },
                    ]);
                  }}
                />
              </IconButton>
            </MDBox>
          </Card>
        )}
      </MDBox>
    </>
  );
}

function _Attachments({ attachments, setAttachments }) {
  const [currentFile, setCurrentFile] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <BasicDialog
        title={"Attachment"}
        content={
          <img
            style={{
              width: "100%",
            }}
            src={currentFile?.url}
          />
        }
        cancelButton={true}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
      <Card>
        <MDBox p={1} display="flex" flexDirection="column" gap={1}>
          <MDBox display="flex" flexDirection="row">
            <MDTypography variant="h6" fontWeight="regular">
              Attachments
            </MDTypography>
            <IconButton size="small" role={undefined} component="label">
              <Icon>add</Icon>
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => {
                  const url = URL.createObjectURL(e.target.files[0]);
                  setAttachments([
                    ...attachments,
                    {
                      name: e.target.files[0].name,
                      url: url,
                      file: e.target.files[0],
                      type: e.target.files[0].type,
                    },
                  ]);
                }}
              />
            </IconButton>
          </MDBox>
          <MDBox display="flex" gap={1}>
            {attachments?.length > 0 ? (
              attachments.map((file, index) => (
                <Card
                  sx={{ overflow: "hidden" }}
                  key={`File: ${file?.name || file?.url || index}`}
                >
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pl={1}
                    sx={{ textOverflow: "ellipsis" }}
                  >
                    <MDTypography
                      sx={{
                        width: 20,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      variant="inherit"
                      color="secondary"
                    >
                      {""}
                    </MDTypography>
                    <IconButton
                      size="small"
                      onClick={() => {
                        const newAttachments = [...attachments];
                        newAttachments.splice(index, 1);
                        setAttachments(newAttachments);
                      }}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </MDBox>

                  <MDBox
                    height="50px"
                    width="60px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => {
                      setCurrentFile(file);
                      setDialogOpen(true);
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        maxHeight: "100px",
                        maxWidth: "100px",
                      }}
                      src={file.url}
                    />
                    {/*file.type.startsWith("image/") ? (
                    <img
                      style={{
                        width: "100%",
                        maxHeight: "100px",
                        maxWidth: "100px",
                      }}
                      src={file.url}
                    />
                  ) : (
                    <object
                      data={file.url}
                      type={file.type}
                      style={{
                        width: "100%",
                        maxHeight: "100px",
                        maxWidth: "100px",
                      }}
                    ></object>
                    )*/}
                  </MDBox>
                </Card>
              ))
            ) : (
              <MDTypography variant="inherit" color="secondary">
                No attachments uploaded
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}
