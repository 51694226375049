import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import LoadingButton from "components/LoadingButton";
import * as React from "react";
import subscriptionsService from "services/subscriptions-service";
import SubscriptionItem from "./SubscriptionItem";
import MDTypography from "components/MDTypography";
import DropdownSelect from "components/DropdownSelect";
import { EXTERNAL } from "const";
import accountsService from "services/accounts-service";
import { Avatar, Step, StepLabel, Stepper } from "@mui/material";
import MDBox from "components/MDBox";

export default function SubscriptionDialog({
  isOpen,
  action,
  data,
  onSubmit,
  metadata,
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState({});
  const [error, setError] = React.useState(null);
  const [options, setOptions] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(0);

  const fetch = async () => {
    async function getSubscriptions(accountId) {
      const [response, subscriptions] =
        await subscriptionsService.getSubscriptions({
          type: data.type,
          ...(data.type === EXTERNAL
            ? { accountId }
            : { internal: true, accountId: metadata.accountId }),
        });
      if (response.ok) {
        setOptions((curr) => ({ ...curr, subscriptions }));
        setValue({ ...value, subscriptionId: subscriptions[0]?.id });
      }
    }

    async function getAccounts() {
      const [response, accounts] = await accountsService.getAccounts({
        only: "externalAuditors",
        raw: true,
      });

      if (response.ok) {
        setOptions((curr) => ({ ...curr, accounts }));
      }
    }

    const promises = [];
    if (activeStep.index === 0) {
      if (data.type === EXTERNAL) {
        promises.push(getAccounts());
      } else {
        promises.push(getSubscriptions());
      }
    } else {
      promises.push(getSubscriptions(value.externalAuditorId));
    }

    await Promise.all(promises);
  };

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);
    setError(null);

    //if (!options.subscriptions) {

    //}
  }, [isOpen]);

  React.useEffect(() => {
    fetch();
  }, [isOpen, data.type, activeStep]);

  React.useEffect(() => {
    if (action == "create") {
      setValue({});
    } else {
      setValue(data);
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const dropdownOptions = React.useMemo(() => {
    return {
      accounts:
        options?.accounts?.map((a) => ({
          id: a.id,
          label: a.name,
          url: a.logo,
        })) || [],
    };
  }, [options]);

  const steps = React.useMemo(() => {
    const steps = [];
    if (data.type === EXTERNAL) {
      steps.push({
        key: "select-auditor",
        label: "Select auditor",
        index: steps.length,
      });
    }

    steps.push({
      key: "select-subscription",
      label: "Select subscription",
      index: steps.length,
    });
    return steps;
  }, [data.type]);

  React.useEffect(() => {
    setActiveStep(steps[0]);
  }, [steps]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          if (activeStep.index === steps.length - 1) {
            console.log("Submitting: ", value);
            setLoading(true);
            const result = await onSubmit(value);
            if (result.success) {
              handleClose();
            } else {
              setError(result.error);
            }

            setLoading(false);
          } else {
            setActiveStep(steps[activeStep.index + 1]);
          }
        },
      }}
    >
      <DialogTitle sx={{ mt: -0.5 }}>
        <Stepper
          activeStep={activeStep?.index || 0}
          alternativeLabel
          size="small"
        >
          {steps.map((step, index) => {
            return (
              <Step key={step.key}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {data.type === EXTERNAL && activeStep.key === "select-auditor" && (
            <>
              <Grid item xs={12}>
                <MDTypography variant="h6">Choose an auditor</MDTypography>
              </Grid>
              <Grid item xs={12}>
                <DropdownSelect
                  required
                  label={"Auditor"}
                  initialValue={value.externalAuditorId}
                  onChange={(e) => {
                    setValue({ ...value, externalAuditorId: e?.id });
                  }}
                  items={dropdownOptions.accounts}
                  fullWidth
                  renderOption={(option) => (
                    <MDBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{ height: "30px", width: "30px" }}
                        src={option.url}
                      />
                      <MDTypography variant="body" sx={{ fontWeight: "400" }}>
                        {option.label}
                      </MDTypography>
                    </MDBox>
                  )}
                />
              </Grid>
            </>
          )}
          {activeStep.key === "select-subscription" && (
            <>
              <Grid item xs={12}>
                <MDTypography variant="h6">Choose a subscription</MDTypography>
              </Grid>

              <Grid item container spacing={1} xs={12}>
                {options.subscriptions?.map((subscription) => {
                  return (
                    <Grid
                      key={subscription.id}
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setValue({ ...value, subscriptionId: subscription.id });
                      }}
                    >
                      <SubscriptionItem
                        subscription={{ subscription }}
                        template
                        selected={value.subscriptionId == subscription.id}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {activeStep.index !== 0 && (
          <Button
            onClick={() => {
              setActiveStep(steps[activeStep?.index - 1]);
            }}
          >
            Back
          </Button>
        )}
        <LoadingButton
          loading={loading}
          type={"submit"}
          variant="gradient"
          color="info"
        >
          {activeStep?.index === steps.length - 1 ? "Subscribe" : "Next"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
