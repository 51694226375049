import {
  Avatar,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DropdownSelect from "components/DropdownSelect";
import LoadingButton from "components/LoadingButton";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import auditsService from "services/audits-service";
import usersService from "services/users-service";
import useLoading from "utils/useLoading";

export default function AuditInfoDialogv2({ open, setOpen, audit, onSubmit }) {
  const [updated, setUpdated] = useState({});
  const [options, setOptions] = useState({
    branches: [],
    users: [],
  });
  const { loading, changeLoading } = useLoading({
    branches: false,
    users: false,
  });

  async function handleFetchUsers() {
    changeLoading("users", true);

    const [response, data] = await usersService.getUsers();
    if (response.ok) {
      setOptions((options) => {
        return { ...options, users: data };
      });
    }
    changeLoading("users", false);
  }

  async function handleFetchStatuses() {
    changeLoading("statuses", true);

    const [response, data] = await auditsService.getStatuses();
    if (response.ok) {
      setOptions((options) => {
        return { ...options, statuses: data };
      });
    }
    changeLoading("statuses", false);
  }

  const dropdownOptions = useMemo(() => {
    const branches = [
      {
        label: audit.branch.name,
        id: audit.branch.id,
        branchGroup: audit.branch.branchGroup.name,
        branchGroupId: audit.branch.branchGroup.id,
        subcategoryId: audit.branch.subcategoryId,
      },
    ];
    /*options.branches?.forEach((branchGroup) => {
      branchGroup.branches?.forEach((branch) => {
        branches.push({
          label: branch.name,
          id: branch.id,
          branchGroup: branchGroup.name,
          branchGroupId: branchGroup.id,
          subcategoryId: branch.subcategoryId,
        });
      });
    });*/

    return {
      branches,
      users:
        options.users?.map((user) => ({
          id: user.id,
          label: user.name,
          profileImage: user.profileImage,
        })) || [],
      statuses:
        options.statuses?.map((status) => ({
          id: status.id,
          label: status.description,
        })) || [],
    };
  }, [options.branches, options.users]);

  useEffect(() => {
    handleFetchUsers();
    handleFetchStatuses();
  }, []);

  useEffect(() => {
    if (open && audit) setUpdated({});
  }, [open, audit]);

  return (
    <Dialog
      open={open}
      maxWidth={"lg"}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          changeLoading("submit", true);

          const result = await onSubmit(updated);
          if (result.success) {
            setOpen(false);
          }

          changeLoading("submit", false);
        },
      }}
    >
      <DialogTitle>Edit Audit</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} mt={0.5}>
          <Grid item xs={6}>
            <Tooltip title="You cannot change the branch after audit creation.">
              <DropdownSelect
                label={"Branch"}
                id="company-selector"
                initialValue={
                  audit.branchId == 0 || !audit.branchId ? "" : audit.branchId
                }
                items={dropdownOptions.branches}
                required={true}
                groupBy={(option) => option.branchGroup}
                fullWidth
                loading={loading.branches}
                readOnly={true}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <DropdownSelect
              label={"Assigned to"}
              id="assignedto-selector"
              initialValue={
                (updated.userId || audit.userId) == 0 ||
                !(updated.userId || audit.userId)
                  ? ""
                  : audit.userId
              }
              onChange={(newValue) => {
                setUpdated({ ...updated, userId: newValue?.id });
              }}
              items={dropdownOptions.users}
              required
              fullWidth
              loading={loading.users}
              renderOption={(option) => (
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{ height: "30px", width: "30px" }}
                    src={option.profileImage}
                  />
                  <MDTypography variant="body" sx={{ fontWeight: "400" }}>
                    {option.label}
                  </MDTypography>
                </MDBox>
              )}
            />
          </Grid>
          <Grid item xs>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                id="date-datetimepicker"
                label="Date"
                required={true}
                sx={{ width: "100%" }}
                value={dayjs(new Date(updated.date || audit.date))}
                onChange={(e) => {
                  setUpdated({ ...updated, date: e?.$d });
                }}
                slotProps={{
                  textField: {
                    required: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item display={"flex"} justifyContent={"end"}>
            <ButtonGroup>
              <MDButton
                id="audit-auditTypeId-0"
                variant={
                  (updated.auditTypeId || audit.auditTypeId) !== 1
                    ? "gradient"
                    : "outlined"
                }
                color={
                  (updated.auditTypeId || audit.auditTypeId) !== 1
                    ? "info"
                    : "secondary"
                }
                size="medium"
                sx={
                  (updated.auditTypeId || audit.auditTypeId) !== 1
                    ? (theme) => ({ borderColor: theme.borders.borderColor })
                    : null
                }
                onClick={() => {
                  setUpdated({ ...updated, auditTypeId: 0 });
                }}
              >
                Normal
              </MDButton>
              <MDButton
                id="audit-auditTypeId-1"
                variant={
                  (updated.auditTypeId || audit.auditTypeId) === 1
                    ? "gradient"
                    : "outlined"
                }
                color={
                  (updated.auditTypeId || audit.auditTypeId) === 1
                    ? "info"
                    : "secondary"
                }
                size="medium"
                sx={
                  (updated.auditTypeId || audit.auditTypeId) === 1
                    ? (theme) => ({ borderColor: theme.borders.borderColor })
                    : null
                }
                onClick={() => {
                  setUpdated({ ...updated, auditTypeId: 1 });
                }}
              >
                Scoreless
              </MDButton>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <DropdownSelect
              label={"Status"}
              id="status-selector"
              initialValue={
                (updated.statusId || audit.statusId) == 0 ||
                !(updated.statusId || audit.statusId)
                  ? ""
                  : audit.statusId
              }
              onChange={(newValue) => {
                setUpdated({ ...updated, statusId: newValue?.id });
              }}
              items={dropdownOptions.statuses}
              required={true}
              fullWidth
              loading={loading.statuses}
            />
          </Grid>

          <Grid item xs={12}>
            <MDInput
              id="audit-description"
              multiline
              rows={4}
              label="Description"
              value={updated.description || audit.description || ""}
              onChange={(e) => {
                setUpdated({ ...updated, description: e.target.value });
              }}
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          justifyContent: "end",
        }}
      >
        <MDButton
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </MDButton>
        <LoadingButton
          loading={loading.submit}
          type="submit"
          variant="gradient"
          color="info"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
