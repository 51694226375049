import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import LoadingButton from "components/LoadingButton";
import MDButton from "components/MDButton";
import PillarsTable from "components/PillarsTable";
import { useEffect, useMemo, useState } from "react";
import pillarsService from "services/pillars-service";
import useLoading from "utils/useLoading";

export default function AuditKpisDialog({ open, setOpen, audit, onSubmit }) {
  const [pillars, setPillars] = useState([]);
  const [selected, setSelected] = useState([]);
  const { loading, changeLoading } = useLoading({ submit: false });

  async function handleFetchPillars() {
    const [response, data] = await pillarsService.getPillars({
      subcategoryId: audit.branch.subcategoryId,
    });
    if (response.ok) {
      setPillars(data);
    }
  }

  function handleChangeSelected(newSelected) {
    setSelected(newSelected);
  }

  async function handleSubmit() {
    changeLoading("submit", true);
    const response = await onSubmit(selected);
    if (response.success) {
      setOpen(false);
    }

    changeLoading("submit", false);
  }

  const initial = useMemo(() => {
    const newSelected = [];
    audit.auditKpis.forEach((auditKpi) => {
      newSelected.push({
        kpiId: auditKpi.kpiId,
        description: auditKpi.description,
        id: auditKpi.id,
      });
    });

    return newSelected;
  }, [audit.auditKpis]);

  useEffect(() => {
    if (open && audit.branch.subcategoryId) {
      handleFetchPillars();
    }
  }, [audit.branch.subcategoryId, open]);

  useEffect(() => {
    if (audit.auditKpis && pillars) {
      const newSelected = [];
      audit.auditKpis.forEach((auditKpi) => {
        newSelected.push({
          kpiId: auditKpi.kpiId,
          description: auditKpi.description,
          id: auditKpi.id,
        });
      });

      setSelected(newSelected);
    }
  }, [audit.auditKpis, pillars]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>Edit Audit Kpis</DialogTitle>
      <DialogContent>
        <PillarsTable
          initial={initial}
          pillars={pillars}
          updatableDescription
          onChangeSelected={handleChangeSelected}
          defaultExpanded
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          justifyContent: "end",
        }}
      >
        <MDButton
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </MDButton>
        <LoadingButton
          loading={loading.submit}
          onClick={handleSubmit}
          variant="gradient"
          color="info"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
