import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import LoadingButton from "components/LoadingButton";

export default function AccountsEditNewDialog({
  isOpen,
  onSubmit,
  data,
  type,
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [account, setAccount] = React.useState({
    name: "",
    description: "",
  });

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);
    setAccount({
      name: "",
      description: "",
    });
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      if (type == "edit") {
        setAccount({ ...data });
      } else {
        setAccount({
          name: "",
          description: "",
          parentId: data.id,
        });
      }
    }
  }, [data, type]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          const result = await onSubmit(account);
          if (result.success) {
            handleClose();
          }

          setLoading(false);
        },
      }}
    >
      <DialogTitle>
        {type == "edit" ? "Edit" : "Create new"} account
      </DialogTitle>
      <DialogContent>
        <MDInput
          autoFocus
          required
          margin="dense"
          id="name"
          name="name"
          label="Name"
          type="text"
          value={account.name}
          onChange={(e) => {
            setAccount({ ...account, name: e.target.value });
          }}
          fullWidth
        />
        <MDInput
          autoFocus
          required
          margin="dense"
          id="description"
          name="description"
          label="Description"
          type="text"
          value={account.description}
          onChange={(e) => {
            setAccount({ ...account, description: e.target.value });
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          {type == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
