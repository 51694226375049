import { DEV_ANSWER_TYPE_ID } from "../../../../const";

import green from "assets/images/green.png";
import orange from "assets/images/orange.png";
import red from "assets/images/red.png";
import yellow from "assets/images/yellow.png";
import yellowgreen from "assets/images/yellowgreen.png";

const greenColor = "green";
const yellowGreenColor = "yellowgreen";
const yellowColor = "yellow";
const orangeColor = "orange";
const redColor = "red";

export function calculateStatusTime(logs) {
  const statusTimes = {};

  for (let i = 0; i < logs.length; i++) {
    const previousLog = i - 1 < 0 ? null : logs[i - 1];
    const currentLog = logs[i];

    if (previousLog) {
      let duration =
        (new Date(currentLog.time) - new Date(previousLog.time)) / 1000;
      duration += statusTimes[previousLog.statusId]?.duration || 0;
      statusTimes[previousLog.statusId] = {
        duration,
        formattedDuration: formatDuration(duration),
      };
    }
  }

  return statusTimes;
}

export function formatDuration(duration) {
  let seconds = Math.floor(duration);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  return (
    (days != 0 ? days + " days, " : "") +
    (days == 0 && hours == 0 ? "" : (hours % 24) + " hours, ") +
    (days == 0 && hours == 0 && minutes == 0
      ? ""
      : (minutes % 60) + " minutes, ") +
    (seconds % 60) +
    " seconds."
  );
}

export const devPillar = (pillars) => {
  const filteredPillars = [];
  for (let index = 0; index < pillars.length; index++) {
    const devKpis = pillars[index].kpis.filter(
      (kpi) => kpi.auditKpis[0].kpiValueTypeAnswerId === DEV_ANSWER_TYPE_ID
    );
    if (devKpis.length >= 1) {
      filteredPillars.push({
        ...pillars[index],
        kpis: devKpis,
      });
    }
  }
  return filteredPillars;
};

export const perfs = [
  { title: "Unsatisfactory", value: "40", color: "red" },
  { title: "Needs Improvement", value: "60", color: "orange" },
  { title: "Meets Requirements", value: "80", color: "yellow" },
  {
    title: "Exceeds Requirements",
    value: "90",
    color: "yellowgreen",
  },
  { title: "Outstanding", value: "100", color: "green" },
];

export const percentage = (percent, total) => {
  return `${((percent / total) * 100).toFixed(2)} %`;
};

export const percentageScore = (percent, total) => {
  return ((percent / total) * 100).toFixed(2);
};

export const formatDateFromISOString = (isoDateString) => {
  const date = new Date(isoDateString);
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatDateTimeFromISOString = (isoDateString) => {
  const date = new Date(isoDateString);
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0"); // Add leading zero if necessary
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Add leading zero if necessary
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const flagColor = (rating) => {
  if (rating < 40) return red;
  if (rating >= 40 && rating < 60) return orange;
  if (rating >= 60 && rating < 80) return yellow;
  if (rating >= 80 && rating < 90) return yellowgreen;
  if (rating >= 90) return green;
};
export const flagColorBasedOnString = (string) => {
  if (string === "red") return red;
  if (string === "orange") return orange;
  if (string === "yellow") return yellow;
  if (string === "yellowgreen") return yellowgreen;
  if (string === "green") return green;
};
export const flagHexColor = (value) => {
  if (value < 40) return redColor;
  else if (value < 60) return orangeColor;
  else if (value < 80) return yellowColor;
  else if (value < 90) return yellowGreenColor;
  else return greenColor;
};

export const colors = {
  green: greenColor,
  orange: orangeColor,
  yellow: yellowColor,
  yellowgreen: yellowGreenColor,
  red: redColor,
};

export function transformToTime(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Build the time string
  let timeString = "";
  if (hours !== 0) {
    timeString += `${hours} hour${hours !== 1 ? "s" : ""}`;
  }
  if (minutes !== 0) {
    timeString += ` and ${minutes} minute${minutes !== 1 ? "s" : ""}`;
  }

  return timeString || "0 minutes"; // Return '0 minutes' if the time is zero
}

export function findSmallestDate(dateString1, dateString2) {
  // Check if both input strings are null or undefined
  if (
    (!dateString1 || isNaN(new Date(dateString1))) &&
    (!dateString2 || isNaN(new Date(dateString2)))
  ) {
    return null;
  } else if (!dateString1 || isNaN(new Date(dateString1))) {
    return dateString2;
  } else if (!dateString2 || isNaN(new Date(dateString2))) {
    return dateString1;
  }

  // Both dates are valid, compare them and return the smallest one
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  if (date1 < date2) {
    return dateString1;
  } else if (date1 > date2) {
    return dateString2;
  } else {
    return dateString1;
  }
}
