import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import NotificationItem from "examples/Items/NotificationItem";
import { useNavigate } from "react-router-dom";

import notificationsService from "services/notifications-service";
import { MenuItem } from "@mui/material";

export default function NotificationElement({
  notification,
  setNotifications,
  handleCloseMenu,
}) {
  const navigate = useNavigate();

  async function handleMarkNotificationAsRead() {
    setNotifications((notifications) => {
      return notifications.filter((n) => {
        return n.id != notification.id;
      });
    });

    const [response, data] = await notificationsService.markNotificationAsRead(
      notification.id
    );

    if (response.ok) {
      //setNotifications(data.notifications);
    } else {
      console.log(`ERROR while marking notification as read!: ${data.error}`);
    }
  }

  return (
    <MenuItem
      sx={{
        cursor: "pointer",

        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        borderRadius: 0,
        marginRight: -1,
        marginLeft: -1,
      }}
      onClick={() => {
        navigate(notification.url);
        handleCloseMenu();
        handleMarkNotificationAsRead();
      }}
    >
      <MDTypography sx={{ fontSize: "1rem" }}>
        {notification.description}
      </MDTypography>
      <MDTypography sx={{ fontSize: "0.8rem", opacity: 0.5 }}>
        {timeAgo(new Date(notification.createdAt))}
      </MDTypography>
      {/*<NotificationItem
        onClick={() => {
          navigate(notification.url);
          handleCloseMenu();
          handleMarkNotificationAsRead();
        }}
        icon={<Icon>email</Icon>}
        title={notification.description}
      />*/}
    </MenuItem>
  );
}

function timeAgo(date) {
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // If less than 5 minutes ago, show "X minutes ago"
  if (minutes < 5) {
    return minutes <= 1 ? "just now" : `${minutes} minutes ago`;
  }

  // If today, show "Today, hh:mm AM/PM"
  if (days === 0) {
    return `Today, ${formatTime(date)}`;
  }

  // If yesterday, show "Yesterday, hh:mm AM/PM"
  if (days === 1) {
    return `Yesterday, ${formatTime(date)}`;
  }

  // If earlier than yesterday and within the same year, show "dd MMM, hh:mm AM/PM"
  if (now.getFullYear() === date.getFullYear()) {
    return `${formatDate(date, false)}, ${formatTime(date)}`;
  }

  // If in a previous year, show "dd MMM yyyy, hh:mm AM/PM"
  return `${formatDate(date, true)}, ${formatTime(date)}`;
}

function formatTime(date) {
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format
  return `${hours}:${minutes} ${ampm}`;
}

function formatDate(date, includeYear) {
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("default", { month: "short" });
  const year = includeYear ? ` ${date.getFullYear()}` : "";
  return `${day} ${month}${year}`;
}
