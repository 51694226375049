import HttpService from "./htttp.service";

class BranchesService {
  getBranches = async (payload, cancelToken) => {
    const endpoint = "branches";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  getBranch = async (payload, cancelToken) => {
    const endpoint = "branches/" + payload;
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  getContactsOfBranch = async (payload) => {
    const endpoint = "branches/" + payload + "/contacts";
    return await HttpService.get(endpoint, payload);
  };

  getBranchGroups = async (payload, cancelToken) => {
    const endpoint = "/branches/groups";
    return await HttpService.get(endpoint, payload, cancelToken);
  };

  createBranch = async (payload) => {
    const endpoint = "branches";
    const { logo, ...branch } = payload;
    if (typeof logo == "string") {
      return await HttpService.uploadFilesWithData(
        endpoint,
        [],
        payload,
        "post"
      );
    }

    return await HttpService.uploadFilesWithData(
      endpoint,
      [logo],
      branch,
      "post"
    );
  };

  updateBranch = async (query, payload) => {
    const endpoint = "branches/" + query;
    const { logo, ...branch } = payload;
    if (typeof logo == "string") {
      return await HttpService.uploadFilesWithData(
        endpoint,
        [],
        payload,
        "put"
      );
    }
    return await HttpService.uploadFilesWithData(
      endpoint,
      [logo],
      branch,
      "put"
    );
  };

  createBranchGroup = async (query, payload) => {
    const endpoint = "branches/groups";
    const { logo, ...branchGroup } = payload;
    return await HttpService.uploadFilesWithData(
      endpoint,
      [logo],
      branchGroup,
      "post",
      query
    );
    /*const endpoint = "branches/groups";
    return await HttpService.post(endpoint, payload);*/
  };

  updateBranchGroup = async (id, query, payload) => {
    const endpoint = "branches/groups/" + id;
    const { logo, ...branchGroup } = payload;
    if (typeof logo == "string") {
      return await HttpService.uploadFilesWithData(
        endpoint,
        [],
        payload,
        "put",
        query
      );
    }
    return await HttpService.uploadFilesWithData(
      endpoint,
      [logo],
      branchGroup,
      "put",
      query
    );

    /*const endpoint = "branches/groups/" + query;
    return await HttpService.put(endpoint, payload);*/
  };

  removeBranch = async (id, query) => {
    const endpoint = "branches/" + id;
    return await HttpService.delete(endpoint, query);
  };

  removeBranchGroup = async (id, query) => {
    const endpoint = "branches/groups/" + id;
    return await HttpService.delete(endpoint, query);
  };

  getConsolidatedReportLink = async (query, payload) => {
    const endpoint = `/branches/groups/${query.branchGroupId}/report/consolidated/link`;
    return await HttpService.post(endpoint, payload, query, null);
  };
}

export default new BranchesService();
