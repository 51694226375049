import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import LoadingButton from "components/LoadingButton";
import { Grid } from "@mui/material";
import DropdownSelect from "components/DropdownSelect";

export default function RegionsDialog({ isOpen, onSubmit, data, type }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [region, setRegion] = React.useState({
    description: "",
    territoryId: null,
  });

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);
    setRegion({
      description: "",
      territoryId: null,
    });
  }, [isOpen]);

  React.useEffect(() => {
    setRegion({ ...data.region });
  }, [data.region]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          const result = await onSubmit(region);
          if (result.success) {
            handleClose();
          }

          setLoading(false);
        },
      }}
    >
      <DialogTitle>{type == "edit" ? "Edit" : "Create new"} region</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: -0.5 }}>
          <Grid item xs={12}>
            <MDInput
              autoFocus
              required
              margin="dense"
              id="description"
              name="description"
              label="Description"
              type="text"
              value={region.description}
              onChange={(e) => {
                setRegion({
                  ...region,
                  description: e.target.value,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <DropdownSelect
              required
              label={"Territory"}
              initialValue={region?.territoryId || ""}
              onChange={(newValue) => {
                setRegion({ ...region, territoryId: newValue?.id || null });
              }}
              items={
                data?.territories?.map((c) => ({ id: c.id, label: c.name })) ||
                []
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          {type == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
