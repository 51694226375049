export function debounce(func, wait) {
  let timeout;

  return function (...args) {
    const context = this;

    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export function debounceAsync(func, wait) {
  let timeout;
  let promiseInProgress = null;

  return function (...args) {
    const context = this;

    clearTimeout(timeout);

    return new Promise((resolve, reject) => {
      timeout = setTimeout(async () => {
        if (promiseInProgress) {
          await promiseInProgress;
        }

        try {
          promiseInProgress = func.apply(context, args);
          const result = await promiseInProgress;
          resolve(result);
        } catch (error) {
          reject(error);
        } finally {
          promiseInProgress = null;
        }
      }, wait);
    });
  };
}
