import { Card, Grid } from "@mui/material";
import Step from "./Step";
import React, { useEffect, useState } from "react";

export default function Stepper({ steps, activeStep }) {
  const [states, setStates] = useState([]);

  useEffect(() => {
    setStates(
      steps.map((step, index) => {
        if (index < activeStep) return "Complete";
        else if (activeStep == index) return "Current";
        else return "Not Started";
      })
    );
  }, [steps, activeStep]);

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: "16px",
        overflow: "hidden",
      }}
    >
      <Grid container spacing={0} sx={{ width: "100%", height: "100%" }}>
        {steps.map((step, index) => (
          <React.Fragment key={step.label}>
            <Step
              step={step}
              end={index === steps.length - 1}
              start={index === 0}
              state={states[index]}
            />
          </React.Fragment>
        ))}
      </Grid>
    </Card>
  );
}
