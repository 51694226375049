import * as React from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider } from "@mui/material";

function Row({ index, name, extra, table, showCollapsibleContent }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {index != 0 ? (
        <Divider
          sx={{
            margin: "0 auto",
            width: "calc(100% - 12px)",
            backgroundColor: "rgba(52, 71, 103, 0.4)",
            backgroundImage: "unset",
          }}
        />
      ) : null}
      <MDBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={1}
        sx={!showCollapsibleContent ? { paddingLeft: "10px" } : null}
      >
        {showCollapsibleContent && (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )}
        <MDTypography
          variant="h6"
          sx={{ width: "100%", cursor: "pointer" }}
          onClick={() => setOpen(!open)}
        >
          {name}
        </MDTypography>
        {extra && extra}
      </MDBox>

      {showCollapsibleContent && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <DataTable
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
            table={table}
          />
        </Collapse>
      )}
    </>
  );
}

export default function CollapsibleTable({
  name,
  rows,
  showCollapsibleContent,
  noCard,
}) {
  return (
    <Card sx={noCard ? { all: "unset !important" } : null}>
      {name && (
        <MDBox p={2}>
          <MDTypography>{name}</MDTypography>
        </MDBox>
      )}
      <MDBox p={1} display="flex" flexDirection="column" gap={1}>
        {rows.map((row, index) => (
          <Row
            index={index}
            key={index}
            name={row.name}
            table={row.table}
            extra={row.extra}
            showCollapsibleContent={showCollapsibleContent}
          />
        ))}
      </MDBox>
    </Card>
  );
}
