import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";

export default function BasicPopover({ content, button, ...rest }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [id, setId] = React.useState(`Popover ${Math.random()}`);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {button && (
        <div aria-describedby={id} onClick={handleClick}>
          {button}
        </div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        {...rest}
      >
        <MDBox
          sx={{
            backgroundColor: "white",
            boxShadow: "none",
            borderRadius: "6px",
          }}
          display="flex"
          flexDirection="column"
          gap={2}
          bgColor="white"
          p={2}
          m={-1}
        >
          {content}
        </MDBox>
      </Popover>
    </div>
  );
}
