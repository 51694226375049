import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSearch from "components/MDSearch";
import MDAvatar from "components/MDAvatar";
import MDSelect from "components/MDSelect";
import MDGroupedSelect from "components/MDGroupedSelect";
import MSelect from "components/MSelect";
import Avatar from "components/FileAvatar";
import { Avatar as MuiAvatar } from "@mui/material";

import {
  CreateBranchGroupDialog,
  CreateColorPaletteDialog,
  CreateContactPositionDialog,
  CreateSubcategoryDialog,
} from "components/Dialogs";

import Grid from "@mui/material/Grid";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import TextField from "@mui/material/TextField";

import AlertDialog from "components/AlertDialog";

import branchesService from "services/branches-service";
import usersService from "services/users-service";
import positionsService from "services/positions-service";
import UsersAutocomplete from "components/UsersAutocomplete";
import categoriesService from "services/categories-service";
import themeService from "services/theme-service";
import useWindowDimensions from "services/useWindowDimensions";
import ContactDialog from "./ContactDialog";
import regionsService from "services/regions-service";
import axios from "axios";
import AccessDenied from "components/AccessDenied";
import LoadingButton from "components/LoadingButton";
import DropdownSelect from "components/DropdownSelect";
import toast from "react-hot-toast";

function Branch({ action, setRoute, permissions }) {
  const [branch, setBranch] = useState(null);

  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState({
    content: "",
    title: "",
    onOk: () => {},
    onCancel: () => {},
  });

  const [savedBranch, setSavedBranch] = useState({});

  const [branchGroups, setBranchGroups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [positions, setPositions] = useState([]);
  const [colorPalettes, setColorPalettes] = useState([]);
  const [accountManagers, setAccountManagers] = useState([]);
  const [regions, setRegions] = useState([]);
  const [logoFile, setLogoFile] = useState(null);

  useWindowDimensions(({ width, height }) => {
    if (width < 450) {
      setSize({ description: "minuscule", xs: 12 });
    } else if (width < 600) {
      setSize({ description: "small", xs: 6 });
    } else if (width < 900) {
      setSize({ description: "medium", xs: 4 });
    } else {
      setSize({ description: "large", xs: 3 });
    }
  });

  const [size, setSize] = useState("small");

  const [createBranchGroupOpen, setCreateBranchGroupOpen] = useState(null);
  const [createContactPositionOpen, setCreateContactPositionOpen] =
    useState(null);
  const [createSubcategoryOpen, setCreateSubcategoryOpen] = useState(null);
  const [createColorPaletteOpen, setCreateColorPaletteOpen] = useState(null);

  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [contactDialogAction, setContactDialogAction] = useState("create");
  const [currentContact, setCurrentContact] = useState(null);

  const [loadingButton, setLoadingButton] = useState(false);

  const { id } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [value, setValue] = useState(null);
  const [fetching, setFetching] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetch(source.token);
    return () => {
      source.cancel("Component unmounted");
    };
  }, [action]);

  useEffect(() => {
    if (savedBranch && setRoute) {
      setRoute(["branches", savedBranch.name]);
    }
  }, [savedBranch]);

  async function fetch(cancelToken) {
    if (fetching) return;
    setFetching(true);

    const fetchSequence = [
      branchesService.getBranchGroups(null, cancelToken),
      categoriesService.getCategories(null, cancelToken),
      positionsService.getPositions(null, cancelToken),
      themeService.getTheme(null, cancelToken),
      regionsService.getRegions(null, cancelToken),
      usersService.getUsers(null),
    ];

    if (action == "edit") {
      fetchSequence.push(branchesService.getBranch(id, cancelToken));
    }

    Promise.all(fetchSequence).then((values) => {
      let branchGroup,
        branchGroupValue,
        category,
        colorPalette,
        region,
        accountManager;
      let [response, data] = values[0];
      if (response.ok) {
        setBranchGroups(data);
        if (!query?.get("branchGroup")) {
          branchGroup = data[0]?.id;
        } else {
          branchGroup = query.get("branchGroup");
        }
        branchGroupValue = data.find((v) => v.id == branchGroup);
      } else {
        console.log("Error while fetching branch groups: " + data.error);
      }

      [response, data] = values[1];
      if (response.ok) {
        setCategories(data);
        category = data[0]?.subcategories[0]?.id; // data[0]?.id;
      } else {
        console.log("Error while fetching categories: " + data.error);
      }

      [response, data] = values[2];
      if (response.ok) {
        setPositions(data);
      } else {
        console.log("Error while fetching positions: " + data.error);
      }

      [response, data] = values[3];
      if (response.ok) {
        setColorPalettes(data);
        colorPalette = data[0]?.id;
      } else {
        console.log("Error while fetching theme: " + data.error);
      }

      [response, data] = values[4];
      if (response.ok) {
        setRegions(data);
        region = data[0]?.id;
      } else {
        console.log("Error while fetching regions: " + data.error);
      }

      [response, data] = values[5];
      if (response.ok) {
        setAccountManagers(data);
        accountManager = data[0]?.id;
      } else {
        console.log("Error while fetching regions: " + data.error);
      }

      if (action == "edit") {
        [response, data] = values[6];
        if (response.ok) {
          const newBranch = getNewObject({
            ...data,
          });
          for (var i = 0; i < newBranch.contacts.length; i++) {
            newBranch.contacts[i].user = {
              name: newBranch.contacts[i].name,
              email: newBranch.contacts[i].email,
              profileImage: newBranch.contacts[i].profileImage,
            };
          }
          setBranch(
            getNewObject({
              ...newBranch,
            })
          );
          setSavedBranch(getNewObject({ ...newBranch }));
        } else {
          console.log("Error while fetching branch: " + data.error);
        }
      } else {
        setBranch({
          name: "",
          trademark: "",
          subcategoryId: category,
          colorPaletteId: branchGroupValue.colorPaletteId,
          logo: branchGroupValue.logo,
          email: branchGroupValue.email,
          phone: branchGroupValue.phone,
          address: "",
          branchGroupId: branchGroup,
          contacts: [],
          regionId: region,
          accountManagerId: branchGroupValue.accountManagerId,
        });
      }
    });

    setFetching(false);
  }

  function getNewObject(object) {
    return { ...object };
    //return JSON.parse(JSON.stringify(object));
  }

  function handleAccountManagerChange(value) {
    setBranch(getNewObject({ ...branch, accountManagerId: value }));
  }

  function handleBranchGroupChange(value) {
    setBranch(getNewObject({ ...branch, branchGroupId: value }));
  }

  function handleNameChange(e) {
    setBranch(getNewObject({ ...branch, name: e.target.value }));
  }

  function handleTrademarkChange(e) {
    setBranch(getNewObject({ ...branch, trademark: e.target.value }));
  }

  const handleLogoChange = (file) => {
    setBranch(getNewObject({ ...branch, logo: file ? file : "reset" }));
  };

  function handleEmailChange(e) {
    setBranch(getNewObject({ ...branch, email: e.target.value }));
  }

  function handlePhoneChange(e) {
    setBranch(getNewObject({ ...branch, phone: e.target.value }));
  }

  function handleAddressChange(e) {
    setBranch(getNewObject({ ...branch, address: e.target.value }));
  }

  function handleRegionChange(value) {
    setBranch(getNewObject({ ...branch, regionId: Number(value) }));
  }

  function handleColorPaletteChange(value) {
    setBranch(getNewObject({ ...branch, colorPaletteId: value }));
  }

  function handleCategoryChange(value) {
    setBranch(getNewObject({ ...branch, subcategoryId: Number(value) }));
  }

  function handleContactAdd(newContact) {
    setBranch(
      getNewObject({
        ...branch,
        contacts: [
          ...branch.contacts,
          {
            user: {
              name: newContact.user.name,
              email: newContact.user.email,
              profileImage: "",
            },
            contactPosition: getContactPositionById(
              newContact.contactPositionId
            ),
          },
        ],
      })
    );
  }

  function getContactPositionById(id) {
    for (var i = 0; i < positions.length; i++) {
      if (positions[i].id == id) {
        return { ...positions[i] };
      }
    }

    console.log("ERROR: Contact position with id " + id + " was not found.");
    return null;
  }

  function handleContactUpdate(newContact) {
    const newContacts = [...branch.contacts];
    newContacts[newContact.index] = {
      user: {
        name: newContact.user.name,
        email: newContact.user.email,
        profileImage: "",
      },
      contactPosition: getContactPositionById(newContact.contactPositionId),
    };
    setBranch(
      getNewObject({
        ...branch,
        contacts: newContacts,
      })
    );
  }

  function handleContactRemove(contactUserIndex) {
    setOpen(true);
    setDialog({
      content: "Are you sure you want to delete this contact ?",
      title: "Delete branch contact ?",
      onOk: () => {
        const newContacts = [];
        for (var i = 0; i < branch.contacts.length; i++) {
          if (i != contactUserIndex) {
            newContacts.push(branch.contacts[i]);
          }
        }
        setBranch(getNewObject({ ...branch, contacts: [...newContacts] }));
      },
      onCancel: () => {},
    });
  }

  function handleContactPositionChange(newPositionId, contactIndex) {
    const newContacts = [...branch.contacts];

    var newPosition;
    for (var i = 0; i < positions.length; i++) {
      if (positions[i].id == newPositionId) {
        newPosition = { ...positions[i] };
      }
    }
    newContacts[contactIndex].contactPosition = newPosition;

    setBranch(getNewObject({ ...branch, contacts: newContacts }));
  }

  async function handleSubmission(e) {
    setLoadingButton(true);
    if (action == "new") {
      e.preventDefault();

      const promise = branchesService.createBranch(branch);

      toast.promise(promise, {
        position: "bottom-right",
        loading: "Creating branch",
        success: "Created branch successfully",
        error: "Branch was not created!",
        duration: 5,
      });

      const [response, data] = await promise;
      if (response.ok) {
        setBranch(data);
        navigate("/branches/" + data.id);
      } else {
        console.log("Error when creating branch: " + data.error);
      }
    } else if (action == "edit") {
      e.preventDefault();

      const [response, data] = await branchesService.updateBranch(
        branch.id,
        branch
      );
      if (response.ok) {
        const newBranch = getNewObject({
          ...data,
        });
        for (var i = 0; i < newBranch.contacts.length; i++) {
          newBranch.contacts[i].user = {
            name: newBranch.contacts[i].name,
            email: newBranch.contacts[i].email,
            profileImage: newBranch.contacts[i].profileImage,
          };
        }
        setBranch(
          getNewObject({
            ...newBranch,
          })
        );
        setSavedBranch(getNewObject({ ...newBranch }));
      } else {
        console.log("Error when updating branch: " + data.error);
      }
    }
    setLoadingButton(false);
  }

  const accountManagerOptions = useMemo(() => {
    return (
      accountManagers?.map((accountManager) => {
        return {
          id: accountManager.id,
          label: accountManager.name,
        };
      }) || []
    );
  }, [accountManagers]);

  if (action == "new") {
    if (!permissions.create) {
      return <AccessDenied type="half-page" />;
    }
  } else if (action == "edit") {
    if (!(permissions.get || permissions.update)) {
      return <AccessDenied type="half-page" />;
    }
  }

  return (
    <>
      {branch && (
        <>
          <form onSubmit={handleSubmission}>
            <MDBox display="flex" flexDirection="column" gap={2}>
              <Card>
                <MDBox
                  display="flex"
                  flexDirection={size.description == "large" ? "row" : "column"}
                  gap={2}
                  p={3}
                >
                  <Avatar
                    attachment={branch.logo == "reset" ? null : branch.logo}
                    setAttachment={handleLogoChange}
                    readOnly={!(permissions?.update || permissions?.create)}
                  />
                  <Grid container spacing={1}>
                    <Grid item xs={size.xs}>
                      <MDInput
                        id="name-input"
                        required
                        fullWidth
                        label={"Branch name"}
                        value={branch.name}
                        onChange={handleNameChange}
                        readOnly={!(permissions?.update || permissions?.create)}
                      />
                    </Grid>

                    <Grid item xs={size.xs}>
                      <CreateSubcategoryDialog
                        isOpen={createSubcategoryOpen}
                        categories={categories}
                        onSubmit={(data) => {
                          setCategories(data);
                        }}
                      />
                      <MDGroupedSelect
                        id="category-select"
                        required
                        fullWidth
                        label={"Category"}
                        value={branch.subcategoryId}
                        items={categories.map((item) => {
                          return {
                            name: item.description,
                            options: item.subcategories.map((subcategory) => {
                              return {
                                name: subcategory.description,
                                value: subcategory.id,
                              };
                            }),
                          };
                        })}
                        onChange={handleCategoryChange}
                        readOnly={!(permissions?.update || permissions?.create)}
                      />
                    </Grid>

                    <Grid item xs={size.xs}>
                      <MDInput
                        id="trademark-input"
                        fullWidth
                        label={"Trademark"}
                        value={branch.trademark}
                        onChange={handleTrademarkChange}
                        readOnly={!(permissions?.update || permissions?.create)}
                      />
                    </Grid>

                    <Grid item xs={size.xs}>
                      <CreateColorPaletteDialog
                        onSubmit={(data) => {
                          setColorPalettes(data);
                        }}
                        isOpen={createColorPaletteOpen}
                      />
                      {permissions?.update || permissions?.create ? (
                        <MDSelect
                          fullWidth
                          id="color palette-select"
                          required
                          value={branch.colorPaletteId}
                          label={"Color palette"}
                          items={colorPalettes.map((cp) => {
                            return {
                              value: cp.id,
                              render: (
                                <MDBox
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  sx={{ width: "100%", gap: ".1rem" }}
                                >
                                  <MDBox
                                    sx={{
                                      flex: 1,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      fontSize: ".8rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {cp.description}
                                  </MDBox>
                                  <MDBox
                                    display="flex"
                                    flexDirection="row"
                                    sx={{ flex: 1 }}
                                  >
                                    <MDBox
                                      sx={{
                                        height: 24,
                                        flex: 3,
                                        backgroundColor: cp.color1,
                                      }}
                                    ></MDBox>
                                    <MDBox
                                      sx={{
                                        flex: 2,
                                        height: 24,
                                        backgroundColor: cp.color2,
                                      }}
                                    ></MDBox>
                                    <MDBox
                                      sx={{
                                        flex: 1,
                                        height: 24,
                                        backgroundColor: cp.color3,
                                      }}
                                    ></MDBox>
                                  </MDBox>
                                </MDBox>
                              ),
                            };
                          })}
                          onChange={handleColorPaletteChange}
                        />
                      ) : (
                        (() => {
                          const colorPalette = colorPalettes.find(
                            (cp) => cp.id == branch.colorPaletteId
                          );
                          return (
                            <MDBox
                              display="flex"
                              flexDirection="row"
                              sx={{ width: "100%" }}
                            >
                              <MDBox
                                sx={{
                                  height: 24,
                                  flex: 3,
                                  backgroundColor: colorPalette.color1,
                                }}
                              ></MDBox>
                              <MDBox
                                sx={{
                                  flex: 2,
                                  height: 24,
                                  backgroundColor: colorPalette.color2,
                                }}
                              ></MDBox>
                              <MDBox
                                sx={{
                                  flex: 1,
                                  height: 24,
                                  backgroundColor: colorPalette.color3,
                                }}
                              ></MDBox>
                            </MDBox>
                          );
                        })()
                      )}
                    </Grid>

                    <Grid item xs={size.xs}>
                      <MDInput
                        id="email-input"
                        fullWidth
                        required
                        label={"Email"}
                        value={branch.email}
                        readOnly={!(permissions?.update || permissions?.create)}
                        onChange={handleEmailChange}
                      />
                    </Grid>

                    <Grid item xs={size.xs}>
                      <MDInput
                        id="phone-input"
                        fullWidth
                        required
                        label={"Phone"}
                        value={branch.phone}
                        readOnly={!(permissions?.update || permissions?.create)}
                        onChange={handlePhoneChange}
                      />
                    </Grid>

                    <Grid item xs={size.xs}>
                      <MDInput
                        id="address-input"
                        fullWidth
                        required
                        label={"Address"}
                        value={branch.address}
                        readOnly={!(permissions?.update || permissions?.create)}
                        onChange={handleAddressChange}
                      />
                    </Grid>

                    <Grid item xs={size.xs}>
                      <MSelect
                        id="region-select"
                        fullWidth
                        required
                        label={"Region"}
                        value={branch.regionId}
                        readOnly={!(permissions?.update || permissions?.create)}
                        items={regions.map((item) => {
                          return {
                            value: item.id,
                            reference: item.id,
                            render: item.description,
                          };
                        })}
                        onChange={handleRegionChange}
                      />
                    </Grid>

                    <Grid item xs={size.xs}>
                      <CreateBranchGroupDialog
                        isOpen={createBranchGroupOpen}
                        onSubmit={(data) => {
                          setBranchGroups(data);
                        }}
                      />
                      <MSelect
                        id="group-select"
                        fullWidth
                        required
                        label={"Branch group"}
                        value={branch.branchGroupId}
                        readOnly={!(permissions?.update || permissions?.create)}
                        items={branchGroups.map((item) => {
                          return {
                            value: item.id,
                            reference: item.id,
                            render: item.name,
                          };
                        })}
                        onChange={handleBranchGroupChange}
                      />
                    </Grid>

                    <Grid item xs={size.xs}>
                      <DropdownSelect
                        id="account manager-select"
                        label={"Account manager"}
                        initialValue={
                          branch.accountManagerId == 0 ||
                          !branch.accountManagerId
                            ? ""
                            : branch.accountManagerId
                        }
                        onChange={(newValue) => {
                          setBranch({
                            ...branch,
                            accountManagerId: newValue?.id,
                          });
                        }}
                        items={accountManagerOptions}
                        required={true}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
              <Card>
                <MDBox p={3} display="flex" flexDirection="column" gap={2}>
                  <MDBox
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <MDTypography variant="h6">Contacts</MDTypography>
                    {permissions?.update && (
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          setContactDialogAction("create");
                          setContactDialogOpen(Math.random());
                        }}
                      >
                        Add contact
                      </MDButton>
                    )}
                  </MDBox>

                  {false && (
                    <MDBox display="flex" flexDirection="row" gap={2}>
                      <UsersAutocomplete value={value} setValue={setValue} />
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          handleContactAdd(value);
                          setValue(null);
                        }}
                      >
                        Add user
                      </MDButton>
                    </MDBox>
                  )}

                  <MDBox sx={{ width: "100%" }}>
                    <DataTable
                      table={{
                        columns: [
                          {
                            Header: "user",
                            accessor: "user",
                            align: "left",
                          },
                          {
                            Header: "position",
                            accessor: "position",
                            align: "left",
                          },
                          {
                            Header: "",
                            accessor: "actions",
                            align: "right",
                          },
                        ],
                        rows:
                          !branch.contacts || branch.contacts.length == 0
                            ? [{ user: "There are no contacts in this branch" }]
                            : branch.contacts.map((contact, contactIndex) => {
                                return {
                                  user: (
                                    <User
                                      image={null}
                                      name={contact.user.name}
                                      email={contact.user.email}
                                    />
                                  ),
                                  position: (
                                    <>
                                      <MSelect
                                        required
                                        label={"Position"}
                                        value={contact.contactPosition?.id}
                                        items={positions.map((item) => {
                                          return {
                                            value: item.id,
                                            reference: item,
                                            render: item.name,
                                          };
                                        })}
                                        readOnly={
                                          !(
                                            permissions?.update ||
                                            permissions?.create
                                          )
                                        }
                                        onChange={(newPositionId) => {
                                          handleContactPositionChange(
                                            newPositionId,
                                            contactIndex
                                          );
                                        }}
                                      />
                                    </>
                                  ),
                                  actions: (
                                    <MDBox display="flex" flexDirection="row">
                                      {permissions?.update && (
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            setContactDialogAction("edit");
                                            setContactDialogOpen(Math.random());
                                            setCurrentContact({
                                              ...contact,
                                              index: contactIndex,
                                              contactPositionId:
                                                contact.contactPosition.id,
                                            });
                                          }}
                                        >
                                          <Icon>edit</Icon>
                                        </IconButton>
                                      )}
                                      {permissions?.update && (
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            handleContactRemove(contactIndex);
                                          }}
                                        >
                                          <Icon>delete</Icon>
                                        </IconButton>
                                      )}
                                    </MDBox>
                                  ),
                                };
                              }),
                      }}
                      showTotalEntries={false}
                      isSorted={false}
                      noEndBorder
                      entriesPerPage={false}
                    />
                  </MDBox>
                </MDBox>
              </Card>
              {(permissions?.update || permissions?.create) && (
                <LoadingButton
                  loading={loadingButton}
                  type="submit"
                  color="info"
                  variant="gradient"
                  disabled={
                    loadingButton ||
                    (action == "edit" &&
                      JSON.stringify(savedBranch) === JSON.stringify(branch))
                  }
                  sx={{ height: "20px" }}
                >
                  {action == "edit" ? "Save" : "Create"}
                </LoadingButton>
              )}
            </MDBox>
          </form>
          <AlertDialog open={open} setOpen={setOpen} {...dialog} />
          <ContactDialog
            isOpen={contactDialogOpen}
            action={contactDialogAction}
            data={{ positions: positions, contact: { ...currentContact } }}
            onSubmit={async (newContact) => {
              if (contactDialogAction == "create") {
                handleContactAdd(newContact);
              } else {
                handleContactUpdate(newContact);
              }
              return { success: true };
            }}
          />
        </>
      )}
    </>
  );
}

const User = ({ image, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MuiAvatar src={image} name={name} sx={{ width: 32, height: 32 }} />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);

export default Branch;
