import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DropdownSelect from "components/DropdownSelect";
import LoadingButton from "components/LoadingButton";
import MDInput from "components/MDInput";
import * as React from "react";

export default function TerritoriesDialog({ isOpen, onSubmit, data, type }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [territory, setTerritory] = React.useState({
    name: "",
    countryId: null,
  });

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);
    setTerritory({
      name: "",
      countryId: null,
    });
  }, [isOpen]);

  React.useEffect(() => {
    setTerritory({ ...data.territory });
  }, [data.territory]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          const result = await onSubmit(territory);
          if (result.success) {
            handleClose();
          }

          setLoading(false);
        },
      }}
    >
      <DialogTitle>
        {type == "edit" ? "Edit" : "Create new"} territory
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: -0.5 }}>
          <Grid item xs={12}>
            <MDInput
              autoFocus
              required
              margin="dense"
              id="name"
              name="name"
              label="Name"
              type="text"
              value={territory.name}
              onChange={(e) => {
                setTerritory({
                  ...territory,
                  name: e.target.value,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <DropdownSelect
              id="country-select"
              required
              label={"Country"}
              initialValue={territory?.countryId || ""}
              onChange={(newValue) => {
                setTerritory({ ...territory, countryId: newValue?.id || null });
              }}
              items={
                data?.countries?.map((c) => ({ id: c.id, label: c.name })) || []
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          {type == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
