import { Avatar, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import DropdownSelect from "components/DropdownSelect";
import LoadingButton from "components/LoadingButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useAuth } from "contexts/auth.context";
import * as React from "react";
import accountsService from "services/accounts-service";
import currenciesService from "services/currencies-service";
import locationService from "services/location-service";
import regionsService from "services/regions-service";

const auditLimitPerMonthList = Array.from({ length: 100 }, (_, i) => ({
  id: i + 1,
  label: i + 1,
}));

export default function SubscriptionDialog({
  isOpen,
  action,
  data,
  onSubmit,
  accountId,
}) {
  const authContext = useAuth();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState({
    name: "",
    description: "",
    price: 0,
    extraAuditPrice: 0,
    type: authContext?.user?.account?.isExternalAuditor ? 2 : 0,
    maxAuditsMonth: -1,
    maxAuditsPeriod: -1,
    maxUsers: -1,
    maxSubAccounts: -1,
    maxBranchGroups: -1,
    period: 0,
  });
  const [error, setError] = React.useState(null);
  const [options, setOptions] = React.useState({});
  const [dropdownOptions, setDropdownOptions] = React.useState({});

  const fetchOptions = async () => {
    async function countries() {
      const [response, data] = await regionsService.getCountries();
      if (response.ok) {
        setOptions((curr) => ({ ...curr, countries: data }));
      }
    }

    async function currencies() {
      const [response, data] = await currenciesService.getCurrencies();
      if (response.ok) {
        setOptions((curr) => ({ ...curr, currencies: data }));
      }
    }

    async function accounts() {
      const [response, data] = await accountsService.getAccounts({ raw: true });
      if (response.ok) {
        setOptions((curr) => ({ ...curr, accounts: data }));
      }
    }

    await Promise.all([countries(), currencies(), accounts()]);
  };

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setLoading(false);
    setError(null);
  }, [isOpen]);

  React.useEffect(() => {
    if (action == "create") {
      setValue({
        name: "",
        description: "",
        price: 0,
        maxAuditsMonth: 0,
        extraAuditPrice: 0,
        maxAuditsPeriod: 0,
        type: authContext?.user?.account?.isExternalAuditor ? 2 : 0,
        periodDay: 0,
        periodMonth: 0,
        periodYear: 0,
        maxUsers: 0,
        maxSubAccounts: 0,
        maxBranchGroups: 0,
        period: 0,
      });
    } else {
      setValue(data);
    }
  }, [data, isOpen]);

  React.useEffect(() => {
    fetchOptions();
  }, []);

  React.useEffect(() => {
    setDropdownOptions({
      countries:
        options?.countries?.map((c) => ({ id: c.id, label: c.name })) || [],
      currencies:
        options?.currencies?.map((c) => ({
          id: c.id,
          label: c.name + " — " + c.value + "$",
        })) || [],
      accounts:
        options?.accounts?.map((a) => ({ id: a.id, label: a.name })) || [],
    });
  }, [options]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();

          setLoading(true);
          const result = await onSubmit(value);
          if (result.success) {
            handleClose();
          } else {
            setError(result.error);
          }

          setLoading(false);
        },
      }}
    >
      <DialogTitle sx={{ mt: -0.5 }}>
        {action == "edit" ? "Edit" : "Create"}
        {" subscription"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1.5} alignItems="center">
          <Grid item xs={12}>
            <MDTypography variant="h6" sx={{ mt: -0.5 }}>
              Info
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDInput
              autoFocus
              required
              margin="dense"
              error={error?.fields === "name"}
              helperText={error?.fields === "name" && error?.message}
              id="r"
              name="r"
              label="Name"
              type="text"
              value={value.name}
              onChange={(e) => {
                setValue({ ...value, name: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              multiline
              required
              margin="dense"
              error={error?.fields === "description"}
              helperText={error?.fields === "description" && error?.message}
              id="r"
              name="r"
              label="Description"
              type="text"
              value={value.description}
              onChange={(e) => {
                setValue({ ...value, description: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          {!accountId && (
            <Grid item xs={12}>
              <DropdownSelect
                label={"Account"}
                initialValue={value.accountId || ""}
                onChange={(e) => {
                  setValue({ ...value, accountId: e?.id });
                }}
                items={dropdownOptions?.accounts}
                required={true}
                fullWidth
              />
            </Grid>
          )}
          <Grid container item xs={12} spacing={1}>
            <Grid container item xs={6} direction="column" spacing={1}>
              <Grid item xs={3}>
                <DropdownSelect
                  label={"Type"}
                  initialValue={value.type || ""}
                  onChange={(e) => {
                    setValue({ ...value, type: e?.id });
                  }}
                  items={[
                    { id: 1, label: "Internal" },
                    ...(authContext?.user?.account?.isExternalAuditor
                      ? [{ id: 2, label: "External" }]
                      : []),
                  ]}
                  required={true}
                  readOnly={Boolean(
                    authContext.user?.account?.isExternalAuditor
                  )}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  control={
                    <Checkbox
                      checked={value.notifyExpiry}
                      value={value.notifyExpiry}
                      onChange={(e) => {
                        setValue({
                          ...value,
                          notifyExpiry: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Notify expiry to account manager"
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <DropdownSelect
                multiple
                label={"Countries"}
                initialValue={
                  value?.countries?.map((country) => country.id) || []
                }
                onChange={(e) => {
                  setValue({ ...value, countries: e });
                }}
                items={dropdownOptions.countries || []}
                //required={true}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: -1 }}>
            <Divider sx={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }} />
            <MDTypography variant="h6" sx={{ mt: -0.5 }}>
              Pricing
            </MDTypography>
          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={4}>
              <MDInput
                required
                margin="dense"
                id="r"
                name="r"
                label="Price"
                min="0"
                step="0.01"
                type="number"
                error={error?.fields === "price"}
                helperText={error?.fields === "price" && error?.message}
                value={value.price}
                onChange={(e) => {
                  setValue({ ...value, price: Number(e.target.value) });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MDInput
                required
                margin="dense"
                id="r"
                name="r"
                label="Price per extra audit"
                min="0"
                step="0.01"
                type="number"
                error={error?.fields === "extraAuditPrice"}
                helperText={
                  error?.fields === "extraAuditPrice" && error?.message
                }
                value={value.extraAuditPrice}
                onChange={(e) => {
                  setValue({
                    ...value,
                    extraAuditPrice: Number(e.target.value),
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <DropdownSelect
                label={"Currency"}
                initialValue={value.currencyId || ""}
                onChange={(e) => {
                  setValue({ ...value, currencyId: e?.id });
                }}
                renderOption={(option) => {
                  return option.label;
                }}
                items={dropdownOptions.currencies}
                required={true}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: -1 }}>
            <Divider sx={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }} />
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <MDTypography variant="h6" sx={{ mt: -0.5 }}>
                Duration
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <UintTextField
              required
              margin="dense"
              error={error?.fields === "period"}
              helperText={error?.fields === "period" && error?.message}
              id="r"
              name="r"
              label="Expires after (in months)"
              type="number"
              value={value.period}
              onChange={(e) => {
                setValue({ ...value, period: Number(e.target.value) || 0 });
              }}
              fullWidth
            />
          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={4}>
              <UintTextField
                required
                margin="dense"
                error={error?.fields === "periodDay"}
                helperText={error?.fields === "periodDay" && error?.message}
                id="r"
                name="r"
                label="Period days"
                type="number"
                value={value.periodDay}
                onChange={(e) => {
                  setValue({
                    ...value,
                    periodDay: Number(e.target.value) || 0,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <UintTextField
                required
                margin="dense"
                error={error?.fields === "periodMonth"}
                helperText={error?.fields === "periodMonth" && error?.message}
                id="r"
                name="r"
                label="Period months"
                type="number"
                value={value.periodMonth}
                onChange={(e) => {
                  setValue({
                    ...value,
                    periodMonth: Number(e.target.value) || 0,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <UintTextField
                required
                margin="dense"
                error={error?.fields === "periodYear"}
                helperText={error?.fields === "periodYear" && error?.message}
                id="r"
                name="r"
                label="Period years"
                type="number"
                value={value.periodYear}
                onChange={(e) => {
                  setValue({
                    ...value,
                    periodYear: Number(e.target.value) || 0,
                  });
                }}
                fullWidth
              />
            </Grid>
          </Grid>

          {/*<Grid item xs={7}>
            <UintTextField
              required
              margin="dense"
              error={error?.fields === "maxAuditsMonth"}
              helperText={error?.fields === "maxAuditsMonth" && error?.message}
              id="r"
              name="r"
              label="Audit limit per month"
              type={value.maxAuditsMonth == -1 ? "text" : "number"}
              value={
                value.maxAuditsMonth == -1 ? "Unlimited" : value.maxAuditsMonth
              }
              disabled={value.maxAuditsMonth == -1}
              onChange={(e) => {
                setValue({
                  ...value,
                  maxAuditsMonth: Number(e.target.value) || 0,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <FormControlLabel
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              control={
                <Checkbox
                  checked={value.maxAuditsMonth == -1}
                  value={value.maxAuditsMonth == -1}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      maxAuditsMonth: !e.target.checked
                        ? value.maxAuditsMonth >= 0
                          ? Number(value.maxAuditsMonth)
                          : 0
                        : -1,
                    });
                  }}
                />
              }
              label="Unlimited audits per month"
            />
          </Grid>*/}

          <Grid item xs={12} sx={{ mt: -1 }}>
            <Divider sx={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }} />
            <MDTypography variant="h6" sx={{ mt: -0.5 }}>
              Limits
            </MDTypography>
          </Grid>

          <Grid item xs={7}>
            <UintTextField
              required
              margin="dense"
              error={error?.fields === "maxAuditsPeriod"}
              helperText={error?.fields === "maxAuditsPeriod" && error?.message}
              id="r"
              name="r"
              label={
                <>
                  Audit limit every{" "}
                  {value.periodYear
                    ? `${value.periodYear} year${
                        value.periodYear == 1 ? "" : "s"
                      }`
                    : ""}
                  {value.periodMonth
                    ? `${
                        value.periodYear
                          ? value.periodDay
                            ? ", "
                            : " and "
                          : ""
                      }${value.periodMonth} month${
                        value.periodMonth == 1 ? "" : "s"
                      }`
                    : ""}
                  {value.periodDay
                    ? `${value.periodYear || value.periodMonth ? " and " : ""}${
                        value.periodDay
                      } day${value.periodDay == 1 ? "" : "s"}`
                    : ""}
                </>
              }
              type={value.maxAuditsPeriod == -1 ? "text" : "number"}
              value={
                value.maxAuditsPeriod == -1
                  ? "Unlimited"
                  : value.maxAuditsPeriod
              }
              disabled={value.maxAuditsPeriod == -1}
              onChange={(e) => {
                setValue({
                  ...value,
                  maxAuditsPeriod: Number(e.target.value) || 0,
                });
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={5}>
            <FormControlLabel
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              control={
                <Checkbox
                  checked={value.maxAuditsPeriod == -1}
                  value={value.maxAuditsPeriod == -1}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      maxAuditsPeriod: !e.target.checked
                        ? value.maxAuditsPeriod >= 0
                          ? Number(value.maxAuditsPeriod)
                          : 0
                        : -1,
                    });
                  }}
                />
              }
              label="Unlimited audits per period"
            />
          </Grid>

          <Grid item xs={7}>
            <UintTextField
              required
              margin="dense"
              error={error?.fields === "maxUsers"}
              helperText={error?.fields === "maxUsers" && error?.message}
              id="r"
              name="r"
              label="Users limit"
              type={value.maxUsers == -1 ? "text" : "number"}
              value={value.maxUsers == -1 ? "Unlimited" : value.maxUsers}
              disabled={value.maxUsers == -1}
              onChange={(e) => {
                setValue({
                  ...value,
                  maxUsers: Number(e.target.value) || 0,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <FormControlLabel
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              control={
                <Checkbox
                  checked={value.maxUsers == -1}
                  value={value.maxUsers == -1}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      maxUsers: !e.target.checked
                        ? value.maxUsers >= 0
                          ? Number(value.maxUsers)
                          : 0
                        : -1,
                    });
                  }}
                />
              }
              label="Unlimited users"
            />
          </Grid>

          <Grid item xs={7}>
            <UintTextField
              required
              margin="dense"
              error={error?.fields === "maxSubAccounts"}
              helperText={error?.fields === "maxSubAccounts" && error?.message}
              id="r"
              name="r"
              label="Sub-accounts limit"
              type={value.maxSubAccounts == -1 ? "text" : "number"}
              value={
                value.maxSubAccounts == -1 ? "Unlimited" : value.maxSubAccounts
              }
              disabled={value.maxSubAccounts == -1}
              onChange={(e) => {
                setValue({
                  ...value,
                  maxSubAccounts: Number(e.target.value) || 0,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <FormControlLabel
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              control={
                <Checkbox
                  checked={value.maxSubAccounts == -1}
                  value={value.maxSubAccounts == -1}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      maxSubAccounts: !e.target.checked
                        ? value.maxSubAccounts >= 0
                          ? Number(value.maxSubAccounts)
                          : 0
                        : -1,
                    });
                  }}
                />
              }
              label="Unlimited sub-accounts"
            />
          </Grid>

          <Grid item xs={7}>
            <UintTextField
              required
              margin="dense"
              error={error?.fields === "maxBranchGroups"}
              helperText={error?.fields === "maxBranchGroups" && error?.message}
              id="r"
              name="r"
              label="Branch groups limit"
              type={value.maxBranchGroups == -1 ? "text" : "number"}
              value={
                value.maxBranchGroups == -1
                  ? "Unlimited"
                  : value.maxBranchGroups
              }
              disabled={value.maxBranchGroups == -1}
              onChange={(e) => {
                setValue({
                  ...value,
                  maxBranchGroups: Number(e.target.value) || 0,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <FormControlLabel
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              control={
                <Checkbox
                  checked={value.maxBranchGroups == -1}
                  value={value.maxBranchGroups == -1}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      maxBranchGroups: !e.target.checked
                        ? value.maxBranchGroups >= 0
                          ? Number(value.maxBranchGroups)
                          : 0
                        : -1,
                    });
                  }}
                />
              }
              label="Unlimited branch groups"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="gradient"
          color="info"
        >
          {action == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function UintTextField({ onChange, ...props }) {
  return (
    <MDInput
      {...props}
      type="text"
      onChange={(e) => {
        if (!/^\d*$/.test(e.target.value)) return;

        if (onChange) {
          onChange(e);
        }
      }}
    />
  );
}
