import { Box, Menu } from "@mui/material";
import MDInput from "components/MDInput";
import { useRef, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";

export default function ColorPickerInput({ ...props }) {
  const [open, setOpen] = useState({ color: false });
  const inputRef = useRef();
  return (
    <>
      <MDInput
        {...props}
        type="color"
        onClick={(e) => {
          e.preventDefault();
          if (props.onClick) props.onClick();
          setOpen({ ...open, color: e.currentTarget });
        }}
        ref={inputRef}
      />
      <Menu
        open={open.color}
        anchorEl={open.color}
        onClose={() => {
          setOpen({ ...open, color: false });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            width: inputRef?.current?.offsetWidth - 16 || "180px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <HexColorPicker
            color={props.value}
            onChange={(newColor) => {
              props.setValue(newColor);
            }}
            style={{ width: "100%" }}
          />
          <HexColorInput
            color={props.value}
            onChange={(newColor) => {
              props.setValue(newColor);
            }}
            prefixed
            style={{ width: "100%", padding: "4px", fontFamily: "Roboto" }}
          />
        </Box>
      </Menu>
    </>
  );
}
