export const DEVELOPMENT = false;

export const API_URL = DEVELOPMENT
  ? "http://localhost:3001"
  : "https://api.ifpc360.com";
export const APP_URL = DEVELOPMENT
  ? "http://localhost:3000"
  : "http://ifpc360.com.s3-website.eu-north-1.amazonaws.com";
//export const UPLOAD_URL = `${API_URL}/uploads`;
export const APK_DOWNLOAD_URL = `${process.env.PUBLIC_URL}/apk/ifpc - 1.0.5.apk`;
