import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MSelect from "components/MSelect";
import MDBox from "components/MDBox";
import TabPanel from "components/TabPanel";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import MDGroupedSelect from "components/MDGroupedSelect";
import DataTable from "examples/Tables/DataTable";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "components/LoadingButton";
import GroupedSelectMultiple from "components/GroupedSelectMultiple";
import TextField from "@mui/material/TextField";

export default function CreatePillarDialog({
  title,
  isOpen,
  onSubmit,
  data,
  createChoice,
  type,
}) {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState(0);
  const [pillar, setPillar] = React.useState({
    parentId: data.parentId,
    subcategoryId: data.subcategoryId,
    subcategories: data.subcategories
      ? data.subcategories?.map((sub) => sub.id)
      : [],
  });

  const [error, setError] = React.useState(null);
  const [submissionError, setSubmissionError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const [kpi, setKpi] = React.useState({
    type: 0,
    pillarId: data.pillarId,
    subcategoryId: data.subcategoryId,
    kpiValueType: data.valueType,
    weight: 0,
    referenceCode: 0,
    deviationDescription: "",
    risks: [],
    actions: [],
    deviationsDescription: [],
  });

  React.useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
    setError(null);
    setLoading(false);
  }, [isOpen]);

  React.useEffect(() => {
    setState(!createChoice ? state : createChoice == "pillar" ? 0 : 1);
  }, [createChoice]);

  React.useEffect(() => {
    setPillar(
      data.pillar
        ? data.pillar
        : {
            referenceCode: generateDefaultReferenceCode(
              data.parentId,
              data.count + 1
            ),
            parentId: data.parentId,
            subcategoryId: data.subcategoryId
              ? data.subcategoryId
              : data.categories.length > 0
              ? data.categories[0].id
              : null,
            subcategories: data.subcategories?.map((sub) => sub.id)
              ? data.subcategories?.map((sub) => sub.id)
              : [],
          }
    );

    setKpi(
      data.kpi
        ? {
            id: data.kpi.id,
            type: data.kpi.type,
            pillarId: data.kpi.pillarId,
            subcategoryId: data.kpi.subcategoryId,
            kpiValueTypeId: data.kpi.kpiValueTypeId, //valueTypeId--
            weight: data.kpi.weight,
            referenceCode: data.kpi.referenceCode,
            deviationDescription: data.kpi.deviationDescription, //description--
            description: data.kpi.description,
            risks: data.kpi.kpiRiskActions.filter((item) => {
              return item.type == 0;
            }),
            actions: data.kpi.kpiRiskActions.filter((item) => {
              return item.type == 1;
            }),
            deviationsDescription: data.kpi.kpiRiskActions.filter((item) => {
              return item.type == 2;
            }),
          }
        : {
            type: 0,
            parentId: data.parentId,
            pillarId: data.pillarId,
            subcategoryId: data.subcategoryId,
            kpiValueTypeId:
              data.valueTypes.length > 0 ? data.valueTypes[0].id : null,
            weight: 0,
            referenceCode: generateKpiReferenceCode(data.parent),
            deviationDescription: "",
            description: "",
            risks: [],
            actions: [],
            deviationsDescription: [],
          }
    );
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  function handleCategoryChange(newSubcategories) {
    setPillar({ ...pillar, subcategories: [...newSubcategories] });
  }

  return (
    <Dialog
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();
          if (submissionError) {
            console.log(submissionError);
            return;
          }

          setLoading(true);
          if (state == 0) {
            const p = { ...pillar };
            p.isPillar = true;
            if (type === "edit") {
              p.subcategories = p.subcategories.map((sub) => {
                if (sub.id) {
                  return sub;
                } else return { id: sub };
              });
            }
            const result = await onSubmit(p);
            if (result.error) {
              setError(result.error);
            } else {
              setError(null);
              handleClose();
            }
          } else {
            const k = { ...kpi };
            k.isPillar = false;
            const result = await onSubmit(k);
            if (result.error) {
              setError(result.error);
              console.log(result.error);
            } else {
              setError(null);
              handleClose();
            }
          }
          setLoading(false);
        },
      }}
    >
      <DialogTitle>
        {(type === "edit" ? "Edit " : "Create ") +
          (createChoice ? createChoice : "")}
      </DialogTitle>
      <DialogContent>
        {createChoice == "kpi" && (
          <Grid container spacing={2} mt={-0.5}>
            <Grid item xs={12}>
              <MDInput
                multiline
                rows={3}
                autoFocus
                required
                id="kpiDescription"
                name="kpiDescription"
                label="Description"
                type="text"
                value={kpi.description}
                onChange={(e) => {
                  setKpi({
                    ...kpi,
                    description: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="reference code-input"
                margin="dense"
                label="Reference code"
                type="text"
                value={kpi.referenceCode}
                error={error?.fields === "kpi_referenceCode_key"}
                helperText={
                  error?.fields === "kpi_referenceCode_key" && error?.message
                }
                onChange={(e) => {
                  setKpi({ ...kpi, referenceCode: e.target.value });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                required
                margin="dense"
                id="kpiWeight"
                name="kpiWeight"
                label="Weight"
                type="number"
                value={kpi.weight}
                onChange={(e) => {
                  setKpi({ ...kpi, weight: e.target.value });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MSelect
                required
                id="type-select"
                value={kpi.type}
                label={"Type"}
                items={["Static", "Dynamic"].map((type, index) => {
                  return {
                    value: index,
                    render: type,
                  };
                })}
                onChange={(value) => {
                  setKpi({ ...kpi, type: value });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <MSelect
                id="kpiValueTypeId-select"
                required
                value={kpi.kpiValueTypeId}
                label={"Value type"}
                items={data.valueTypes.map((valueType, index) => {
                  return {
                    value: valueType.id,
                    render: valueType.description,
                  };
                })}
                onChange={(value) => {
                  setKpi({ ...kpi, kpiValueTypeId: value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
              >
                <MDTypography variant="h6">Deviations description</MDTypography>
                <MDButton
                  onClick={() => {
                    setKpi({
                      ...kpi,
                      deviationsDescription: [
                        ...kpi.deviationsDescription,
                        {
                          description: "",
                          isDefault: kpi.deviationsDescription.length == 0,
                          type: 2,
                          editing: true,
                        },
                      ],
                    });
                  }}
                >
                  <Icon>add</Icon>&nbsp; Add deviation description
                </MDButton>
              </MDBox>
              <MDBox display="flex" flexDirection="column">
                <DataTable
                  table={{
                    columns: [
                      {
                        Header: "Default",
                        accessor: "default",
                        width: "10px",
                        align: "center",
                      },
                      {
                        Header: "Description",
                        accessor: "description",
                        width: "100%",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        width: "15%",
                        align: "center",
                      },
                    ],
                    rows: kpi.deviationsDescription.map((value, index) => {
                      return {
                        default: (
                          <FormControlLabel
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            control={
                              <Checkbox
                                checked={value.isDefault}
                                disabled={value.isDefault}
                                onChange={(e) => {
                                  const newDeviationsDescription = [
                                    ...kpi.deviationsDescription,
                                  ];
                                  if (e.target.checked) {
                                    for (
                                      var i = 0;
                                      i < newDeviationsDescription.length;
                                      i++
                                    ) {
                                      newDeviationsDescription[
                                        i
                                      ].isDefault = false;
                                    }
                                  }

                                  newDeviationsDescription[index].isDefault =
                                    e.target.checked;

                                  setKpi({
                                    ...kpi,
                                    deviationsDescription: [
                                      ...kpi.deviationsDescription,
                                    ],
                                  });
                                }}
                              />
                            }
                          />
                        ),
                        description: (
                          <>
                            {!value.editing && (
                              <MDTypography variant="inherit">
                                {value.description}
                              </MDTypography>
                            )}
                            {value.editing && (
                              <MDInput
                                sx={{ width: "100%", minWidth: "400px" }}
                                value={value.description}
                                label="Description"
                                onChange={(e) => {
                                  const newDeviationsDescription = [
                                    ...kpi.deviationsDescription,
                                  ];
                                  newDeviationsDescription[index].description =
                                    e.target.value;
                                  setKpi({
                                    ...kpi,
                                    deviationsDescription: [
                                      ...kpi.deviationsDescription,
                                    ],
                                  });
                                }}
                              />
                            )}
                          </>
                        ),
                        actions: (
                          <>
                            <IconButton
                              disabled={
                                value.editing && value.description == ""
                              }
                              size="small"
                              onClick={() => {
                                const newDeviationsDescription = [
                                  ...kpi.deviationsDescription,
                                ];
                                newDeviationsDescription[index].editing =
                                  !value.editing;
                                setKpi({
                                  ...kpi,
                                  deviationsDescription: [
                                    ...kpi.deviationsDescription,
                                  ],
                                });
                              }}
                            >
                              <Icon>{value.editing ? "check" : "edit"}</Icon>
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                const newDeviationsDescription = [
                                  ...kpi.deviationsDescription,
                                ];
                                newDeviationsDescription.splice(index, 1);

                                if (newDeviationsDescription.length > 0) {
                                  newDeviationsDescription[0].isDefault = kpi
                                    .deviationsDescription[index].isDefault
                                    ? true
                                    : newDeviationsDescription[0].isDefault;
                                }

                                setKpi({
                                  ...kpi,
                                  deviationsDescription: [
                                    ...newDeviationsDescription,
                                  ],
                                });
                              }}
                            >
                              <Icon>delete</Icon>
                            </IconButton>
                          </>
                        ),
                      };
                    }),
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
              >
                <MDTypography variant="h6">Actions</MDTypography>
                <MDButton
                  onClick={() => {
                    setKpi({
                      ...kpi,
                      actions: [
                        ...kpi.actions,
                        {
                          description: "",
                          isDefault: kpi.actions.length == 0,
                          type: 0,
                          editing: true,
                        },
                      ],
                    });
                  }}
                >
                  <Icon>add</Icon>&nbsp; Add Action
                </MDButton>
              </MDBox>
              <MDBox display="flex" flexDirection="column">
                <DataTable
                  table={{
                    columns: [
                      {
                        Header: "Default",
                        accessor: "default",
                        width: "10px",
                        align: "center",
                      },
                      {
                        Header: "Description",
                        accessor: "description",
                        width: "100%",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        width: "15%",
                        align: "center",
                      },
                    ],
                    rows: kpi.actions.map((value, index) => {
                      return {
                        default: (
                          <FormControlLabel
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            control={
                              <Checkbox
                                checked={value.isDefault}
                                disabled={value.isDefault}
                                onChange={(e) => {
                                  const newActions = [...kpi.actions];
                                  if (e.target.checked) {
                                    for (
                                      var i = 0;
                                      i < newActions.length;
                                      i++
                                    ) {
                                      newActions[i].isDefault = false;
                                    }
                                  }

                                  newActions[index].isDefault =
                                    e.target.checked;

                                  setKpi({
                                    ...kpi,
                                    actions: [...kpi.actions],
                                  });
                                }}
                              />
                            }
                          />
                        ),
                        description: (
                          <>
                            {!value.editing && (
                              <MDTypography variant="inherit">
                                {value.description}
                              </MDTypography>
                            )}
                            {value.editing && (
                              <MDInput
                                sx={{ width: "100%", minWidth: "400px" }}
                                value={value.description}
                                label="Description"
                                onChange={(e) => {
                                  const newActions = [...kpi.actions];
                                  newActions[index].description =
                                    e.target.value;
                                  setKpi({
                                    ...kpi,
                                    actions: [...kpi.actions],
                                  });
                                }}
                              />
                            )}
                          </>
                        ),
                        actions: (
                          <>
                            <IconButton
                              disabled={
                                value.editing && value.description == ""
                              }
                              size="small"
                              onClick={() => {
                                const newActions = [...kpi.actions];
                                newActions[index].editing = !value.editing;
                                setKpi({
                                  ...kpi,
                                  actions: [...kpi.actions],
                                });
                              }}
                            >
                              <Icon>{value.editing ? "check" : "edit"}</Icon>
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                const newActions = [...kpi.actions];
                                newActions.splice(index, 1);

                                if (newActions.length > 0) {
                                  newActions[0].isDefault = kpi.actions[index]
                                    .isDefault
                                    ? true
                                    : newActions[0].isDefault;
                                }

                                setKpi({
                                  ...kpi,
                                  actions: [...newActions],
                                });
                              }}
                            >
                              <Icon>delete</Icon>
                            </IconButton>
                          </>
                        ),
                      };
                    }),
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
              >
                <MDTypography variant="h6">Risks</MDTypography>
                <MDButton
                  onClick={() => {
                    setKpi({
                      ...kpi,
                      risks: [
                        ...kpi.risks,
                        {
                          description: "",
                          isDefault: kpi.risks.length == 0,
                          type: 0,
                          editing: true,
                        },
                      ],
                    });
                  }}
                >
                  <Icon>add</Icon>&nbsp; Add Risk
                </MDButton>
              </MDBox>
              <MDBox display="flex" flexDirection="column">
                <DataTable
                  table={{
                    columns: [
                      {
                        Header: "Default",
                        accessor: "default",
                        width: "10px",
                        align: "center",
                      },
                      {
                        Header: "Description",
                        accessor: "description",
                        width: "100%",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        width: "15%",
                        align: "center",
                      },
                    ],
                    rows: kpi.risks.map((value, index) => {
                      return {
                        default: (
                          <FormControlLabel
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "20px",
                            }}
                            control={
                              <Checkbox
                                checked={value.isDefault}
                                disabled={value.isDefault}
                                onChange={(e) => {
                                  const newRisks = [...kpi.risks];
                                  if (e.target.checked) {
                                    for (var i = 0; i < newRisks.length; i++) {
                                      newRisks[i].isDefault = false;
                                    }
                                  }

                                  newRisks[index].isDefault = e.target.checked;

                                  setKpi({
                                    ...kpi,
                                    risks: [...kpi.risks],
                                  });
                                }}
                              />
                            }
                          />
                        ),
                        description: (
                          <>
                            {!value.editing && (
                              <MDTypography variant="inherit">
                                {value.description}
                              </MDTypography>
                            )}
                            {value.editing && (
                              <MDBox
                                sx={{ width: "100%", minWidth: "400px" }}
                                fullWidth
                              >
                                <MDInput
                                  sx={{ width: "100%", minWidth: "400px" }}
                                  value={value.description}
                                  label="Description"
                                  onChange={(e) => {
                                    const newRisks = [...kpi.risks];
                                    newRisks[index].description =
                                      e.target.value;
                                    setKpi({
                                      ...kpi,
                                      risks: [...newRisks],
                                    });
                                  }}
                                />
                              </MDBox>
                            )}
                          </>
                        ),
                        actions: (
                          <>
                            <IconButton
                              disabled={
                                value.editing && value.description == ""
                              }
                              size="small"
                              onClick={() => {
                                const newRisks = [...kpi.risks];
                                newRisks[index].editing = !value.editing;
                                setKpi({
                                  ...kpi,
                                  risks: [...newRisks],
                                });
                              }}
                            >
                              <Icon>{value.editing ? "check" : "edit"}</Icon>
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                const newRisks = [...kpi.risks];
                                newRisks.splice(index, 1);

                                if (newRisks.length > 0) {
                                  newRisks[0].isDefault = kpi.risks[index]
                                    .isDefault
                                    ? true
                                    : newRisks[0].isDefault;
                                }

                                setKpi({
                                  ...kpi,
                                  risks: [...newRisks],
                                });
                              }}
                            >
                              <Icon>delete</Icon>
                            </IconButton>
                          </>
                        ),
                      };
                    }),
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Grid>
          </Grid>
        )}

        {createChoice == "pillar" && (
          <Grid container spacing={2} mt={-0.5}>
            <Grid item xs={12}>
              <MDInput
                multiline
                rows={3}
                autoFocus
                value={pillar?.description ? pillar.description : ""}
                onChange={(e) => {
                  setPillar({ ...pillar, description: e.target.value });
                }}
                required
                margin="dense"
                id="pillarDescription"
                name="pillarDescription"
                label="Description"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                required
                value={pillar?.referenceCode ? pillar.referenceCode : ""}
                onChange={(e) => {
                  setPillar({ ...pillar, referenceCode: e.target.value });
                }}
                id="pillarReferenceCode"
                name="pillarReferenceCode"
                label="Reference code"
                error={error?.fields === "pillar_referenceCode_key"}
                helperText={
                  error?.fields === "pillar_referenceCode_key" && error?.message
                }
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <GroupedSelectMultiple
                required
                fullWidth={true}
                label={"Category"}
                readOnly={data?.parentId ? true : false}
                value={
                  pillar?.subcategories.length > 0 &&
                  pillar?.subcategories[0].id
                    ? pillar.subcategories.map((sub) => sub.id)
                    : pillar.subcategories
                }
                items={data.categories.map((item) => {
                  return {
                    render: item.description,
                    children: item.subcategories.map((subcategory) => {
                      return {
                        render: subcategory.description,
                        value: subcategory.id,
                      };
                    }),
                  };
                })}
                onChange={handleCategoryChange}
              />
            </Grid>
          </Grid>
        )}

        {!createChoice && (
          <TabPanel
            items={[
              {
                label: "New Pillar",
                panel: (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <MDInput
                        multiline
                        rows={3}
                        autoFocus
                        value={pillar?.description ? pillar.description : ""}
                        onChange={(e) => {
                          setPillar({ ...pillar, description: e.target.value });
                        }}
                        required
                        margin="dense"
                        id="pillarDescription"
                        name="pillarDescription"
                        label="Description"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MDInput
                        required
                        value={
                          pillar?.referenceCode ? pillar.referenceCode : ""
                        }
                        onChange={(e) => {
                          setPillar({
                            ...pillar,
                            referenceCode: e.target.value,
                          });
                        }}
                        id="pillarReferenceCode"
                        name="pillarReferenceCode"
                        label="Reference code"
                        error={error?.referenceCode}
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MDGroupedSelect
                        required
                        fullWidth={true}
                        label={"Category"}
                        readOnly={data?.subcategoryId ? true : false}
                        value={pillar?.subcategoryId}
                        items={data.categories.map((item) => {
                          return {
                            name: item.description,
                            options: item.subcategories.map((subcategory) => {
                              return {
                                name: subcategory.description,
                                value: subcategory.id,
                              };
                            }),
                          };
                        })}
                        onChange={handleCategoryChange}
                      />
                    </Grid>
                  </Grid>
                ),
              },
              {
                label: "New KPI",
                panel: (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <MDInput
                        multiline
                        rows={3}
                        autoFocus
                        required
                        id="kpiDescription"
                        name="kpiDescription"
                        label="Description"
                        type="text"
                        value={kpi.description}
                        onChange={(e) => {
                          setKpi({
                            ...kpi,
                            description: e.target.value,
                          });
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MDInput
                        autoFocus
                        required
                        margin="dense"
                        id="kpiReferenceCode"
                        name="kpiReferenceCode"
                        label="Reference code"
                        type="text"
                        value={kpi.referenceCode}
                        error={error?.referenceCode}
                        onChange={(e) => {
                          setKpi({ ...kpi, referenceCode: e.target.value });
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MDInput
                        required
                        margin="dense"
                        id="kpiWeight"
                        name="kpiWeight"
                        label="Weight"
                        type="number"
                        value={kpi.weight}
                        onChange={(e) => {
                          setKpi({ ...kpi, weight: e.target.value });
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MSelect
                        required
                        value={kpi.type}
                        label={"Type"}
                        items={["Static", "Dynamic"].map((type, index) => {
                          return {
                            value: index,
                            render: type,
                          };
                        })}
                        onChange={(value) => {
                          setKpi({ ...kpi, type: value });
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MSelect
                        required
                        value={kpi.kpiValueTypeId}
                        label={"Value type"}
                        items={data.valueTypes.map((valueType, index) => {
                          return {
                            value: valueType.id,
                            render: valueType.description,
                          };
                        })}
                        onChange={(value) => {
                          setKpi({ ...kpi, kpiValueTypeId: value });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <MDTypography variant="h6">
                          Deviations description
                        </MDTypography>
                        <MDButton
                          onClick={() => {
                            setKpi({
                              ...kpi,
                              deviationsDescription: [
                                ...kpi.deviationsDescription,
                                {
                                  description: "",
                                  isDefault:
                                    kpi.deviationsDescription.length == 0,
                                  type: 2,
                                  editing: true,
                                },
                              ],
                            });
                          }}
                        >
                          <Icon>add</Icon>&nbsp; Add deviation description
                        </MDButton>
                      </MDBox>
                      <MDBox display="flex" flexDirection="column">
                        <DataTable
                          table={{
                            columns: [
                              {
                                Header: "Default",
                                accessor: "default",
                                width: "10%",
                                align: "center",
                              },
                              {
                                Header: "Description",
                                accessor: "description",
                                width: "75%",
                              },
                              {
                                Header: "Actions",
                                accessor: "actions",
                                width: "15%",
                                align: "center",
                              },
                            ],
                            rows: kpi.deviationsDescription.map(
                              (value, index) => {
                                return {
                                  default: (
                                    <FormControlLabel
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      control={
                                        <Checkbox
                                          checked={value.isDefault}
                                          disabled={value.isDefault}
                                          onChange={(e) => {
                                            const newDeviationsDescription = [
                                              ...kpi.deviationsDescription,
                                            ];
                                            if (e.target.checked) {
                                              for (
                                                var i = 0;
                                                i <
                                                newDeviationsDescription.length;
                                                i++
                                              ) {
                                                newDeviationsDescription[
                                                  i
                                                ].isDefault = false;
                                              }
                                            }

                                            newDeviationsDescription[
                                              index
                                            ].isDefault = e.target.checked;

                                            setKpi({
                                              ...kpi,
                                              deviationsDescription: [
                                                ...kpi.deviationsDescription,
                                              ],
                                            });
                                          }}
                                        />
                                      }
                                    />
                                  ),
                                  description: (
                                    <>
                                      {!value.editing && (
                                        <MDTypography variant="inherit">
                                          {value.description}
                                        </MDTypography>
                                      )}
                                      {value.editing && (
                                        <MDInput
                                          sx={{ width: "100%" }}
                                          value={value.description}
                                          label="Description"
                                          onChange={(e) => {
                                            const newDeviationsDescription = [
                                              ...kpi.deviationsDescription,
                                            ];
                                            newDeviationsDescription[
                                              index
                                            ].description = e.target.value;
                                            setKpi({
                                              ...kpi,
                                              deviationsDescription: [
                                                ...kpi.deviationsDescription,
                                              ],
                                            });
                                          }}
                                        />
                                      )}
                                    </>
                                  ),
                                  actions: (
                                    <>
                                      <IconButton
                                        disabled={
                                          value.editing &&
                                          value.description == ""
                                        }
                                        size="small"
                                        onClick={() => {
                                          const newDeviationsDescription = [
                                            ...kpi.deviationsDescription,
                                          ];
                                          newDeviationsDescription[
                                            index
                                          ].editing = !value.editing;
                                          setKpi({
                                            ...kpi,
                                            deviationsDescription: [
                                              ...kpi.deviationsDescription,
                                            ],
                                          });
                                        }}
                                      >
                                        <Icon>
                                          {value.editing ? "check" : "edit"}
                                        </Icon>
                                      </IconButton>
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          const newDeviationsDescription = [
                                            ...kpi.deviationsDescription,
                                          ];
                                          newDeviationsDescription.splice(
                                            index,
                                            1
                                          );

                                          if (
                                            newDeviationsDescription.length > 0
                                          ) {
                                            newDeviationsDescription[0].isDefault =
                                              kpi.deviationsDescription[index]
                                                .isDefault
                                                ? true
                                                : newDeviationsDescription[0]
                                                    .isDefault;
                                          }

                                          setKpi({
                                            ...kpi,
                                            deviationsDescription: [
                                              ...newDeviationsDescription,
                                            ],
                                          });
                                        }}
                                      >
                                        <Icon>delete</Icon>
                                      </IconButton>
                                    </>
                                  ),
                                };
                              }
                            ),
                          }}
                          isSorted={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <MDTypography variant="h6">Actions</MDTypography>
                        <MDButton
                          onClick={() => {
                            setKpi({
                              ...kpi,
                              actions: [
                                ...kpi.actions,
                                {
                                  description: "",
                                  isDefault: kpi.actions.length == 0,
                                  type: 0,
                                  editing: true,
                                },
                              ],
                            });
                          }}
                        >
                          <Icon>add</Icon>&nbsp; Add Action
                        </MDButton>
                      </MDBox>
                      <MDBox display="flex" flexDirection="column">
                        <DataTable
                          table={{
                            columns: [
                              {
                                Header: "Default",
                                accessor: "default",
                                width: "10%",
                                align: "center",
                              },
                              {
                                Header: "Description",
                                accessor: "description",
                                width: "75%",
                              },
                              {
                                Header: "Actions",
                                accessor: "actions",
                                width: "15%",
                                align: "center",
                              },
                            ],
                            rows: kpi.actions.map((value, index) => {
                              return {
                                default: (
                                  <FormControlLabel
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    control={
                                      <Checkbox
                                        checked={value.isDefault}
                                        disabled={value.isDefault}
                                        onChange={(e) => {
                                          const newActions = [...kpi.actions];
                                          if (e.target.checked) {
                                            for (
                                              var i = 0;
                                              i < newActions.length;
                                              i++
                                            ) {
                                              newActions[i].isDefault = false;
                                            }
                                          }

                                          newActions[index].isDefault =
                                            e.target.checked;

                                          setKpi({
                                            ...kpi,
                                            actions: [...kpi.actions],
                                          });
                                        }}
                                      />
                                    }
                                  />
                                ),
                                description: (
                                  <>
                                    {!value.editing && (
                                      <MDTypography variant="inherit">
                                        {value.description}
                                      </MDTypography>
                                    )}
                                    {value.editing && (
                                      <MDInput
                                        sx={{ width: "100%" }}
                                        value={value.description}
                                        label="Description"
                                        onChange={(e) => {
                                          const newActions = [...kpi.actions];
                                          newActions[index].description =
                                            e.target.value;
                                          setKpi({
                                            ...kpi,
                                            actions: [...kpi.actions],
                                          });
                                        }}
                                      />
                                    )}
                                  </>
                                ),
                                actions: (
                                  <>
                                    <IconButton
                                      disabled={
                                        value.editing && value.description == ""
                                      }
                                      size="small"
                                      onClick={() => {
                                        const newActions = [...kpi.actions];
                                        newActions[index].editing =
                                          !value.editing;
                                        setKpi({
                                          ...kpi,
                                          actions: [...kpi.actions],
                                        });
                                      }}
                                    >
                                      <Icon>
                                        {value.editing ? "check" : "edit"}
                                      </Icon>
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        const newActions = [...kpi.actions];
                                        newActions.splice(index, 1);

                                        if (newActions.length > 0) {
                                          newActions[0].isDefault = kpi.actions[
                                            index
                                          ].isDefault
                                            ? true
                                            : newActions[0].isDefault;
                                        }

                                        setKpi({
                                          ...kpi,
                                          actions: [...newActions],
                                        });
                                      }}
                                    >
                                      <Icon>delete</Icon>
                                    </IconButton>
                                  </>
                                ),
                              };
                            }),
                          }}
                          isSorted={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <MDTypography variant="h6">Risks</MDTypography>
                        <MDButton
                          onClick={() => {
                            setKpi({
                              ...kpi,
                              risks: [
                                ...kpi.risks,
                                {
                                  description: "",
                                  isDefault: kpi.risks.length == 0,
                                  type: 0,
                                  editing: true,
                                },
                              ],
                            });
                          }}
                        >
                          <Icon>add</Icon>&nbsp; Add Risk
                        </MDButton>
                      </MDBox>
                      <MDBox display="flex" flexDirection="column">
                        <DataTable
                          table={{
                            columns: [
                              {
                                Header: "Default",
                                accessor: "default",
                                width: "10%",
                                align: "center",
                              },
                              {
                                Header: "Description",
                                accessor: "description",
                                width: "75%",
                              },
                              {
                                Header: "Actions",
                                accessor: "actions",
                                width: "15%",
                                align: "center",
                              },
                            ],
                            rows: kpi.risks.map((value, index) => {
                              return {
                                default: (
                                  <FormControlLabel
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    control={
                                      <Checkbox
                                        checked={value.isDefault}
                                        disabled={value.isDefault}
                                        onChange={(e) => {
                                          const newRisks = [...kpi.risks];
                                          if (e.target.checked) {
                                            for (
                                              var i = 0;
                                              i < newRisks.length;
                                              i++
                                            ) {
                                              newRisks[i].isDefault = false;
                                            }
                                          }

                                          newRisks[index].isDefault =
                                            e.target.checked;

                                          setKpi({
                                            ...kpi,
                                            risks: [...kpi.risks],
                                          });
                                        }}
                                      />
                                    }
                                  />
                                ),
                                description: (
                                  <>
                                    {!value.editing && (
                                      <MDTypography variant="inherit">
                                        {value.description}
                                      </MDTypography>
                                    )}
                                    {value.editing && (
                                      <MDBox sx={{ width: "100%" }} fullWidth>
                                        <MDInput
                                          sx={{ width: "100%" }}
                                          value={value.description}
                                          label="Description"
                                          onChange={(e) => {
                                            const newRisks = [...kpi.risks];
                                            newRisks[index].description =
                                              e.target.value;
                                            setKpi({
                                              ...kpi,
                                              risks: [...newRisks],
                                            });
                                          }}
                                        />
                                      </MDBox>
                                    )}
                                  </>
                                ),
                                actions: (
                                  <>
                                    <IconButton
                                      disabled={
                                        value.editing && value.description == ""
                                      }
                                      size="small"
                                      onClick={() => {
                                        const newRisks = [...kpi.risks];
                                        newRisks[index].editing =
                                          !value.editing;
                                        setKpi({
                                          ...kpi,
                                          risks: [...newRisks],
                                        });
                                      }}
                                    >
                                      <Icon>
                                        {value.editing ? "check" : "edit"}
                                      </Icon>
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        const newRisks = [...kpi.risks];
                                        newRisks.splice(index, 1);

                                        if (newRisks.length > 0) {
                                          newRisks[0].isDefault = kpi.risks[
                                            index
                                          ].isDefault
                                            ? true
                                            : newRisks[0].isDefault;
                                        }

                                        setKpi({
                                          ...kpi,
                                          risks: [...newRisks],
                                        });
                                      }}
                                    >
                                      <Icon>delete</Icon>
                                    </IconButton>
                                  </>
                                ),
                              };
                            }),
                          }}
                          isSorted={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                ),
              },
            ]}
            value={state}
            onChange={(e, value) => {
              setState(value);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="info"
          type="submit"
        >
          {type == "edit" ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function generateDefaultReferenceCode(parentId, id) {
  function convert(n) {
    var result = "";
    n--;
    do {
      result = ((n % 26) + 10).toString(36) + result;
      n = Math.floor(n / 26) - 1;
    } while (n >= 0);
    return result.toUpperCase();
  }

  if (parentId) {
    return convert(parentId) + id;
  } else {
    return convert(id);
  }
}

function generateKpiReferenceCode(parent) {
  return parent?.referenceCode + (parent?.kpis?.length + 1);
}
