import HttpService from "./htttp.service";

class LocationService {
  getCountries = async (query, cancelToken) => {
    const endpoint = "location/countries";
    return await HttpService.get(endpoint, query, cancelToken);
  };

  createCountry = async (payload) => {
    const endpoint = "location/countries";
    return await HttpService.post(endpoint, payload);
  };

  removeCountry = async (id) => {
    const endpoint = "location/countries/" + id;
    return await HttpService.delete(endpoint, id);
  };

  updateCountry = async (id, payload) => {
    const endpoint = "location/countries/" + id;
    return await HttpService.put(endpoint, payload);
  };

  getTerritories = async (query, cancelToken) => {
    const endpoint = "location/territories";
    return await HttpService.get(endpoint, query, cancelToken);
  };

  createTerritory = async (payload) => {
    const endpoint = "location/territories";
    return await HttpService.post(endpoint, payload);
  };

  removeTerritory = async (id) => {
    const endpoint = "location/territories/" + id;
    return await HttpService.delete(endpoint, id);
  };

  updateTerritory = async (id, payload) => {
    const endpoint = "location/territories/" + id;
    return await HttpService.put(endpoint, payload);
  };

  getRegions = async (query, cancelToken) => {
    const endpoint = "location/regions";
    return await HttpService.get(endpoint, query, cancelToken);
  };

  createRegion = async (payload) => {
    const endpoint = "location/regions";
    return await HttpService.post(endpoint, payload);
  };

  removeRegion = async (id) => {
    const endpoint = "location/regions/" + id;
    return await HttpService.delete(endpoint, id);
  };

  updateRegion = async (id, payload) => {
    const endpoint = "location/regions/" + id;
    return await HttpService.put(endpoint, payload);
  };
}

export default new LocationService();
