import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import { TextField } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";

export default function MDSelect({
  noneOption,
  label,
  value,
  onChange,
  items,
  required,
  readOnly,
  multiple,
  ...rest
}) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const selectedValues = event.target.value;
    onChange(selectedValues);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      {/*<FormControl {...rest}>*/}
      <TextField
        fullWidth
        select
        required={required}
        sx={{
          minHeight: 44,
          //textAlign: "start",
          whiteSpace: "wrap",
        }}
        label={label}
        open={open}
        onClose={handleClose}
        //onOpen={handleOpen}
        readOnly={readOnly}
        SelectProps={{
          multiple: multiple ? true : false,
          value: value,
          onChange: handleChange,
          MenuProps: {
            sx: {
              "&& .Mui-selected": {
                borderRadius: "6px",
                backgroundColor: `${theme.palette.info.main} !important`,
                color: `${"white"} !important`,
              },
            },
          },
        }}
        gap={1}
        {...rest}
      >
        {noneOption ? (
          <MenuItem
            sx={{
              borderRadius: 0,
              p: 0.25,
              textAlign: "start",
              fontWeight: "600",
              width: "100%",
            }}
            key={"MD empty select item"}
            value={""}
          >
            (None)
          </MenuItem>
        ) : null}
        {items?.map((item, index) => {
          return (
            <MenuItem
              key={"MD select item index: " + label + " - " + index}
              value={item.value}
              sx={{
                padding: ".2rem",
              }}
            >
              {item.render}
            </MenuItem>
          );
        })}
      </TextField>
      {/*</FormControl>*/}
    </>
  );
}
