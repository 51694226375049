/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Badge from "@mui/material/Badge";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

import AuthService from "services/auth-service";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "contexts/auth.context";
import MDButton from "components/MDButton";
import { useAuth } from "contexts/auth.context";
import NotificationElement from "components/NotificationElement";
import MDTypography from "components/MDTypography";
import NotificationsMenu from "components/Notifications";
import MDAvatar from "components/MDAvatar";
import {
  Avatar,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
} from "@mui/material";
import BasicPopover from "components/BasicPopover";

function DashboardNavbar({ absolute, light, isMini, customRoute }) {
  const authContext = useAuth();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [open, setOpen] = useState({});
  const route = useLocation().pathname.split("/").slice(1);
  const [notifications, setNotifications] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    document.addEventListener(
      "notifications-updated",
      handleUpdateNotifications
    );

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("scroll", handleTransparentNavbar);
      document.removeEventListener(
        "notifications-updated",
        handleUpdateNotifications
      );
    };
  }, [dispatch, fixedNavbar]);

  function handleUpdateNotifications(event) {
    setNotifications(event.detail);
  }

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) =>
    setOpen({ ...open, notifications: event.currentTarget });
  const handleCloseMenu = () => setOpen({ ...open, notifications: false });

  const renderMenus = () => (
    <>
      <NotificationsMenu
        anchorElement={open.notifications}
        onClose={handleCloseMenu}
        notifications={notifications}
        setNotifications={setNotifications}
      />
      <BasicPopover
        button={
          <MDButton
            onClick={(e) => {
              setOpen({ ...open, profile: e.currentTarget });
            }}
            variant="outlined"
            color="info"
            size="small"
            sx={(theme) => ({
              backgroundColor: "white !important",
              borderColor: "transparent" + " !important",
              boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.15)",
              pl: 1,
            })}
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
              }}
              src={authContext?.user?.profileImage}
            />
            <MDTypography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "500",
                ml: 1,
              }}
            >
              {authContext?.user?.name}
            </MDTypography>
          </MDButton>
        }
        content={
          <MDBox sx={{ m: -1, backgroundColor: "white", minWidth: "300px" }}>
            <MenuList dense={true}>
              <MenuItem
                sx={{
                  mx: -1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: 1,
                  px: 3,
                  py: 1,
                }}
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      width: 36,
                      height: 36,
                    }}
                    src={authContext?.user?.profileImage}
                  />
                  <MDBox sx={{ display: "flex", flexDirection: "column" }}>
                    <MDTypography sx={{ fontSize: "1rem", fontWeight: "500" }}>
                      {authContext?.user?.name}
                    </MDTypography>
                    <MDTypography sx={{ fontSize: "0.9rem" }}>
                      {authContext?.user?.email}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MenuItem>
              <Divider />
              <MenuItem
                size="small"
                sx={{ mx: -1 }}
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <ListItemIcon>
                  <Icon fontSize="medium">settings</Icon>
                </ListItemIcon>
                <MDTypography sx={{ fontSize: "1rem" }}>Settings</MDTypography>
              </MenuItem>
              <Divider />
              <MenuItem size="small" sx={{ mx: -1 }} onClick={handleLogOut}>
                <ListItemIcon>
                  <Icon fontSize="medium">logout</Icon>
                </ListItemIcon>
                <MDTypography sx={{ fontSize: "1rem" }}>Log out</MDTypography>
              </MenuItem>
            </MenuList>
          </MDBox>
        }
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              borderRadius: "12px",

              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                borderRadius: "12px",
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 19,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    </>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
    fontSize: "32px",
  });

  const handleLogOut = async () => {
    const response = await AuthService.logout();
    authContext.logout();
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={
              (customRoute && customRoute[customRoute?.length - 1]) ||
              route[route.length - 1]
            }
            route={customRoute || route}
            light={light}
          />
        </MDBox>

        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/*<MDBox pr={1}>
              <MDInput label="Search here" />
        </MDBox>*/}

            <MDBox
              display="flex"
              alignItems="center"
              color={light ? "white" : "inherit"}
            >
              <IconButton sx={{ mr: 2 }} onClick={() => handleMiniSidenav()}>
                <Icon>menu</Icon>
              </IconButton>

              <Badge
                sx={(theme) => ({
                  "& .MuiBadge-badge": {
                    right: 11,
                    top: 2,
                  },
                })}
                badgeContent={notifications?.length}
                color="primary"
              >
                <MDButton
                  iconOnly
                  variant={notifications?.length ? "gradient" : "outlined"}
                  color="primary"
                  sx={(theme) => ({
                    backgroundColor: notifications?.length
                      ? null
                      : "white !important",
                    borderColor: "transparent" + " !important",
                    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.15)",
                    color: notifications?.length
                      ? "white"
                      : theme.palette.text.main,
                    mr: 1,
                  })}
                  size="medium"
                  onClick={handleOpenMenu}
                >
                  <Icon
                    fontSize="large"
                    sx={{
                      transform: "scale(1.4)",
                    }}
                  >
                    notifications
                  </Icon>
                </MDButton>
              </Badge>

              {/*<IconButton
                size="medium"
                color="inherit"
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Badge
                  badgeContent={notifications?.length || 0}
                  color="primary"
                >
                  <Icon sx={iconsStyle}>notifications</Icon>
                </Badge>
              </IconButton>*/}
              {/*<Link to="/profile">
                <MDButton
                  iconOnly
                  variant="outlined"
                  color="info"
                  sx={(theme) => ({
                    backgroundColor: "white !important",
                    borderColor: "transparent" + " !important",
                    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.15)",
                    color: theme.palette.text.main,
                    mr: 1,
                  })}
                >
                  <Icon
                    sx={{
                      transform: "scale(1.4)",
                    }}
                  >
                    settings
                  </Icon>
                </MDButton>
              
              </Link>*/}

              {renderMenus()}
              {/*<MDBox sx={{ ml: 2 }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="button"
                  onClick={handleLogOut}
                  startIcon={<Icon>logout</Icon>}
                >
                  Log Out
                </MDButton>
              </MDBox>*/}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
