import {
  Icon,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
} from "@mui/material";

export const Accordion = styled((props) => (
  <MuiAccordion
    slotProps={{ transition: { unmountOnExit: true } }}
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  /*"&:first-child": {
      borderTop: 0,
    },*/
  "&::before": {
    display: "none",
  },
  "&:hover": {
    //boxShadow: "inset 0 0 0 2px #007bff",
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <Icon sx={{ fontSize: "0.9rem !important" }}>arrow_forward_ios</Icon>
    }
    {...props}
    sx={{ fontSize: "1rem !important", ...props?.sx }}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },

  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
