import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";

export default function MSelect({
  label,
  items,
  value,
  //defaultValue,
  onChange,
  required,
  createButton,
  readOnly,
  disabled,
}) {
  /*const [value, setValue] = React.useState(defaultValue ? defaultValue : 1);

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  React.useEffect(() => {
    onChange(value);
  }, [value]);*/

  return (
    <MDBox sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      <FormControl fullWidth sx={{ minWidth: 120 }}>
        <InputLabel htmlFor={"grouped-native-select" + label}>
          {label}
        </InputLabel>
        <Select
          native
          id={"grouped-native-select" + label}
          label={label}
          required={required}
          disabled={disabled}
          value={value}
          sx={{ pointerEvents: readOnly ? "none" : "auto" }}
          onChange={(e) => {
            onChange(e.target.value);
            /*setValue(e.target.value);
            if (onChange) {
              for (var i = 0; i < items.length; i++) {
                if (e.target.value == items[i].value) {
                  onChange(items[i].reference);
                  break;
                }
              }
            }*/
          }}
        >
          {items?.map((item, index) => {
            return (
              <option
                key={"ITEM: " + index + label}
                aria-label="None"
                value={item.value}
              >
                {item.render}
              </option>
            );
          })}
        </Select>
      </FormControl>
      {createButton && (
        <div>
          <MDButton
            onClick={createButton}
            variant="gradient"
            color="info"
            iconOnly
          >
            <Icon fontSize="lg">add</Icon>
          </MDButton>
        </div>
      )}
    </MDBox>
  );
}
