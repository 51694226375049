import HttpService from "./htttp.service";

class CurrenciesService {
  getCurrencies = async (query, cancelToken) => {
    const endpoint = "currencies";
    return await HttpService.get(endpoint, query, cancelToken);
  };
}

export default new CurrenciesService();
