import { Box, Card, Grid } from "@mui/material";
import BigCalendar from "components/BigCalendar";
import DropdownSelect from "components/DropdownSelect";
import MDTypography from "components/MDTypography";
import * as DomToImage from "dom-to-image";
import { jsPDF } from "jspdf";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import Resizer from "react-image-file-resizer";
import { useSearchParams } from "react-router-dom";
import auditsService from "services/audits-service";
import branchesService from "services/branches-service";
import usersService from "services/users-service";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function AuditsCalendar() {
  const [loading, setLoading] = useState({ fetch: true, generatingPdf: false });
  const [audits, setAudits] = useState([]);
  const [lastRequest, setLastRequest] = useState({});
  const [options, setOptions] = useState({
    users: null,
    statuses: null,
    branches: null,
  });

  const [delayByRenders, setDelayByRenders] = useState(5);

  const [searchParams, setSearchParams] = useSearchParams({
    branches: "",
    assignedTo: "",
    statuses: "",
  });

  const pdfHtmlRef = useRef(null);

  const events = useMemo(() => {
    if (!audits) return [];
    return audits.map((audit) => {
      return {
        id: audit.id,
        date: new Date(audit.date),
        description: audit.description,
        backgroundColor: audit.assignedTo.color || "#eeeeee",
        //color: "#344767",
        //color: audit.branch.colorPalette.fontColor1,
        branchId: audit.branch.id,
        branch: audit.branch,
        status: audit.status,
        assignedTo: audit.assignedTo,
      };
    });
  }, [audits]);

  const fetchAudits = async () => {
    setLoading((curr) => ({ ...curr, fetch: true }));

    let dateFrom, dateTo;
    const date = searchParams.get("date")
      ? new Date(searchParams.get("date"))
      : new Date();

    if (searchParams.get("layout") === "month") {
      dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
      dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    } else if (searchParams.get("layout") === "week") {
      dateFrom = new Date(date);
      dateTo = new Date(date);
      dateFrom.setDate(date.getDate() - date.getDay());
      dateTo.setDate(date.getDate() + (7 - date.getDay()));
      /*dateFrom.setHours(0, 0, 0, 0);
      dateTo.setHours(23, 59, 59, 999);*/
    } else if (searchParams.get("layout") === "day") {
      dateFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      dateTo = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1
      );
      /*dateFrom = date;
      dateFrom.setHours(0);
      dateTo = new Date(dateFrom);
      dateTo.setHours(23);
      dateTo.setMinutes(59);
      dateTo.setSeconds(59);*/
    }

    const request = {
      userId: searchParams.get("assignedTo") || 0,
      branchId: searchParams.get("branches") || 0,
      status: searchParams.get("statuses") || 0,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };

    if (!request.dateFrom || !request.dateTo) {
      setLoading((curr) => ({ ...curr, fetch: false }));
      return;
    }

    const [res, data] = await auditsService.getAudits(request);
    if (res.ok) {
      if (
        lastRequest.userId == request.userId &&
        lastRequest.branchId == request.branchId &&
        lastRequest.status == request.status
      ) {
        const newAudits = [...data];

        audits?.forEach((audit) => {
          const newValue = data.find((v) => v.id == audit.id);
          if (!newValue) {
            newAudits.push(audit);
          }
        });

        setAudits(newAudits);
      } else {
        setAudits(data);
      }

      setLastRequest(request);
    }
    setLoading((curr) => ({ ...curr, fetch: false }));
  };

  const fetchOther = async () => {
    setLoading((curr) => ({ ...curr, fetch: true }));
    const promises = [
      (async () => {
        const [ok, data] = await usersService.getUsers();
        if (ok) {
          setOptions((curr) => ({ ...curr, users: data }));
        }
      })(),
      (async () => {
        const [ok, data] = await auditsService.getStatuses();
        if (ok) {
          setOptions((curr) => ({ ...curr, statuses: data }));
        }
      })(),
      (async () => {
        const [ok, data] = await branchesService.getBranches();
        if (ok) {
          setOptions((curr) => ({ ...curr, branches: data }));
        }
      })(),
    ];

    await Promise.all(promises);
    setLoading((curr) => ({ ...curr, fetch: false }));
  };

  const updateAudit = async (newAudit) => {
    const promise = auditsService.updateAudit(newAudit.id, {
      date: newAudit.date,
      branchId: newAudit.branchId,
    });

    toast.promise(promise, {
      position: "bottom-right",
      loading: "Loading",
      success: "Saved",
      error: "Not saved!",
      duration: 5,
    });

    const [response, data] = await promise;

    if (response.ok) {
      fetchAudits();
    }
  };

  const generatePdf = async () => {
    setLoading({ ...loading, generatingPdf: true });

    if (delayByRenders > 0) {
      setDelayByRenders((curr) => curr - 1);
      return;
    }

    const capture = pdfHtmlRef.current;

    let orientation = "portrait";

    if (capture.clientWidth > capture.clientHeight) {
      orientation = "landscape";
    }

    const a4 = 1.414;
    let width = capture.clientWidth,
      height = capture.clientHeight;
    if (height > width) {
      width = height * a4;
    } else {
      height = width / a4;
    }

    const pdf = new jsPDF({
      orientation,
      putOnlyUsedFonts: true,
      unit: "px",
      format: [width, height],
    });

    const scale = 2;

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const contentWidth = capture.clientWidth;
    const contentHeight = capture.clientHeight;

    let scaleFactor = Math.min(
      pdfWidth / contentWidth,
      pdfHeight / contentHeight
    );

    const scaledWidth = contentWidth * scaleFactor;
    const scaledHeight = contentHeight * scaleFactor;

    const image = await DomToImage.toBlob(capture, {
      width: scaledWidth * scale,
      height: scaledHeight * scale,
      style: {
        transform: "scale(" + scaleFactor * scale + ")",
        transformOrigin: "top left",
      },
    });

    Resizer.imageFileResizer(
      image,
      scaledWidth * 1.5,
      scaledHeight * 1.5,
      "BLOB",
      100,
      0,
      (uri) => {
        setLoading({ ...loading, generatingPdf: false });

        pdf.addImage(uri, "PNG", 0, 0, scaledWidth, scaledHeight, null, "SLOW");

        let pdfName = "";
        const date = new Date(searchParams.get("date"));

        if (searchParams.get("layout") == "day") {
          pdfName += date
            .toLocaleDateString("en-GB", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
            .replaceAll("/", "-");
        } else if (searchParams.get("layout") == "month") {
          pdfName += date.getMonth() + 1 + "-" + date.getFullYear();
        } else {
          let dateFrom = new Date(date);
          let dateTo = new Date(date);
          dateFrom.setDate(date.getDate() - date.getDay());
          dateTo.setDate(date.getDate() + (6 - date.getDay()));
          dateFrom.setHours(0, 0, 0, 0);
          dateTo.setHours(23, 59, 59, 999);

          pdfName +=
            "Week from " +
            dateFrom
              .toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })
              .replaceAll("/", "-") +
            " to " +
            dateTo
              .toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })
              .replaceAll("/", "-");
        }
        pdfName += " Calendar.pdf";
        pdf.save(pdfName);
      },
      "base64"
    );

    setDelayByRenders(5);

    // const compressedImage = await imageCompression(image);
    /*const myFile = new File([compressedImage], "image.jpeg", {
      type: compressedImage.type,
    });*/
  };

  useEffect(() => {
    fetchOther();
  }, []);

  useEffect(() => {
    fetchAudits();
  }, [searchParams]);

  useEffect(() => {
    if (delayByRenders != 5) {
      generatePdf();
    }
  }, [delayByRenders]);

  const assignedToDropdownItems = useMemo(() => {
    return (
      options.users?.map((user) => ({
        id: user.id,
        label: user.name,
      })) || []
    );
  }, [options.users]);

  const statusDropdownItems = useMemo(() => {
    return (
      options.statuses?.map((status) => ({
        id: status.id,
        label: status.description,
      })) || []
    );
  }, [options.statuses]);

  const branchesDropdownItems = useMemo(() => {
    let allBranches = [];
    options.branches?.forEach((branchGroup) => {
      branchGroup.branches?.map((branch) => {
        allBranches.push({
          label: branch.name,
          id: branch.id,
          branchGroup: branchGroup.name,
        });
      });
    });

    return allBranches;
  }, [options.branches]);

  const assignedToInitialValue = useMemo(() => {
    const filtered =
      searchParams
        .get("assignedTo")
        ?.split(",")
        ?.map((v) => Number(v)) || [];
    return (
      assignedToDropdownItems?.filter((v) => filtered.includes(v.id)) || []
    );
  }, [searchParams.get("assignedTo"), assignedToDropdownItems]);

  const statusesInitialValue = useMemo(() => {
    const filtered =
      searchParams
        .get("statuses")
        ?.split(",")
        ?.map((v) => Number(v)) || [];
    return statusDropdownItems?.filter((v) => filtered.includes(v.id)) || [];
  }, [searchParams.get("statuses"), statusDropdownItems]);

  const branchesInitialValue = useMemo(() => {
    const filtered =
      searchParams
        .get("branches")
        ?.split(",")
        ?.map((v) => Number(v)) || [];
    return branchesDropdownItems?.filter((v) => filtered.includes(v.id)) || [];
  }, [searchParams.get("branches"), branchesDropdownItems]);

  const assignedToLegendItems = useMemo(() => {
    if (!audits?.length) return [];
    const users = {};
    const layout = searchParams.get("layout");
    const date = new Date(searchParams.get("date"));
    audits.map((audit) => {
      const { startDate, endDate } = getStartAndEndDate(date, layout);
      const auditDate = new Date(audit.date);
      if (auditDate >= startDate && auditDate <= endDate)
        users[audit.assignedTo.id] = audit.assignedTo;
    });
    return Object.values(users).sort((a, b) => a.name?.localeCompare(b.name));
  }, [audits, searchParams.get("layout"), searchParams.get("date")]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card sx={{ padding: 2 }}>
          <MDTypography variant="h6" sx={{ marginBottom: 1 }}>
            Filters
          </MDTypography>
          <Grid container spacing={1}>
            <Grid item xs>
              <DropdownSelect
                label={"Company"}
                initialValue={
                  searchParams
                    .get("branches")
                    ?.split(",")
                    ?.map((v) => Number(v)) || []
                }
                onChange={(newValue) => {
                  setSearchParams(
                    (curr) => {
                      if (newValue.find((v) => v.id === -1)) {
                        curr.set("branches", "-1");
                      } else {
                        curr.set(
                          "branches",
                          newValue.map((v) => v.id).join(",")
                        );
                      }
                      return curr;
                    },
                    { replace: true }
                  );
                }}
                items={branchesDropdownItems}
                groupBy={(option) => option.branchGroup}
                fullWidth
                multiple
              />
            </Grid>
            <Grid item xs>
              <DropdownSelect
                label={"Assigned to"}
                initialValue={
                  searchParams
                    .get("assignedTo")
                    ?.split(",")
                    ?.map((v) => Number(v)) || []
                }
                onChange={(newValue) => {
                  setSearchParams(
                    (curr) => {
                      if (newValue.find((v) => v.id === -1)) {
                        curr.set("assignedTo", "-1");
                      } else {
                        curr.set(
                          "assignedTo",
                          newValue.map((v) => v.id).join(",")
                        );
                      }
                      return curr;
                    },
                    { replace: true }
                  );
                }}
                items={assignedToDropdownItems}
                multiple
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <DropdownSelect
                label={"Status"}
                initialValue={
                  searchParams
                    .get("statuses")
                    ?.split(",")
                    ?.map((v) => Number(v)) || []
                }
                onChange={(newValue) => {
                  setSearchParams(
                    (curr) => {
                      if (newValue.find((v) => v.id === -1)) {
                        curr.set("statuses", "-1");
                      } else {
                        curr.set(
                          "statuses",
                          newValue.map((v) => v.id).join(",")
                        );
                      }
                      return curr;
                    },
                    { replace: true }
                  );
                }}
                items={statusDropdownItems}
                multiple
                fullWidth
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <div ref={pdfHtmlRef}>
          <BigCalendar
            loading={loading}
            initialEvents={events}
            onEventsChange={(newEvents, eventChanged) => {
              updateAudit(eventChanged);
            }}
            generatePdf={generatePdf}
          />
          {assignedToLegendItems?.length ? (
            <Card sx={{ padding: 2, marginTop: 1 }}>
              <Grid container gap={3}>
                {assignedToLegendItems.map((user) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        aspectRatio: 2,
                        height: "20px",
                        borderRadius: 2,
                        backgroundColor: user.color || "#eee",
                      }}
                    ></Box>
                    <MDTypography
                      sx={{ whiteSpace: "nowrap", fontSize: "1rem" }}
                    >
                      {user.name}
                    </MDTypography>
                  </Box>
                ))}
              </Grid>
            </Card>
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
}

function getStartAndEndDate(date, layout = "month") {
  const inputDate = new Date(date); // Convert input to a Date object
  let startDate, endDate;

  switch (layout) {
    case "day":
      startDate = new Date(inputDate);
      endDate = new Date(inputDate);
      break;

    case "week":
      const dayOfWeek = inputDate.getDay(); // 0 = Sunday, 6 = Saturday
      startDate = new Date(inputDate);
      startDate.setDate(inputDate.getDate() - dayOfWeek); // Start of the week (Sunday)
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6); // End of the week (Saturday)
      break;

    case "month":
      startDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), 1); // First day of the month
      endDate = new Date(inputDate.getFullYear(), inputDate.getMonth() + 1, 0); // Last day of the month
      break;

    default:
      throw new Error(
        "Invalid layout. Please choose 'day', 'week', or 'month'."
      );
  }

  // Reset time to 00:00:00 for startDate and 23:59:59 for endDate
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  return { startDate, endDate };
}
