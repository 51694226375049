import Collapse from "@mui/material/Collapse";
import MDBox from "components/MDBox";
import { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import SidenavCollapse from "./SidenavCollapse";

export default function SidenavGroup({ header, items, collapseName }) {
  const [open, setOpen] = useState(false);

  const childSelected = useMemo(() => {
    if (items.find((item) => item.key == collapseName)) {
      return true;
    }

    return false;
  }, [collapseName, items]);

  useEffect(() => {
    if (childSelected) setOpen(true);
  }, [childSelected]);

  return (
    <>
      <SidenavCollapse
        {...header}
        isGroup={true}
        open={open}
        setOpen={setOpen}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <MDBox>
          {items.map((item, index) => {
            return (
              <NavLink key={item.key + index} to={item.route}>
                <SidenavCollapse
                  name={item.name}
                  icon={item.icon}
                  active={item.key === collapseName}
                  isInGroup={true}
                />
              </NavLink>
            );
          })}
        </MDBox>
      </Collapse>
    </>
  );
}
