import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import AccessDenied from "components/AccessDenied";
import AllBranches from "./components/AllBranches";
import Branch from "./components/Branch";

import PageContainer from "components/PageContainer";
import Footer from "examples/Footer";
import { Route, Routes, useLocation } from "react-router-dom";

import { useAuth } from "contexts/auth.context";
import { useEffect, useState } from "react";

function Branches() {
  const [route, setRoute] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setRoute(null);
  }, [location]);

  const { checkPermission } = useAuth();

  if (
    Object.keys(checkPermission("branchGroup")).filter(
      (key) => checkPermission("branchGroup")[key]
    ).length == 0
  ) {
    return <AccessDenied type="page" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar customRoute={route} />

      <PageContainer>
        <Routes>
          <Route
            index
            element={
              <AllBranches
                permissions={{
                  branchGroup: checkPermission("branchGroup"),
                  branch: checkPermission("branch"),
                }}
              />
            }
          />
          <Route
            path={"/:id"}
            element={
              <Branch
                action="edit"
                setRoute={setRoute}
                permissions={checkPermission("branch")}
              />
            }
          />
          <Route
            path={"/new"}
            element={
              <Branch action="new" permissions={checkPermission("branch")} />
            }
          />
        </Routes>
      </PageContainer>

      <Footer />
    </DashboardLayout>
  );
}

export default Branches;
